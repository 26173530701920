import React, { useContext } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import './index.css';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '-16px',
      marginBottom: '4px',
      width: '100%'
    },
}));

const PointLoading = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const appTheme = useSelector(store => store.setting.theme);
  const visible = props.visibility;

  return (
    <div className={classes.root} style={{visibility: visible}}>
        <div className={'loader-wrapper'}>
            <div className={'loader-item'}/>
            <div className={'loader-item'} style={{animationDelay: '0.1s'}}/>
            <div className={'loader-item'} style={{animationDelay: '0.2s'}}/>
            <div className={'loader-item'} style={{animationDelay: '0.3s'}}/>
        </div>
    </div>    
  )
}

export default PointLoading;

