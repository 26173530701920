import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {isMobile} from 'react-device-detect';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { ROUTE_PARAM_SIDEBAR, ROUTE_PARAM_CONTENTS } from '../../utils/const';
import { useSideBarState, useContentState, useSelNotifyState, useSelKeyState, useAccountState, useSearchState } from '../../helper/routing-analyser';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex', 
      padding: isMobile ? '0 15px' : '0 30px'
    },
    content: {
      display: 'flex',
      flexWrap: "wrap",
      width: '100%',
      font: `400 14px/50px Muli`,
      fontWeight: 'normal',
      borderBottom: '1px solid #E9E9E9',
      textTransform: 'uppercase',
    },
    selected: {
      cursor: 'pointer',
      width: 'fit-content',
      display: 'flex',
      '&:hover': {
        opacity: '0.7'
      }
    }
}));

function BreadCrumb(props) {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const appTheme = useSelector(store => store.setting.theme);
  const sidebar = useSideBarState();
  const content = useContentState();
  const notify = useSelNotifyState();
  const key = useSelKeyState();
  const uploader = useAccountState();
  const search = useSearchState();

  const getSettingBreadCrumb = () => {
    let str = 'Setting=@=';
    switch (content) {
      case ROUTE_PARAM_CONTENTS.SETTING_APPEARANCE:
        str += 'Appreance';
        break;
      case ROUTE_PARAM_CONTENTS.SETTING_CATEGORY:
        str += 'Category';
        break;
      case ROUTE_PARAM_CONTENTS.SETTING_GENERAL:
        str += 'General';
        break;
      case ROUTE_PARAM_CONTENTS.SETTING_USER:
        str += 'User';
        break;
      case ROUTE_PARAM_CONTENTS.SETTING_VIEW:
        str += 'View';
        break;
    }

    return str;
  }

  const getCategoryBreadCrumb = () => {
    let str;
    if (String(notify) === 'null')
      str = 'Category=@=' + 'No Category';
    else
      str = 'Category=@=' + notify;

    if (key) {
      let array = key.split('/');
      array.forEach((item, index) => {
        if (index === 0 || index === array.length - 1) return;
        str += ('=@=' + item);
      })
    }
    return str;
  }

  const getFolderBreadCrumb = () => {
    let str = 'Folder';

    let array = key.split('/');
    array.forEach((item, index) => {
      if (index === 0 || index === array.length - 1) return;
      str += ('=@=' + item);
    })
    return str;
  }

  const getFolderUserBreadCrumb = () => {
    let str = 'Uploader=@='+uploader;

    let array = key ? key.split('/') : [];
    array.forEach((item, index) => {
      if (index === 0 || index === array.length - 1) return;
      str += ('=@=' + item);
    })
    return str;
  }

  const getSearchFolderBreadCrumb = () => {
    let str = 'Search=@='+search;
    return str;
  }

  const getBreadCrumb = () => {
    let str ='';    
    if (uploader) {
      str = getFolderUserBreadCrumb();
    } else if (search) {
      str = getSearchFolderBreadCrumb();
    } else if (content) {
      if (sidebar === ROUTE_PARAM_SIDEBAR.SETTING) {
        str = getSettingBreadCrumb();
      } else if (sidebar === ROUTE_PARAM_SIDEBAR.CATEGORY) {
        str = getCategoryBreadCrumb();
      } else {
        str = getFolderBreadCrumb();
      }
    } else {
      str = 'home';
    }
    return str.split('=@=');;
  }

  return (
      <div className={classes.root}>
          <div className={classes.content} style={{color: appTheme.textColor}}>
            {getBreadCrumb().map((title, index) => {
              if (index === 0) {
                return (
                  <div key={index} className={classes.selected} style={{display: 'flex'}} /*onClick={()=>handleBreadcrumb(index)}*/>{title}</div>
                )
              } else {
                return (
                  <div key={index} style={{display: 'flex'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><ArrowRightAltIcon/></div>
                    <div className={classes.selected} /*onClick={()=>handleBreadcrumb(index)}*/>{title}</div>
                  </div>
                )
              }
            })}
          </div>
      </div>
  )
}

export default connect(s => ({}))(BreadCrumb);
