import React, { useContext, useState , useEffect} from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useLocationQuery } from '../../helper/routing-analyser';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core/';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ComputerIcon from '@material-ui/icons/Computer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { handleHome } from '../../utils/sidebar-controls';
import { ROUTE_PARAM_CONTENTS, ROUTE_PARAM_KEY, ROUTE_PARAM_SIDEBAR } from '../../utils/const';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    marginTop: '30px',
    marginBottom: '10px',
    font: `400 15px/50px Muli`,
  },
  appTitle: {
    cursor: 'pointer',   
    userSelect: 'none',
    '&:hover': {
      opacity: '0.7'
    },
  },
  backbutton: {
    display: 'flex',
    padding: '10px 20px',
    width: '100%',
    font: `400 12px/24px Muli`
  },
}))

const Setting = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory(); 
  const query = useLocationQuery(); 
  const classes = useStyles(); 
  const appTheme = useSelector(store => store.setting.theme); 
  const appViewmode = useSelector(store => store.setting.viewmode);

  const BackButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);  

  const handleBack = () => {
    history.goBack();
  }

  const handleSelectPage = (event, page) => {
    //const isNavigateNewTab = event.ctrlKey || event.metaKey;  
    if (props.mobileOpen) {
      props.handleDrawerToggle();
    }
    // if (isNavigateNewTab) {
    //   window.open(page, "_blank");
    // } else {
      const params = new URLSearchParams();
      params.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.SETTING);
      params.append(ROUTE_PARAM_KEY.CONTENT, page);
      history.push({pathname: '/', search: params.toString()});
    // } 
  }

  return (
    <div>
      <div className={classes.toolbar} >
        <h1 className={classes.appTitle} style={{color: appTheme.textColorSecondary}} onClick={()=>handleHome(history, query, appViewmode, props)}>{dictionary.appTitle}</h1>
      </div>
      <div id='sidebardrawer'>
        <Divider />
        <div className={classes.backbutton}>
          <BackButton style={{width: '100%'}} onClick={()=>handleBack()}>
            {dictionary.btnLabelBack}
          </BackButton>
        </div>
        <Divider />
        <List>
          <ListItem button key={dictionary.tabLabelGeneral} onClick={(event) => handleSelectPage(event, ROUTE_PARAM_CONTENTS.SETTING_GENERAL)}>
            <ListItemIcon style={{color: appTheme.textColor}}><PermDataSettingIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.tabLabelGeneral} />
          </ListItem>
          <ListItem button key={dictionary.tabLabelView} onClick={(event) => handleSelectPage(event, ROUTE_PARAM_CONTENTS.SETTING_VIEW)}>
            <ListItemIcon style={{color: appTheme.textColor}}><ComputerIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.tabLabelView} />
          </ListItem>
          <ListItem button key={dictionary.tabLabelAppearance} onClick={(event) => handleSelectPage(event, ROUTE_PARAM_CONTENTS.SETTING_APPEARANCE)}>
            <ListItemIcon style={{color: appTheme.textColor}}><AccountBalanceIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.tabLabelAppearance} />
          </ListItem>
          <ListItem button key={dictionary.tabLabelCategory} onClick={(event) => handleSelectPage(event, ROUTE_PARAM_CONTENTS.SETTING_CATEGORY)}>
            <ListItemIcon style={{color: appTheme.textColor}}><DashboardIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.tabLabelCategory} />
          </ListItem>
          <ListItem button key={dictionary.tabLabelUser} onClick={(event) => handleSelectPage(event, ROUTE_PARAM_CONTENTS.SETTING_USER)}>
            <ListItemIcon style={{color: appTheme.textColor}}><AccountBoxIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.tabLabelUser} />
          </ListItem>
        </List>
      </div>
    </div>
  );
}

export default connect(s => ({}))(Setting);