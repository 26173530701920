import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { WingBlank } from 'antd-mobile';
import { LanguageContext } from '../../../localization/LanguageContainers';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../../store/folder-reducer';
import { API_GET_CATEGORIES_URL } from '../../../utils/const';
import { useSelContentState, useSelLevelState, useSelKeyState } from '../../../helper/routing-analyser';
import ContentToolbar from '../../../components/content-toolbar';
import PhotoGrid from '../../../components/photo-grid';
import WebLoading from '../../../components/web-loading';
import EmptyData from '../../../icons/EmptyData';
import BrowserSubfolders from '../../../components/browser-subfolders';
import CategoryExpand from '../../../components/category-expand';
import { getAccountName } from '../../../helper/get-account-name';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'block'
    },
    contents: {
      marginTop: '20px',
      padding: '0 10px'
    },
    paginationbar: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    },
    tableempty: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      padding: '10px 0',
      color: 'black'
    },
}));

function BrowserList(props) {
  const { dictionary } = useContext(LanguageContext);
  const appTheme = useSelector(store => store.setting.theme);
  const classes = useStyles();
  const dispatch = useDispatch();
 
  const [selectLoading, setSelectLoading] = useState(false);
  const selectedContent = useSelContentState();
  const selectedLevel = useSelLevelState(); 
  const selectedKey = useSelKeyState(); 
  const isFileListLoading = useSelector(store => store.folder.isFileListFetching);
  const filelist = useSelector(store => store.folder.dataFileList);
  const isFetchingPageSubFolders = useSelector(store => store.folder.isPagesSubFolderListFetching);
  const pageSubFolders = useSelector(store => store.folder.dataPagesSubFolderList);
  const token = useSelector((store) => store.login.token);

  const { isLoading, data } = useQuery(['repoData', token], async (token) => {
    const token_ = token.queryKey[1];
    return (await fetch(API_GET_CATEGORIES_URL+`?AccountName=${getAccountName()}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",  
        "Accept": "application/json",
        "token": token_,      
      }
    })).json(); 
  });

  useEffect(()=>{
    dispatch(Actions.getFilesByFolderId(selectedContent));    
    dispatch(Actions.getPagesSubFolderList(selectedKey, Number(selectedLevel) + 1, 0));    
  }, [selectedContent]);

  const make_photo_urls = (photo) => {
    const has_resolution = (photo, res) => photo && photo.Resolutions && photo.Resolutions.includes(res)
    const has_preview = has_resolution(photo, 200)
    const has_mediumview = has_resolution(photo, 600)
    const has_lightbox = has_resolution(photo, 1600)
    const hires_url = photo.Domain === "images.photish.com" ? `https://${photo.Domain}/${photo.FileReference}` : `https://${photo.Domain}/files/${photo.FileReference}`;
    const preview_url = `${hires_url}${has_mediumview ? '600' : ''}`
    const lightbox_url = `${hires_url}${has_lightbox ? '1600' : ''}`
    const resolutions = photo.Resolutions;
    return { hires_url, preview_url, has_preview, lightbox_url, resolutions }
  }

  const images = isFileListLoading ? [] : filelist.map((photo, photo_index) => {
    const { hires_url, preview_url, resolutions } = make_photo_urls(photo);
    return ({
      icon: preview_url,
      id: photo.id,
      photo_width: photo.Width,
      photo_height: photo.Height,
      hires_url: hires_url,
      preview_url: preview_url,
      resolutions: resolutions
    })
  });   

  return (
      <div className={classes.root}>
          <ContentToolbar />
          <div className={classes.contents}>      
              { (isFileListLoading || isFetchingPageSubFolders || selectLoading || isLoading) ? <WebLoading/> : 
                        (images.length === 0 && pageSubFolders.length === 0) ? <div className={classes.tableempty}><EmptyData size={100}/><h3 style={{color: appTheme.textColor}}>{dictionary.tblLabelEmptyData}</h3></div> :                        
                        <WingBlank>
                          {pageSubFolders.length > 0 && <BrowserSubfolders subfolders={pageSubFolders} setSelectLoading={setSelectLoading}/>}
                          <CategoryExpand categories={data.result}/>
                          {filelist.length > 0 && <PhotoGrid images={images} filelist={filelist}/>}
                        </WingBlank>
              }
          </div>
      </div>
  )
}

const mapStateToProps = (s) => ({})
const with_connect = connect(mapStateToProps)
const enhance = compose( withRouter, with_connect);
export default enhance(BrowserList)
