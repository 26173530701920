import React, { useContext} from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { LanguageContext } from '../../localization/LanguageContainers';
import { useWindowSize } from '../../helper/window-size';
import { DRAWER_WIDTH, ROUTE_PARAM_SIDEBAR } from '../../utils/const';
import { useSideBarState } from '../../helper/routing-analyser';
import Category from './category.tsx';
import Folder from './folder';
import FolderUser from './folder-user';
import Setting from './setting';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: '7! important',
    [theme.breakpoints.up('sm')]: {
      width: `${DRAWER_WIDTH}px`,
      flexShrink: 0,
    },
  },
}))

const getSideBarContents = (route_state, stored_state, props) => {
  let contents = null;
  const state = String(route_state) === 'null' ? stored_state : route_state;
  switch (state) {
    case ROUTE_PARAM_SIDEBAR.CATEGORY:
      contents = <Category
        mobileOpen={props.mobileOpen} 
        handleDrawerToggle={props.handleDrawerToggle}
      />;
      break;
    case ROUTE_PARAM_SIDEBAR.FOLDER:
      contents = <Folder
        mobileOpen={props.mobileOpen} 
        handleDrawerToggle={props.handleDrawerToggle}
      />;
      break;
    case ROUTE_PARAM_SIDEBAR.FOLDER_USER:
      contents = <FolderUser
        mobileOpen={props.mobileOpen} 
        handleDrawerToggle={props.handleDrawerToggle}
      />;
      break;
    case ROUTE_PARAM_SIDEBAR.SETTING:
      contents = <Setting 
        mobileOpen={props.mobileOpen} 
        handleDrawerToggle={props.handleDrawerToggle}
      />;
      break;
    default:
      break;
  }

  return contents;
}

const SideBar = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const [width, height] = useWindowSize();
  const theme = useTheme(); 
  const appSetting = useSelector(store => store.setting); 
  const appTheme = appSetting.theme; 
  const classes = useStyles(); 
  const sidebar = useSideBarState();   
  const SideBarContents = getSideBarContents(sidebar, appSetting.viewmode, props);

  const usePaper = makeStyles((theme) => ({
    paper: {
      backgroundColor: appTheme.backgroundColorSecondary,
      width: `${DRAWER_WIDTH}px`,
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      textAlign: 'center',
      zIndex: '100'
    }
  }))
  const paperclasses = usePaper();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        classes={{ paper: paperclasses.paper}}
        ModalProps={{
          keepMounted: true
        }}
      >
        {SideBarContents}
      </Drawer>
      <Hidden xsDown implementation="css">
        <Drawer 
          classes={{ paper: paperclasses.paper }} 
          PaperProps={{
            opacity: 0.5
          }}
          variant="permanent" 
          open
        >
          {SideBarContents}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default connect(s => ({}))(SideBar);