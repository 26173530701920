import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const PhotoLightBox = (props) => {
    const { 
      images, filelist,
      photoIndex, setPhotoIndex,
      setLightboxMode, lightboxMode 
    } = props

    if (!lightboxMode) { return null }
    if (!Array.isArray(images)) { return null }

    const prev_photo_index = (photoIndex + images.length - 1) % images.length
    const next_photo_index = (photoIndex + 1) % images.length
  
    const getPreferedImgUrl = (index) => {
      return filelist[index].Resolutions.includes(1600) ? images[index].hires_url + "1600" : images[index].hires_url;
    }
    
    return (
      <Lightbox
        animationDuration={500}
        mainSrc={images[photoIndex] && getPreferedImgUrl(photoIndex)}
        nextSrc={getPreferedImgUrl(next_photo_index)}
        prevSrc={getPreferedImgUrl(prev_photo_index)}
        onCloseRequest={() => setLightboxMode(false)}
        onImageLoad={() => {
        }}
        onMovePrevRequest={() => {
          setPhotoIndex(prev_photo_index)
        }}
        onMoveNextRequest={() => {
          setPhotoIndex(next_photo_index)
        }}
      />
    )
  }

  export default PhotoLightBox;