import update from 'immutability-helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_DEL_FOLDER_URL, API_SAVE_FOLDER_URL } from '../utils/const';
import apicall from '../utils/api-call';

toast.configure();
export const ActionsTypes = {
    DELETE_FOLDER_ATTEMPT: "DELETE_FOLDER_ATTEMPT",
    DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
    DELETE_FOLDER_FAILURE: "DELETE_FOLDER_FAILURE",
    DELETE_FILES: "DELETE_FILES",
    STOP_DELETE_FILES: "STOP_DELETE_FILES",
    DELETE_FILES_CANCEL: "DELETE_FILES_CANCEL",
    DELETE_FILES_FULLFILED: "DELETE_FILES_FULLFILED",
    DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
    DELETE_FILE_FAILURE: "DELETE_FILE_FAILURE",
    DELETE_FILE_ATTEMPT: "DELETE_FILE_ATTEMPT",
    DELETING_FILES: "DELETING_FILES",
}

export const saveFolderDetails = ({ AccountName, FolderId, Description, IsFavorite, Categories, ForCategory, FolderType, callback, message }) => {
  return (dispatch, getState) => {
    return apicall({
      url: API_SAVE_FOLDER_URL,
      method: "POST",
      type: "json",
      params: { AccountName, FolderId, Description, IsFavorite, Categories, FolderType }
    })
      .then(response => {
        let res = null
        if (response.status === 200) {
          res = JSON.parse(response.responseText);
          callback();
        } else {
          try { res = JSON.parse(response.responseText) } catch{ res = { message: "Error!" } }
          toast.error(`${res.message}`);
        }
      })
  };
};

export const deleteFolder = ({ folderId, sortFolderVariable, callback }) => {
  console.log({ folderId, sortFolderVariable, callback })
    return (dispatch, getState) => {
      dispatch(Actions.deleteFolderAttempt({ folderId }))
  
      // Required Params
      const AccountName = localStorage.getItem("name")
      const FolderId = folderId
  
      if (localStorage.getItem("token") === 'null') {
        dispatch(Actions.deleteFolderFailure());
        if (typeof callback == 'function') callback();
        toast.info('Guest cannot delete folder.')
        return;
      }
  
      return apicall({
        url: API_DEL_FOLDER_URL,
        method: "POST",
        type: "json",
        params: { AccountName, FolderId }
      })
        .then(response => {
          let res = null
          try {
            res = JSON.parse(response.responseText)
          } catch (e) {
            if (e instanceof SyntaxError) {
              res = { message: `Error! ${e.name}` }
            } else {
              res = { message: `Error! ${e.message}` }
            }
          }
          if (res && response.status === 200) {
            dispatch(Actions.deleteFolderSuccess({ folderId }));
            toast.info(res.Message)
            if (typeof callback == 'function') callback();
            return
          }
          if (response.status === 500) {
            if (res && (res.Message || res.Error)) {
              toast.error(res.Message || res.Error)
            }
            dispatch(Actions.deleteFolderFailure());
            return
          }
          toast.error("Error!")
        })
    };
};
  
export const deleteFile = ({ fileId, filesIDs }) => {
    return (dispatch) => {
      dispatch(Actions.deleteFileAttempt({ fileId, filesIDs }))
    };
};

export const Actions = {
    saveFolderDetails: saveFolderDetails,
    deleteFolder: deleteFolder,
    deleteFile: deleteFile,
    deleteFolderAttempt: (payload) => ({ type: ActionsTypes.DELETE_FOLDER_ATTEMPT, payload: payload }),
    deleteFolderSuccess: (payload) => ({ type: ActionsTypes.DELETE_FOLDER_SUCCESS, payload: payload }),
    deleteFolderFailure: (payload) => ({ type: ActionsTypes.DELETE_FOLDER_FAILURE, payload: payload }),
    deleteFilesAttempt: (payload) => ({ type: ActionsTypes.DELETE_FILES_ATTEMPT, payload: payload }),
    deleteFiles: (payload) => ({ type: ActionsTypes.DELETE_FILES, payload: payload }),
    deleteFilesFullfiled: (payload) => ({ type: ActionsTypes.DELETE_FILES_FULLFILED, payload: payload }),
    stopDeleteFiles: (payload) => ({ type: ActionsTypes.STOP_DELETE_FILES, payload: payload }),
    deleteFileSuccess: (payload) => ({ type: ActionsTypes.DELETE_FILE_SUCCESS, payload: payload }),
    deleteFileFailure: (payload) => ({ type: ActionsTypes.DELETE_FILE_FAILURE, payload: payload }),
    deleteFileAttempt: (payload) => ({ type: ActionsTypes.DELETE_FILE_ATTEMPT, payload: payload }),
    deletingFiles: (payload) => ({ type: ActionsTypes.DELETING_FILES, payload: payload }),
}

let initial = {
    is_deleting_folder: 0,
    is_deleting_file: [],
    deleting_files: [],
    deleted_files: [],
    deleted_folders: [],
}

export default (state, action) => {
  state = state == null ? initial : state
  switch (action.type) {    
    //{{{ DELETE_FOLDER_ATTEMPT
    case ActionsTypes.DELETE_FOLDER_ATTEMPT: {
        const { folderId } = action.payload
        return update(state, {
          is_deleting_folder: { $set: folderId }
        })
    }
    //}}}
    //{{{ DELETE_FOLDER_FAILURE
    case ActionsTypes.DELETE_FOLDER_FAILURE: {
        return update(state, {
          is_deleting_folder: {
            $set: 0
          }
        })
    }
    //}}}
    //{{{ DELETE_FOLDER_SUCCESS
    case ActionsTypes.DELETE_FOLDER_SUCCESS: {
        return state;
    }
    //}}}
    //{{{ STOP_DELETE_FILES
    case ActionsTypes.STOP_DELETE_FILES: {
        return state;
    }
    //}}}
    //{{{ DELETE_FILE_ATTEMPT
    case ActionsTypes.DELETE_FILE_ATTEMPT: {
        const { fileId, filesIDs } = action.payload
        return update(state, {
          is_deleting_file: { $push: [fileId] }
        })
    }
    //}}}
    //{{{ DELETE_FILES_FULLFILED
    case ActionsTypes.DELETE_FILES_FULLFILED: {
        return update(state, {
          deleting_files: { $set: [] },
          deleted_files: { $set: [] },
          is_deleting_file: { $set: [] }
        })
    }
    //}}}
    //{{{ DELETE_FILES
    case ActionsTypes.DELETE_FILES: {
        const { filesIDs } = action.payload
        return update(state, {
          deleting_files: { $set: filesIDs },
          is_deleting_file: { $set: [] },
          deleted_files: { $set: [] }
        })
    }
    //}}}
    //{{{ DELETING_FILES
    case ActionsTypes.DELETING_FILES: {
        const { filesIDs } = action.payload
        return update(state, {
          deleting_files: { $set: filesIDs }
        })
    }
    //}}}
    //{{{ DELETED_FILES
    case ActionsTypes.DELETED_FILES: {
        const { filesIDs } = action.payload
        return update(state, {
          deleted_files: { $set: filesIDs }
        })
    }
    //}}}
    //{{{ DELETE_FILE_FAILURE
    case ActionsTypes.DELETE_FILE_FAILURE: {
        const { fileId } = action.payload
        return update(state, {
          is_deleting_file: {
            $apply: (x) => x.filter(f => f !== fileId)
          }
        })
    }
    //}}}
    //{{{ DELETE_FILE_SUCCESS
    case ActionsTypes.DELETE_FILE_SUCCESS: {
        const { fileId, folderId } = action.payload;
        return update(state, {
          is_deleting_file: {
            $apply: (ids) => ids.filter(id => id !== fileId)
          },
          deleted_files: {
            $push: [fileId]
          }
        })
    }
    //}}}
    default:
        return state;
  }
}

