import React, { useContext, useState , useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useQuery } from 'react-query';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CreateFolderButton from '../../components/create-folder-button';
import { getAccountName } from '../../helper/get-account-name';
import { API_GET_CATEGORIES_URL, API_GET_PARENT_FOLDER_URL } from '../../utils/const';
import { useWindowSize } from '../../helper/window-size';
import { useLocationQuery, useSelContentState, useSelAppendState, useSelLevelState, useSelKeyState } from '../../helper/routing-analyser';
import { handleHome, getSubFolderCount, handleFolderSelect } from '../../utils/sidebar-controls';
import { getFolderInfo } from '../../helper/get-folder';
import { Actions } from '../../store/folder-reducer';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    marginTop: '30px',
    marginBottom: '10px',
    font: `400 15px/50px Muli`,
  },
  appTitle: {
    cursor: 'pointer',   
    userSelect: 'none',
    '&:hover': {
      opacity: '0.7'
    },
  },
  marginspace: {
    marginTop: '10px'
  }
}))

const Folder = (props: any) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles(); 
  const [width, height] = useWindowSize();
  const [selectLoading, setSelectLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useLocationQuery();
  const selectedContent = useSelContentState(); 
  const selectedLevel = useSelLevelState(); 
  const selectedAppend = useSelAppendState();  
  const selectedKey = useSelKeyState(); 
  const appTheme = useSelector((store: any) => store.setting.theme); 
  const appViewmode = useSelector((store: any) => store.setting.viewmode);
  const selectedFolder = useSelector((store: any) => store.folder.selectedFolder);
  const isSubLoading = useSelector((store: any) => store.folder.isSubFolderListFetching);
  const subFolderList = useSelector((store: any) => store.folder.dataSubFolderList);
  const token = useSelector((store: any) => store.login.token);

  const { isLoading: categoryLoading, data: categoryData } = useQuery(['repoCateData', token], async (token) => {
    const token_ = token.queryKey[1];
    return (await fetch(API_GET_CATEGORIES_URL+`?AccountName=${getAccountName()}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",  
        "Accept": "application/json",
        "token": token_,      
      }
    })).json(); 
  });

  const { isLoading: folderLoading, data: folderData } = useQuery(['repoFolData', token], async (token) => {
    const token_ = token.queryKey[1];
    return (await fetch(API_GET_PARENT_FOLDER_URL+`?AccountName=${getAccountName()}&SortBy=natural&Level=1`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",  
        "Accept": "application/json",
        "token": token_,      
      }
    })).json(); 
  });

  const handleSelectFolder = async (event: any, fid: String, title: String, fkey: String, flevel: Number) => {
    event.persist();
    
    setSelectLoading(true);
    const reponse: any = await getSubFolderCount(fkey, flevel);
    const subFolderCount = JSON.parse(reponse.responseText).result;
    setSelectLoading(false);

    handleFolderSelect(event, history, query, dictionary, fid, fkey, flevel, subFolderCount, props);
  }

  const handleGoBack = () => {
    history.goBack();
  }

  const getFolderSideBarContents = () => {
    if (selectedContent) {
      const { title } = getFolderInfo(selectedFolder);
      return (
        <div>
          <ListItem button key={0} onClick={()=>handleGoBack()}>
            <ListItemIcon style={{color: appTheme.textColor}}><ArrowBackIcon/></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.btnLabelBack} />
          </ListItem>
          <ListItem button key={1} >
            <ListItemIcon style={{color: appTheme.textColor}}><FolderIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}}>
              <label>{title}</label>
            </ListItemText>
          </ListItem>
          { (Number(selectedAppend) > 0) ? <>
            {
              subFolderList.map((folder: any) => {            
                const { title, folderImageUrl } = getFolderInfo(folder);
                return (
                  <div style={{marginLeft: '20px'}} key={folder.id}>
                    <ListItem button onClick={(event) => handleSelectFolder(event, folder.id, title, folder.key, folder.level)} disabled={folderLoading}>
                      <ListItemIcon style={{color: appTheme.textColor}}><FolderOpenIcon /></ListItemIcon>
                      <ListItemText style={{color: appTheme.textColor}}><label style={{fontWeight: title===localStorage.getItem("selectedFolderName") ? "bold": "normal"}}>{title}</label></ListItemText>
                    </ListItem>
                  </div>
                )            
              })
            }
            </> : <>
            
            </>            
          }
        </div>
      )
    } else {
      return (
        folderData?.result.map((folder: any, index: number) => {                      
          const { title, folderImageUrl } = getFolderInfo(folder);
          return (
            <ListItem button key={folder.id} onClick={(event) => handleSelectFolder(event, folder.id, title, folder.key, folder.level)} disabled={folderLoading}>
              <ListItemIcon style={{color: appTheme.textColor}}><FolderIcon /></ListItemIcon>
              <ListItemText style={{color: appTheme.textColor}}><label style={{fontWeight: title===localStorage.getItem("selectedFolderName") ? "bold": "normal"}}>{title}</label></ListItemText>
            </ListItem>  
          );            
        })
      );
    }
  }

  useEffect(()=>{
    if (selectedContent && Number(selectedAppend) > 0) {
      dispatch(Actions.getSubFolderList(selectedKey, Number(selectedLevel) + 1))
    }
  }, [selectedContent]);

  return (
    <div>
      <div className={classes.toolbar} >
        <h1 className={classes.appTitle} style={{color: appTheme.textColorSecondary}} onClick={()=>handleHome(history, query, appViewmode, props)}>{dictionary.appTitle}</h1>
        <CreateFolderButton
          categories={categoryData?.result}
          mobileOpen={props.mobileOpen} 
          handleDrawerToggle={props.handleDrawerToggle}
        />
      </div>
      <div id='sidebardrawer'>
        <Divider />
        {(folderLoading || selectLoading || isSubLoading) ? <CircularProgress className={classes.marginspace}/> :
        <List>
          { getFolderSideBarContents() }  
        </List>}
      </div>
    </div>
  );
}

export default connect(s => ({}))(Folder);