import update from 'immutability-helper'
import apicall from '../utils/api-call'

const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"

const UPDATE_FILES_LIST = "UPDATE_FILES_LIST"
const SET_CURRENT_UPLOAD = "SET_CURRENT_UPLOAD"

const GET_UPLOAD_LINK_ATTEMPT = "GET_UPLOAD_LINK_ATTEMPT"
const GET_UPLOAD_LINK_SUCCESS = "GET_UPLOAD_LINK_SUCCESS"
const GET_UPLOAD_LINK_FAILURE = "GET_UPLOAD_LINK_FAILURE"

const UPLOAD_FILE_ATTEMPT = "UPLOAD_FILE_ATTEMPT"
const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE"

const PROCESS_IMAGE_ATTEMPT = "PROCESS_IMAGE_ATTEMPT"
const PROCESS_IMAGE_SUCCESS = "PROCESS_IMAGE_SUCCESS"
const PROCESS_IMAGE_FAILURE = "PROCESS_IMAGE_FAILURE"
const CLEAR_UPLOADED_FILES = "CLEAR_UPLOADED_FILES"
const TOGGLE_FAST_UPLOAD = "TOGGLE_FAST_UPLOAD"
const SET_FAST_UPLOAD = "SET_FAST_UPLOAD"
const UNSET_FAST_UPLOAD = "UNSET_FAST_UPLOAD"
const SET_UPLOADING_PROCESS = "SET_UPLOADING_PROCESS"
const UNSET_UPLOADING_PROCESS = "UNSET_UPLOADING_PROCESS"
const SET_FILES_SIZE = "SET_FILES_SIZE"
const UNSET_FILES_SIZE = "UNSET_FILES_SIZE"
const SET_UPLOAD_SIZE = "SET_UPLOAD_SIZE"
const UNSET_UPLOAD_SIZE = "UNSET_UPLOAD_SIZE"
const SET_UPLOAD_COMPLETE = "SET_UPLOAD_COMPLETE"
const SET_UPLOAD_READY = "SET_UPLOAD_READY"

const UPDATE_STATE = "UPDATE_STATE"

const LOGIN_URL = "https://myra-login.azurewebsites.net/api/Login"
const GET_UPLOAD_LINK = "https://myra-get-upload-link.azurewebsites.net/api/GetUploadLink"
const FAST_PROCESS_IMAGE_URL = "https://myra-enqueue.azurewebsites.net/api/ProcessImage"
const PROCESS_IMAGE_URL = "https://myra-queue-process-image.azurewebsites.net/api/ProcessImageNow"

const loginSuccess = (payload) => { return { type: LOGIN_SUCCESS, payload: payload } }

const getUploadLinkAttempt = (payload) => { return { type: GET_UPLOAD_LINK_ATTEMPT, payload: payload } }
const getUploadLinkSuccess = (payload) => { return { type: GET_UPLOAD_LINK_SUCCESS, payload: payload } }
const getUploadLinkFailure = (payload) => { return { type: GET_UPLOAD_LINK_FAILURE, payload: payload } }

const processImageAttempt = (payload) => { return { type: PROCESS_IMAGE_ATTEMPT, payload: payload } }
const processImageSuccess = (payload) => { return { type: PROCESS_IMAGE_SUCCESS, payload: payload } }
const processImageFailure = (payload) => { return { type: PROCESS_IMAGE_FAILURE, payload: payload } }

const uploadFileAttempt = (payload) => { return { type: UPLOAD_FILE_ATTEMPT, payload: payload } }
const uploadFileSuccess = (payload) => { return { type: UPLOAD_FILE_SUCCESS, payload: payload } }
const uploadFileFailure = (payload) => { return { type: UPLOAD_FILE_FAILURE, payload: payload } }


export const setCurrentUpload = () => { return { type: SET_CURRENT_UPLOAD } }
export const clearUploadedFiles = () => { return { type: CLEAR_UPLOADED_FILES } }
export const updateFilesList = (payload) => { return { type: UPDATE_FILES_LIST, payload: payload } }
export const updateState = (payload) => { return { type: "UPDATE_STATE", payload: payload } }
export const toggleFastUpload = () => ({ type: TOGGLE_FAST_UPLOAD })

export const setUploadingProcess = () => ({ type: SET_UPLOADING_PROCESS })
export const unsetUploadingProcess = () => ({ type: UNSET_UPLOADING_PROCESS })
export const setFilesSize = (payload) => ({ type: SET_FILES_SIZE, payload: payload })
export const unsetFilesSize = () => ({ type: UNSET_FILES_SIZE })
export const setUploadSize = (payload) => ({ type: SET_UPLOAD_SIZE, payload: payload })
export const unsetUploadSize = () => ({ type: UNSET_UPLOAD_SIZE })
export const setUploadComplete = () => ({ type: SET_UPLOAD_COMPLETE })
export const setUploadReady = () => ({ type: SET_UPLOAD_READY })

const initialState = {
  AccountName: null,
  CurrentFolder: null,
  files: {},
  uploaded_files: {},
  current_uploads: {},
  latest_uploaded: [],
  uploaded_count: 0,
  files_count: 0,
  fast_upload: false,     //For UI
  fast_upload_option: false, //For Process
  is_uploading: false,
  files_size: 0,
  upload_size: {},
  upload_flag: 0 // 0: ready, 1: complete
}
let upload_watcher = 0;

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_ATTEMPT: {
      const { path } = action.payload
      const file = state.current_uploads[path]
      return update(state, {
        current_uploads: { 
          [path]: {
              status: { $set: "uploading"}
          }
        }
      })
    }
    case UPLOAD_FILE_SUCCESS: {
      const { path } = action.payload
      const file = state.current_uploads[path]
      if(!file){ return state }
      return update(state, {
        current_uploads: { 
          [path]: {
            $merge: {
              uploaded: true,
              status: "process",
              retries: 0
            }
          }
        }
      });
    }
    case UPLOAD_FILE_FAILURE: {
      const { path } = action.payload
      const file = state.current_uploads[path]
      const retries = file && file.retries
      return update(state, {
        current_uploads: { 
          [path]: {
            $merge: {
              uploaded: true,
              status: "upload_file_failure",
              retries: retries + 1,
              last_attempt: Date.now() 
            }
          }
        }
      });
    }
    case GET_UPLOAD_LINK_ATTEMPT: {
      const { path } = action.payload;
      return update(state, {
        current_uploads: {
          [path]: {
            status: { $set: "getting_link"}
          }
        }
      });
    }
    case GET_UPLOAD_LINK_SUCCESS: {
      const { Link, url, path } = action.payload;
      const { current_uploads } = state
      if(!current_uploads[path]){ return state }
      return update(state, {
        current_uploads: {
          [path]: {
            $merge: {
              Link: Link,
              status: "upload",
              retries: 0
            }
          }
        }
      });
    }
    case GET_UPLOAD_LINK_FAILURE: {
      const { path } = action.payload;
      const { current_uploads } = state
      const retries = state.current_uploads[path].retries
      return update(state, {
        current_uploads: {
          [path]: {
            $merge: {
              status: "getting_link_failure",
              retries: retries + 1,
              last_attempt: Date.now()
            }
          }
        }
      });
    }
    case PROCESS_IMAGE_ATTEMPT: {
      const { path } = action.payload
      return update(state, {
        current_uploads: { 
          [path]: {
            $merge: {
              status: 'processing'
            }
          }
        }
      });
    }
    case PROCESS_IMAGE_SUCCESS: {
      const { files, uploaded_files, latest_uploaded, current_uploads, uploaded_count} = state
      const {path} = action.payload
      const uploaded_file = files[path]
      const new_file = [path, uploaded_file.size]
      const updated_latest_uploaded = [new_file, ...latest_uploaded].splice(0,20)
      const new_state = update(state, {
        current_uploads: { $unset: [path] },
        uploaded_files: { $merge: { [path]: uploaded_file.size } },
        latest_uploaded: {$set: updated_latest_uploaded},
        uploaded_count: { $set: uploaded_count + 1}
      });
      return new_state
    }
    case PROCESS_IMAGE_FAILURE: {
      const { path } = action.payload
      const { file } = state.current_uploads[path]
      const retries = file && file.retries
      return update(state, {
        current_uploads: { 
          [path]: {
            $merge: {
              uploaded: true,
              status: "process_image_failure",
              retries: retries + 1,
              last_attempt: Date.now()
            }
          }
        }
      });
    }
    case UPDATE_FILES_LIST: {
      const { files, files_count, fast_upload_option } = action.payload;
      upload_watcher = 0;
      return update(state, {
          files_count: { $set: files_count },
          files: {
            $set: files
          },
          fast_upload_option: {
            $set: fast_upload_option
          }
      });
    }
    case TOGGLE_FAST_UPLOAD: {
      return update(state, {
          fast_upload: {
            $set: !state.fast_upload
          },
      });
    }
    case SET_UPLOADING_PROCESS: {
      return update(state, {
          is_uploading: {
            $set: true
          },
      });
    }
    case UNSET_UPLOADING_PROCESS: {
      return update(state, {
        is_uploading: {
            $set: false
          },
      });
    }  
    case SET_FILES_SIZE: {
      const { size } = action.payload
      return update(state, {
          files_size: {
            $set: size
          },
      });
    }
    case UNSET_FILES_SIZE: {
      return update(state, {
        files_size: {
            $set: 0
          },
      });
    }  
    case SET_UPLOAD_SIZE: {
      const { size, path } = action.payload
      let upload_size_ = state.upload_size;
      upload_size_ = update(upload_size_, {
        [path]: { 
          $set: { size } 
        }
      })
      return update(state, {
          upload_size: {
            $set: upload_size_
          },
      });
    }
    case UNSET_UPLOAD_SIZE: {
      const {upload_size} = state
      return update(state, {
        upload_size: {
            $set: { $set: Array(upload_size.length).fill(null) }
          },
      });
    }    
    case UPDATE_STATE: {
      const { field, value } = action.payload;
      return update(state, {
          [field]: {
            $set: value
          },
      });
    }
    case CLEAR_UPLOADED_FILES: {
      return initialState;
    }
    case SET_UPLOAD_COMPLETE: {
      return update(state, {
        upload_flag: {
            $set: 1
          },
      });
    }
    case SET_UPLOAD_READY: {
      return update(state, {
        upload_flag: {
            $set: 0
          },
      });
    }
    case SET_CURRENT_UPLOAD: {
      const { files, files_count, uploaded_files, uploaded_count, current_uploads } = state;

      if(files_count == 0){ return state};
      if(files_count == Object.keys(current_uploads).length){ return state};
      if(files_count <= uploaded_count){ return state};

      //TODO: Optimize 
      const path = Object.keys(files).find(path => !current_uploads[path] && !uploaded_files[path])
      if(current_uploads[path]){ return state }
      const file = files[path]
      if(!file){ return state }
      return update(state, {
        current_uploads: {
          $merge: {
              [path]: {
                file: file,
                Link: null,
                retries: 0,
                status: "get_link"
              } 
          }
        }
      });
    }
    default:
      return state;
  }
}

/*
curl --request POST \
  --url 'https://myra-login.azurewebsites.net/api/Login' \
  --header 'Content-Type: application/json' \
  --data '{ "Name": "username", "Password": "password" }'
  */
export const sendLoginRequest = ({username, password}) => {
  return dispatch => {
    return apicall({
      url: LOGIN_URL,
      method: "POST",
      type: "json",
      params: { Name: username, Password: password}
    })
    .then(response => {
        const res = JSON.parse(response)
        dispatch(loginSuccess(res));
      })
      .catch(err => {
          console.log({
            message: "Error: Unable to login"
          })
      });
  };
};

export const uploadBlob = ({path}) => {
  return (dispatch, getState) => {
    const state = getState()
    const { current_uploads } = state.upload
    const current_upload = current_uploads[path]
    const upload_link = current_upload && current_upload.Link
    const file = current_upload && current_upload.file
    dispatch(uploadFileAttempt({path}))
      return apicall({
        url: upload_link,
        method: "Put",
        headers: [["x-ms-blob-type","BlockBlob"]],
        type: "form",
        file: file,
        dispatch: dispatch
      })
      .then(response => {
        if(response.status == 201){
          dispatch(uploadFileSuccess({path}))
        }else{
          dispatch(uploadFileFailure({path}))
          setTimeout(() => dispatch(uploadBlob({path})), 2000)
        }
      })
  }
}

/*
 * curl --request GET --url 'https://myra-get-upload-link.azurewebsites.net/api/GetUploadLink?AccountName=username&FileName=%2Fmyra.jpg' --header 'Token: c7g2d976-b021-47ee-adbb-bd3fa7b41f48'
 * */
export const getUploadLink = ({ FileName, path}) => {
  return dispatch => {
    dispatch(getUploadLinkAttempt({path}));
    const AccountName = localStorage.getItem("name")
    return apicall({
      url: GET_UPLOAD_LINK,
      method: "GET",
      type: "form",
      params: { AccountName, FileName }
    })
    .then(response => {
      let res = null
      if(response.status == 200){
        res = JSON.parse(response.responseText)
      }
      if(res && res.Link){
        dispatch(getUploadLinkSuccess({...res, path}));
      }else{
        dispatch(getUploadLinkFailure({path}));
        setTimeout(() => dispatch(getUploadLink({AccountName, FileName, path})), 10000)
      }
    })
  };
};

export const processImage = ({path, total}) => {
  return (dispatch, getState) => {
    const state = getState()
    const AccountName = localStorage.getItem("name")
    const { current_uploads, uploaded_count } = state.upload
    const current_upload = current_uploads[path]
    const {Link} = current_upload
    dispatch(processImageAttempt({path}));
    return apicall({
      url: state.upload.fast_upload_option ? FAST_PROCESS_IMAGE_URL : PROCESS_IMAGE_URL,
      method: "POST",
      type: "json",
      params: { AccountName: AccountName, Url: Link.split("?")[0] }
    })
    .then(response => {
        let res = null;
        if(response.status == 200){
          res = JSON.parse(response.responseText)
        }
        if(res) { 
          dispatch(processImageSuccess({...res, path})) 

          upload_watcher++;
          if (upload_watcher === total) {
            dispatch(unsetUploadingProcess());
            dispatch(unsetFilesSize());
            dispatch(unsetUploadSize());
            dispatch(setUploadComplete());
            //setTimeout(() => dispatch(window.location.reload()), 2000);
          }
        } else { 
          dispatch(processImageFailure({path})) 
          setTimeout(() => dispatch(processImage({path})), 2000)
        }
      })
  };
};

