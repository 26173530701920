import React, { useContext } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import './index.css';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '-50px'
    },
    loader: {
      position: 'relative',
      width: '60px',
      height: '60px',
      borderRadius: '50%',
      margin: '75px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    indicator: {
      marginTop: '64px',
      fontFamily: 'Muli',
    }
}));

const WebLoading = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const appTheme = useSelector(store => store.setting.theme);

  return (
    <div className={classes.root}>
        <div className={classes.loader}>
            <div className="loader-outter"></div>
            <div className="loader-inner"></div>
            <h3 className={classes.indicator} style={{color: appTheme.textColor}}>{dictionary.appLabelWebloading}</h3>
        </div>
    </div>    
  )
}

export default WebLoading;

