import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../localization/LanguageContainers';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    toolbar: {
      display: 'flex',
      width: '100%',
      font: `400 12px/24px Muli`,
      marginTop: '10px',
      padding: '0 30px'
    },
    iconRoot: {   
      height: '100%',
      marginRight: '10px',
      marginTop: '-6px'
    },
    iconDelRoot: {   
      height: '100%',
      marginTop: '-6px'
    },
    iconThumbRoot: {   
      display: 'contents'
    },
    controls: {
        right: '36px',
        position: 'absolute'
    }
}));

function SettingUser(props) {
  // const { dictionary } = useContext(LanguageContext);
  const classes = useStyles(); 
  
  return (
      <div className={classes.root}>
          <div className={classes.toolbar}>
                Account
          </div>
      </div>
  )
}

export default SettingUser
