import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../../localization/LanguageContainers';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useWindowSize } from '../../../helper/window-size';
import { getFolderInfo } from '../../../helper/get-folder';
import { getAccountName } from '../../../helper/get-account-name';
import { useSortState, useLocationQuery, useSelectState, useSelContentState, useAccountState, useSearchState } from '../../../helper/routing-analyser';
import { handleSort, handleFolderSelect, handleUploaderContents } from '../../../utils/folder-controls';
import { API_GET_PARENT_FOLDER_URL, ROUTE_PARAM_SELECT, TOOL_FIXED_HEIGHT } from '../../../utils/const';
import WebLoading from '../../../components/web-loading';
import EmptyData from '../../../icons/EmptyData';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from '@material-ui/lab/Pagination';
import { Actions } from '../../../store/folder-reducer';

let tableClickFlag = false;
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'block',
      overflowY: 'auto'
    },
    head: {
      display: 'flex',
      font: `400 12px/16px Muli`,
      fontWeight: `bold`,
      padding: '0 10px',
      flexDirection: 'row',
      height: '52px',
      alignItems: 'center',
      letterSpacing: '0.01071em',
      borderRadius: '6px 6px 0 0',
      wordBreak: 'break-all'
    },
    body: {
      display: 'block',
      wordBreak: 'break-all',
      font: `400 12px/16px Muli`,
      fontWeight: `bold`,
    },
    title: {
      width: '30%',
      padding: '0 5px',
    },
    count: {
      width: '25%', 
      height: '52px',
      textAlign: 'center',
      cursor: 'pointer',
      '&:hover' : {
        backgroundColor: '#DFDFDF20'
      }
    },
    countbd: {
      width: '25%', 
      textAlign: 'center',
    },
    created: {
      width: '30%', 
      height: '52px',
      textAlign: 'center', 
      cursor: 'pointer',
      '&:hover' : {
        backgroundColor: '#DFDFDF20'
      }
    },
    createdbd: {
      width: '30%', 
      textAlign: 'center', 
    },
    uploader: {
      width: '15%', 
      textAlign: 'center', 
      padding: '16px 5px',
      '&:hover': {
        backgroundColor: '#AAAAAA30'
      }
    },
    tablerow: {
      display: 'flex',
      height: '52px',
      alignItems: 'center',
      padding: '0 10px',
      border: '1px solid #CBCBCB',
      borderTop: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#B0B0B020',
      }
    },
    tablerowbox: {        
      display: 'block',
    },
    tableempty: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      padding: '10px 0',
      color: 'black'
    },
    thumbnails: {
      display: 'flex',
      flexFlow: 'row nowrap',
      width: '100%',
      boxSizing: 'border-box',
      cursor: 'pointer'
    },
    thumbnail: {
      backgroundSize: 'contain',
      position: 'relative',
      width: '100%',
      paddingTop: '33%',
      height: '0px',
      flexGrow: '1',
      display: 'flex',
      margin: '0',
      display: 'block',
      backgroundColor: '#FFFFFF'
    },
    hidenimage: {
      display: 'none'
    },
    paginationbar: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '10px'
    },
}));

const FolderList = (props) => {
    const dispatch = useDispatch();
    const { dictionary } = useContext(LanguageContext);
    const [width, height] = useWindowSize();
    const [selectLoading, setSelectLoading] = useState(false);
    const classes = useStyles();    
    const token = useSelector(store => store.login.token);
    const appTheme = useSelector(store => store.setting.theme); 
    const themeName = useSelector(store => store.setting.themeName); 
    const sort = useSortState(); 
    const query = useLocationQuery(); 
    const selected = useSelectState();
    const selectedContent = useSelContentState();
    const account = useAccountState(); 
    const search = useSearchState();
    const history = useHistory(); 
    const contentHeight = height - TOOL_FIXED_HEIGHT;

    const isLoading1 = useSelector(store => store.folder.isFolderUserListFetching);    
    const data1 = useSelector(store => store.folder.dataFolderUserList);
    const isLoading2 = useSelector(store => store.folder.isSearchFolderListFetching);
    const data2 = useSelector(store => store.folder.dataSearchFolderList);
    const isLoading3 = useSelector(store => store.folder.isFolderListFetching);
    const data3 = useSelector(store => store.folder.dataFolderList);
    const isLoading = account ? isLoading1 : search ? isLoading2: isLoading3;
    const data = account ? data1 : search ? data2 : data3;   

    useEffect(()=>{
      if (!selected) {
        if (account) {
          dispatch(Actions.getFolderUserList(sort, account));
        } else if (search) {
          dispatch(Actions.getSearchFolderList(sort, search));
        } else {
          dispatch(Actions.getFolderList(sort));
        }
      } else {
        if (account) {
          dispatch(Actions.getFolderUserList(sort, account));
        } else if (search) {
          dispatch(Actions.getSearchFolderList(sort, search));
        } else if (selected === ROUTE_PARAM_SELECT.CATEGORY) {
          dispatch(Actions.getCategoryFolderList(sort, selectedContent));
        } else {
          dispatch(Actions.getFolderList(sort));       
        }        
      }
    }, []);

    const IPagination = withStyles((theme) => ({    
      root: {      
        '& .MuiPaginationItem-root': {
          color: themeName === 'LightTheme' ? '' : 'white',
         }
      },
    }))(Pagination);

    const handleSortByCount = () => {
      const newSort = sort === "filesdesc" ? "files" : "filesdesc";
      handleSort(history, newSort, query, props);
    }

    const handleSortByCreated = () => {
      const newSort = sort === "createddesc" ? "created" : "createddesc";
      handleSort(history, newSort, query, props);
    }

    const handleUploader = (uploader) => {
      tableClickFlag = true;
      handleUploaderContents(history, uploader, props);
    }

    const Thumbnails = ({ img_url }) => {
      const [isLoaded, setLoaded] = useState(false);
      const backgroundImage = "url(" + String(img_url) + ")";
      const backgroundSize = "cover";
      const backgroundPosition = "center center";
      const style = {
        backgroundImage: backgroundImage,
        backgroundSize,
        backgroundPosition,
      };
  
      var onLoadImage = () => {
        setLoaded(true);
      };
  
      return (
        <>
          <div className={classes.thumbnail} style={style}/>
          {isLoaded ? null : <div className={classes.hidenimage} src={img_url} onLoad={onLoadImage} />}
        </>
      );
    };

    return (
        <>
          {(isLoading || selectLoading) ? <WebLoading/> : <>          
            {data.length === 0 ? <div className={classes.tableempty}><EmptyData size={100}/><h3 style={{color: appTheme.textColor}}>{dictionary.tblLabelEmptyData}</h3></div> :
              <>
                <IPagination 
                  className={classes.paginationbar} 
                  //count={Math.floor(front_page_folders_count/DEFAULT_FOLDER_PAGE_COUNT) + ((front_page_folders_count%DEFAULT_FOLDER_PAGE_COUNT === 0) ? 0 : 1)}
                  count={6}
                  size="small"
                  color="secondary"
                  //page={Object.values(props.pageFolder)[0]} 
                  page={2} 
                  //onChange={handleChangePageFolder}
                />
                <div className={classes.root} style={{color: appTheme.textColor, backgroundColor: appTheme.backgroundColor, height: `${contentHeight}px`}}>
                    <div className={classes.head} style={{color: appTheme.textColor, backgroundColor: appTheme.backgroundColorFifth}}>
                        <div className={classes.title}>
                            {dictionary.tblLabelTitle}
                        </div>
                        <span className={classes.count} onClick={()=>handleSortByCount()}>
                            {dictionary.tblLabelCount}
                            {
                              sort.includes('files') ? <>
                                {sort === "files" && <ArrowDropUpIcon style={{marginTop: '6px'}}/>}
                                {sort === "filesdesc" && <ArrowDropDownIcon style={{marginTop: '6px'}}/>}
                              </> : <ArrowDropUpIcon style={{marginTop: '6px', opacity: '0.0'}}/>
                            }
                        </span>
                        <div className={classes.created} onClick={()=>handleSortByCreated()}>
                            {dictionary.tblLabelCreated}
                            {
                              sort.includes('created') ? <>
                                {sort === "created" && <ArrowDropUpIcon style={{marginTop: '6px'}}/>}
                                {sort === "createddesc" && <ArrowDropDownIcon style={{marginTop: '6px'}}/>}
                              </> : <ArrowDropUpIcon style={{marginTop: '6px', opacity: '0.0'}}/>
                            }
                        </div>
                        <div className={classes.uploader}>
                            {dictionary.tblLabelUploader}
                        </div>
                    </div>
                    <div className={classes.body} style={{color: appTheme.textColor, backgroundColor: appTheme.backgroundColorThird}}>
                        {data.map((folder, index) => {
                            const { title, folderImageUrl } = getFolderInfo(folder);
                            return (
                                <div 
                                    className={classes.tablerowbox}
                                    key={index}  
                                    onClick={(event) => {
                                      if (tableClickFlag) {
                                        tableClickFlag = false;
                                        return;
                                      }
                                      handleFolderSelect(event, history, query, dictionary, title, folder.id, folder.key, folder.level, setSelectLoading)
                                    }}
                                >
                                    <div className={classes.tablerow}>
                                        <div className={classes.title}>
                                            {title}
                                        </div>
                                        <div className={classes.countbd}>
                                            {folder.fileCount || 0}
                                        </div>
                                        <div className={classes.createdbd}>
                                            {folder.created.slice(0, 10)}
                                        </div>
                                        <div className={classes.uploader} onClick={() => handleUploader(folder.guestName ? folder.guestName : getAccountName())}>
                                            {folder.guestName ? folder.guestName : getAccountName()}
                                        </div>
                                    </div>
                                    {folderImageUrl ? (
                                        <div className={classes.thumbnails}>
                                            <Thumbnails img_url={folderImageUrl}/>
                                        </div>
                                    ) : null}
                                </div>                        
                            );
                        })}
                    </div>
                </div></>} 
              </>
            }
        </>
    );
}

export default withRouter(FolderList);