import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import CheckSolid from '../../icons/CheckSolid';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { useWindowSize } from '../../helper/window-size';
import styled from "styled-components";
import { useSelContentState } from '../../helper/routing-analyser';
import { Actions } from '../../store/folder-reducer';
import PhotoLightBox from '../../components/photo-lightbox';
import { THUMBNAIL_SIZE_LARGE } from '../../utils/const';

const PhotoGrid = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const { images, filelist } = props;
  const appTheme = useSelector(store => store.setting.theme);
  const guestname = localStorage.getItem("guestname");
  const [width, height] = useWindowSize();
  const selectedContent = useSelContentState();

  const folder = useSelector(store => store.folder.selectedFolder);
  const clipboard = useSelector(store => store.folder.clipboardPhotos);
  const thumbnailSize = useSelector(store => store.setting.thumbnailSize);

  useEffect(()=>{
    if (folder && folder.id === selectedContent) {
      return;
    } else {
      dispatch(Actions.getFolderById(selectedContent));
    }
  }, [selectedContent]);

  const useStyles = makeStyles((theme) => ({
    photoborder: {
        border: thumbnailSize === THUMBNAIL_SIZE_LARGE ? `3px solid ${appTheme.backgroundColor}` : `2px solid ${appTheme.backgroundColor}`,
        '&:hover': {
          border: thumbnailSize === THUMBNAIL_SIZE_LARGE ? `3px solid ${appTheme.buttonColor}` : `2px solid ${appTheme.buttonColor}`,
        }
    },
    photoborderselect: {
        border: thumbnailSize === THUMBNAIL_SIZE_LARGE ? `3px solid ${appTheme.buttonColor}` : `2px solid ${appTheme.buttonColor}`,
    },
  }));
  const classes = useStyles();  

  const PhotoItems = styled.figure`
      z-index: 5;
      &:hover {
        .checkitem {
          opacity: 1
        }
      }
      .checkitem {
        width: 20px;
        height: 20px;
        background-color: ${appTheme.buttonColor};
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
        color: #FFFFFF;
        text-align: center;
        opacity: 0;
      }
      .checkitemselect {
        width: 20px;
        height: 20px;
        background-color: ${appTheme.buttonColor};
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
        color: #FFFFFF;
        text-align: center;
        opacity: 1;
      } 
    `;  

  const [lightboxMode, setLightboxMode] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleCheckItems = (dataItem) => () => {
    dispatch(Actions.addClipboardPhotos({ selectedKeys: [dataItem.id] }));
  }

  const handleItem = (dataItem) => () => {
    const photoIndex = images.findIndex(i => i.id === dataItem.id)
    setPhotoIndex(photoIndex)
    setLightboxMode(true)
  }

  const lightboxProps = {
    images, filelist,
    photoIndex, setPhotoIndex,
    setLightboxMode, lightboxMode
  }
  
  return (
    <div id='dscontainer' className="grid">
        {images.map((dataItem, index) => {
        var x = width > 600 ? thumbnailSize : thumbnailSize / 2;
        var flexBasis = Math.ceil(dataItem.photo_width * x / dataItem.photo_height);
        var flexGrow =  Math.ceil(dataItem.photo_width * 100 / dataItem.photo_height);
        var paddingBottom =  Math.ceil(dataItem.photo_height / dataItem.photo_width * 100.0);
        return (
        <PhotoItems 
            key={index} 
            id='item'
            style={{ flexGrow: flexGrow, flexBasis: `${flexBasis}px`, cursor: "pointer" }} 
            className={(clipboard && clipboard.includes(dataItem.id))?classes.photoborderselect:classes.photoborder} >
            <i style={{ paddingBottom: `${paddingBottom}%` }}></i>
            <div 
                className={(clipboard && clipboard.includes(dataItem.id))?'checkitemselect':isMobile?'checkitemselect':'checkitem'}
                onClick={ (e) => {
                if (String(guestname) !== 'null' && guestname !== folder.guestName) {
                    toast.error(dictionary.appToastGuestPermissionError);
                    return;
                }
                if (!e.nativeEvent.ctrlKey && !e.nativeEvent.metaKey) {
                    handleCheckItems(dataItem)();
                    e.nativeEvent.preventDefault();
                }
                }}
            > 
                {(clipboard && clipboard.includes(dataItem.id)) && <CheckSolid size={14} />}
            </div>
            <img 
                src={dataItem.icon} 
                alt="placeholder"
                onClick={ (e) => {
                if (!e.nativeEvent.ctrlKey && !e.nativeEvent.metaKey) {
                    handleItem(dataItem)();
                    e.nativeEvent.preventDefault();
                } else {
                    window.open(dataItem.hires_url, "_blank");
                    e.nativeEvent.preventDefault();
                }
                }} />
        </PhotoItems>)
        })}
        <PhotoLightBox {...lightboxProps}/>
    </div>
  )
}

export default PhotoGrid;

