import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../localization/LanguageContainers';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core/';
import Select from 'react-select';
import { getAccountName } from '../../../helper/get-account-name';
import { uploadSettings } from '../../../store/setting-reducer';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      textAlign: 'center'
    },
    card: {
      minWidth: '330px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '20px',
      marginTop: '20px',
      boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px'
    },
    item: {
      margin: '10px 0'
    },
    itemtitle: {
      textAlign: 'left',
      fontFamily: 'Muli'
    },
    ctrlitem: {
      margin: '20px 0 10px 0'
    },
    spinner: {
      color: 'white',
      marginRight: '10px'
    },
}));

function SettingGeneral(props) {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles(); 
  const settingInfo = useSelector((state) => state.setting);
  const [loading, setLoading] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(settingInfo.allowGuests || false)
  const [selectedOption2, setSelectedOption2] = useState(settingInfo.guestsCanAddContent || false)
  const dispatch = useDispatch();
  const appTheme = settingInfo.theme;

  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const SaveButton = withStyles((theme) => ({
    root: {
      width: '100%',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);

  const getOption = (value) => {
    let rv = {};
    options.forEach(o=>{
      if (o.value === value)
       rv = o;
    })
    return rv;
  }

  const handleChange1 = option1 => {
    setSelectedOption1(option1.value);
  };

  const handleChange2 = option2 => {
    setSelectedOption2(option2.value);
  };

  const handleSave = () => {
    if (loading) return;
    setLoading(true);
    let values = {};
    values.AccountName = getAccountName();
    values.AllowGuests = selectedOption1;
    values.GuestsCanAddContent = selectedOption2;
    values.TextColor = settingInfo.textColor;
    values.PrimaryColor = settingInfo.primaryColor;
    values.SecondaryColor = settingInfo.secondaryColor;
    values.BackgroundColor = settingInfo.backgroundColor;
    values.BannerUrl = settingInfo.bannerUrl;
    values.Introduction = settingInfo.introduction;
    values.LogoUrl = settingInfo.logoUrl;
    dispatch(uploadSettings(values, setLoading, false));
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.card} style={{backgroundColor:appTheme.backgroundColor}}>
        <div className={classes.item}>
          <h4 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelAllowGuests}</h4>
          <Select
            value={getOption(selectedOption1)}
            onChange={(o)=>handleChange1(o)}
            options={options}
          />
        </div>
        <div className={classes.item}>
          <h4 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelGuestsCanAddContent}</h4>
          <Select
            value={getOption(selectedOption2)}
            onChange={(o)=>handleChange2(o)}
            options={options}
          />
        </div>
        <div className={classes.ctrlitem}>
          <SaveButton onClick={()=>handleSave()}>
            {loading && <CircularProgress
              className={classes.spinner}
              size={20}
            />}
            {dictionary.btnLabelSave}
          </SaveButton>
        </div>
      </div>
    </div>
  )
}

export default SettingGeneral
