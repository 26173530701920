import update from 'immutability-helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_GET_FOLDER_BY_ID_URL, API_GET_PARENT_FOLDER_URL, API_GET_FILES_BY_FOL_ID_URL } from '../utils/const';
import { getAccountName } from '../helper/get-account-name';
import apicall from '../utils/api-call';

toast.configure();
export const ActionsTypes = {
    GET_FOLDER_LIST_FETCHING: "GET_FOLDER_LIST_FETCHING",
    GET_FOLDER_LIST_SUCCESS: "GET_FOLDER_LIST_SUCCESS",
    GET_FOLDER_LIST_FAILED: "GET_FOLDER_LIST_FAILED",
    GET_FOLDER_USER_LIST_FETCHING: "GET_FOLDER_USER_LIST_FETCHING",
    GET_FOLDER_USER_LIST_SUCCESS: "GET_FOLDER_USER_LIST_SUCCESS",
    GET_FOLDER_USER_LIST_FAILED: "GET_FOLDER_USER_LIST_FAILED",
    GET_SEARCH_FOLDER_LIST_FETCHING: "GET_SEARCH_FOLDER_LIST_FETCHING",
    GET_SEARCH_FOLDER_LIST_SUCCESS: "GET_SEARCH_FOLDER_LIST_SUCCESS",
    GET_SEARCH_FOLDER_LIST_FAILED: "GET_SEARCH_FOLDER_LIST_FAILED",
    GET_SUBFOLDER_LIST_FETCHING: "GET_SUBFOLDER_LIST_FETCHING",
    GET_SUBFOLDER_LIST_SUCCESS: "GET_SUBFOLDER_LIST_SUCCESS",
    GET_SUBFOLDER_LIST_FAILED: "GET_SUBFOLDER_LIST_FAILED",
    GET_PAGES_SUBFOLDER_LIST_FAILED: "GET_PAGES_SUBFOLDER_LIST_FAILED",
    GET_PAGES_SUBFOLDER_LIST_FETCHING: "GET_PAGES_SUBFOLDER_LIST_FETCHING",
    GET_PAGES_SUBFOLDER_LIST_SUCCESS: "GET_PAGES_SUBFOLDER_LIST_SUCCESS",
    GET_FOLDER_FETCHING: "GET_FOLDER_FETCHING",
    GET_FOLDER_SUCCESS: "GET_FOLDER_SUCCESS",
    GET_FOLDER_FAILED: "GET_FOLDER_FAILED",
    GET_FILE_LIST_FETCHING: "GET_FILE_LIST_FETCHING",
    GET_FILE_LIST_SUCCESS: "GET_FILE_LIST_SUCCESS",
    GET_FILE_LIST_FAILED: "GET_FILE_LIST_FAILED",
    ADD_CLIPBOARD_PHOTOS: "ADD_CLIPBOARD_PHOTOS",
    ADD_CLIPBOARD_INDEXES: "ADD_CLIPBOARD_INDEXES",
    ADD_CLIPBOARD_ALL_PHOTOS: "ADD_CLIPBOARD_ALL_PHOTOS",
    CLEAR_CLIPBOARD_PHOTOS: "CLEAR_CLIPBOARD_PHOTOS",
}

export const getFolderList = (sort) => {
    return (dispatch) => {
        dispatch(Actions.getFolderListFetching());
        return apicall({
            url: `${API_GET_PARENT_FOLDER_URL}?AccountName=${getAccountName()}&SortBy=${sort}&Level=1`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getFolderListSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get folderlist.")
            }
            return dispatch(Actions.getFolderListFail());
        })
    }
};

export const getFolderUserList = (sort, account) => {
    const uploader = (getAccountName() === account) ? "null":account;
    return (dispatch) => {
        dispatch(Actions.getFolderUserListFetching());
        return apicall({
            url: `${API_GET_PARENT_FOLDER_URL}?AccountName=${getAccountName()}&Uploader=${uploader}&SortBy=${sort}&Level=1`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getFolderUserListSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get UserFolderList.")
            }
            return dispatch(Actions.getFolderUserListFail());
        })
    }
};


export const getSearchFolderList = (sort, search) => {
    return (dispatch) => {
        dispatch(Actions.getSearchFolderListFetching());
        return apicall({
            url: `${API_GET_PARENT_FOLDER_URL}?AccountName=${getAccountName()}&Condition=contains&Content=${search}&SortBy=${sort}`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getSearchFolderListSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get SearchFolderList.")
            }
            return dispatch(Actions.getSearchFolderListFail());
        })
    }
};

export const getSubFolderList = (key, level) => {
    return (dispatch) => {
        dispatch(Actions.getSubFolderListFetching());
        return apicall({
            url: `${API_GET_PARENT_FOLDER_URL}?AccountName=${getAccountName()}&Content=${key}&SortBy=natural&Level=${level}`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getSubFolderListSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get SubFolderList.")
            }
            return dispatch(Actions.getSubFolderListFail());
        })
    }
};

export const getPagesSubFolderList = (key, level, page) => {
    return (dispatch) => {
        dispatch(Actions.getPagesSubFolderListFetching());
        return apicall({
            url: `${API_GET_PARENT_FOLDER_URL}?AccountName=${getAccountName()}&Content=${key}&SortBy=natural&Level=${level}`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getPagesSubFolderListSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get PagesSubFolderList.")
            }
            console.log(res.result)
            return dispatch(Actions.getPagesSubFolderListFail());
        })
    }
};

export const getCategoryFolderList = (sort, category) => {
    return (dispatch) => {
        dispatch(Actions.getFolderListFetching());
        return apicall({
            url: `${API_GET_PARENT_FOLDER_URL}?AccountName=${getAccountName()}&Categories=${category}&SortBy=${sort}`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getFolderListSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get CategoryFolderList.")
            }
            return dispatch(Actions.getFolderListFail());
        })
    }
};

export const getFolderById = (folderid) => {
    return (dispatch) => {
        dispatch(Actions.getFolderFetching());
        return apicall({
            url: `${API_GET_FOLDER_BY_ID_URL}?AccountName=${getAccountName()}&FolderId=${folderid}`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.getFolderSuccess(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get selected Folder.")
            }
            return dispatch(Actions.getFolderFail());
        })
    }
};

export const getFilesByFolderId = (folderid) => {
    return (dispatch) => {
        dispatch(Actions.getFileListFetching());
        return apicall({
            url: `${API_GET_FILES_BY_FOL_ID_URL}?AccountName=${getAccountName()}&FolderId=${folderid}`,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null;
            if (response.status === 200) {
                res = JSON.parse(response.responseText)
            }
            if (res && res.Result) {
                return dispatch(Actions.getFileListSuccess(res.Result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to get files of the selected folder.")
            }
            return dispatch(Actions.getFileListFail());
        })
    }
};

export const Actions = {
    getFolderList,
    getFolderListFetching: () => ({ type: ActionsTypes.GET_FOLDER_LIST_FETCHING }),
    getFolderListSuccess: (payload) => ({ type: ActionsTypes.GET_FOLDER_LIST_SUCCESS, payload: payload }),
    getFolderListFail: () => ({ type: ActionsTypes.GET_FOLDER_LIST_FAILED }),
    getFolderUserList,
    getFolderUserListFetching: () => ({ type: ActionsTypes.GET_FOLDER_USER_LIST_FETCHING }),
    getFolderUserListSuccess: (payload) => ({ type: ActionsTypes.GET_FOLDER_USER_LIST_SUCCESS, payload: payload }),
    getFolderUserListFail: () => ({ type: ActionsTypes.GET_FOLDER_USER_LIST_FAILED }),
    getSearchFolderList,
    getSearchFolderListFetching: () => ({ type: ActionsTypes.GET_SEARCH_FOLDER_LIST_FETCHING }),
    getSearchFolderListSuccess: (payload) => ({ type: ActionsTypes.GET_SEARCH_FOLDER_LIST_SUCCESS, payload: payload }),
    getSearchFolderListFail: () => ({ type: ActionsTypes.GET_SEARCH_FOLDER_LIST_FAILED }),
    getSubFolderList,
    getSubFolderListFetching: () => ({ type: ActionsTypes.GET_SUBFOLDER_LIST_FETCHING }),
    getSubFolderListSuccess: (payload) => ({ type: ActionsTypes.GET_SUBFOLDER_LIST_SUCCESS, payload: payload }),
    getSubFolderListFail: () => ({ type: ActionsTypes.GET_SUBFOLDER_LIST_FAILED }),
    getPagesSubFolderList,
    getPagesSubFolderListFetching: () => ({ type: ActionsTypes.GET_PAGES_SUBFOLDER_LIST_FETCHING }),
    getPagesSubFolderListSuccess: (payload) => ({ type: ActionsTypes.GET_PAGES_SUBFOLDER_LIST_SUCCESS, payload: payload }),
    getPagesSubFolderListFail: () => ({ type: ActionsTypes.GET_PAGES_SUBFOLDER_LIST_FAILED }),
    getCategoryFolderList,
    getFolderById,
    getFolderFetching: () => ({ type: ActionsTypes.GET_FOLDER_FETCHING }),
    getFolderSuccess: (payload) => ({ type: ActionsTypes.GET_FOLDER_SUCCESS, payload: payload }),
    getFolderFail: () => ({ type: ActionsTypes.GET_FOLDER_FAILED }),
    getFilesByFolderId,
    getFileListFetching: () => ({ type: ActionsTypes.GET_FILE_LIST_FETCHING }),
    getFileListSuccess: (payload) => ({ type: ActionsTypes.GET_FILE_LIST_SUCCESS, payload: payload }),
    getFileListFail: () => ({ type: ActionsTypes.GET_FILE_LIST_FAILED }),
    addClipboardPhotos: (payload) => ({ type: ActionsTypes.ADD_CLIPBOARD_PHOTOS, payload: payload }),
    addClipboardIndexes: (payload) => ({ type: ActionsTypes.ADD_CLIPBOARD_INDEXES, payload: payload }),
    addClipboardAllPhotos: () => ({ type: ActionsTypes.ADD_CLIPBOARD_ALL_PHOTOS }),
    clearClipboardPhotos: () => ({ type: ActionsTypes.CLEAR_CLIPBOARD_PHOTOS }),
}

let initial = {
    isFolderListFetching: false,
    dataFolderList: [],
    isFolderUserListFetching: false,
    dataFolderUserList: [],
    isSearchFolderListFetching: false,
    dataSearchFolderList: [],
    isSubFolderListFetching: false,
    dataSubFolderList: [],
    isPagesSubFolderListFetching: false,
    dataPagesSubFolderList: [],
    isFolderFetching: false,
    selectedFolder: null,
    isFileListFetching: false,
    dataFileList: [],
    clipboardPhotos: [],
}

export default (state, action) => {
  state = state == null ? initial : state
  switch (action.type) {
    case ActionsTypes.GET_FOLDER_LIST_FETCHING: {
        return update(state, {
            isFolderListFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_FOLDER_LIST_SUCCESS: {
        return update(state, {
            isFolderListFetching: { $set: false },
            dataFolderList: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_FOLDER_LIST_FAILED: {
        return update(state, {
            isFolderListFetching: { $set: false },
            dataFolderList: { $set: [] },
        })
    }
    case ActionsTypes.GET_FOLDER_USER_LIST_FETCHING: {
        return update(state, {
            isFolderUserListFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_FOLDER_USER_LIST_SUCCESS: {
        return update(state, {
            isFolderUserListFetching: { $set: false },
            dataFolderUserList: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_FOLDER_USER_LIST_FAILED: {
        return update(state, {
            isFolderUserListFetching: { $set: false },
            dataFolderUserList: { $set: [] },
        })
    }
    
    case ActionsTypes.GET_SEARCH_FOLDER_LIST_FETCHING: {
        return update(state, {
            isSearchFolderListFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_SEARCH_FOLDER_LIST_SUCCESS: {
        return update(state, {
            isSearchFolderListFetching: { $set: false },
            dataSearchFolderList: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_SEARCH_FOLDER_LIST_FAILED: {
        return update(state, {
            isSearchFolderListFetching: { $set: false },
            dataSearchFolderList: { $set: [] },
        })
    }
    case ActionsTypes.GET_SUBFOLDER_LIST_FETCHING: {
        return update(state, {
            isSubFolderListFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_SUBFOLDER_LIST_SUCCESS: {
        return update(state, {
            isSubFolderListFetching: { $set: false },
            dataSubFolderList: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_SUBFOLDER_LIST_FAILED: {
        return update(state, {
            isSubFolderListFetching: { $set: false },
            dataSubFolderList: { $set: [] },
        })
    }
    case ActionsTypes.GET_PAGES_SUBFOLDER_LIST_FETCHING: {
        return update(state, {
            isPagesSubFolderListFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_PAGES_SUBFOLDER_LIST_SUCCESS: {
        return update(state, {
            isPagesSubFolderListFetching: { $set: false },
            dataPagesSubFolderList: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_PAGES_SUBFOLDER_LIST_FAILED: {
        return update(state, {
            isPagesSubFolderListFetching: { $set: false },
            dataPagesSubFolderList: { $set: [] },
        })
    }
    case ActionsTypes.GET_FOLDER_FETCHING: {
        return update(state, {
            isFolderFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_FOLDER_SUCCESS: {
        return update(state, {
            isFolderFetching: { $set: false },
            selectedFolder: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_FOLDER_FAILED: {
        return update(state, {
            isFolderFetching: { $set: false },
            selectedFolder: { $set: null },
        })
    }
    case ActionsTypes.GET_FILE_LIST_FETCHING: {
        return update(state, {
            isFileListFetching: { $set: true },
        })
    }
    case ActionsTypes.GET_FILE_LIST_SUCCESS: {
        return update(state, {
            isFileListFetching: { $set: false },
            dataFileList: { $set: action.payload },
        })
    }
    case ActionsTypes.GET_FILE_LIST_FAILED: {
        return update(state, {
            isFileListFetching: { $set: false },
            dataFileList: { $set: [] },
        })
    }
    case ActionsTypes.ADD_CLIPBOARD_PHOTOS: {
        const photos = action.payload.selectedKeys;
        const photos_clipboard = state.clipboardPhotos ? state.clipboardPhotos : [];
        const already_exist = photos.filter(i => photos_clipboard.includes(i));
        const unique = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
        const clipboard = [...photos_clipboard, ...photos].filter(i => !already_exist.includes(i));
        return update(state, {
            clipboardPhotos: { $set: unique(clipboard) }
        })
    }
    case ActionsTypes.ADD_CLIPBOARD_PHOTOS: {
        const indexes = action.payload.indexes;
        const metaKey = action.payload.metaKey;
        const allphotos = state.dataFileList ? state.dataFileList : [];
        const filterphotos = allphotos.filter((p, index) => indexes.includes(index) && p.id);
        const photos = filterphotos.map(p => p.id)
        const photos_clipboard = metaKey ? state.clipboardPhotos : [];
        const unique = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
        const clipboard = [...photos_clipboard, ...photos].filter(i => i);
        return update(state, {
            clipboardPhotos: { $set: unique(clipboard) }
        });
    }
    case ActionsTypes.ADD_CLIPBOARD_ALL_PHOTOS: {
        const photos = state.dataFileList ? state.dataFileList : []
        const photos_ids = photos.map(p => p.id)
        const photos_clipboard = state.clipboardPhotos
        const already_exist = photos.filter(i => photos_clipboard.includes(i))
        const unique = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
        const clipboard = [...photos_clipboard, ...photos_ids].filter(i => !already_exist.includes(i))
        return update(state, {
            clipboardPhotos: { $set: unique(clipboard) }
        })
    }
    case ActionsTypes.CLEAR_CLIPBOARD_PHOTOS: {
        return update(state, {
            clipboardPhotos: { $set: [] }
        })
    }
    default:
        return state;
  }
}

