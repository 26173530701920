import { ROUTE_PARAM_CONTENTS, ROUTE_PARAM_KEY, ROUTE_PARAM_SELECT, API_GET_PARENT_FOLDER_URL, ROUTE_PARAM_SIDEBAR } from './const';
import { getAccountName } from '../helper/get-account-name';
import apicall from '../utils/api-call';
import { toast } from 'react-toastify';

export const handleHome = (history, query, sidestate, props) => {    
    if (props.mobileOpen) {
        props.handleDrawerToggle();
    }

    query.delete(ROUTE_PARAM_KEY.SEARCH);
    query.delete(ROUTE_PARAM_KEY.CONTENT);
    query.delete(ROUTE_PARAM_KEY.SELECT);
    query.delete(ROUTE_PARAM_KEY.SELCONTENT);
    query.delete(ROUTE_PARAM_KEY.SELNOTIFY);
    query.delete(ROUTE_PARAM_KEY.SELKEY);
    query.delete(ROUTE_PARAM_KEY.SELLEVEL);   
    query.delete(ROUTE_PARAM_KEY.SELAPPEND);
    query.delete(ROUTE_PARAM_KEY.ACCOUNT);
    query.delete(ROUTE_PARAM_KEY.SIDEBAR);
    query.append(ROUTE_PARAM_KEY.SIDEBAR, sidestate);    
    history.push({pathname: '/', search: query.toString()});
}

export const handleCategorySelect = (event, history, query, dictionary, cid, cname, props) => {
    if (props.mobileOpen) {
        props.handleDrawerToggle();
    }

    const isNavigateNewTab = event.ctrlKey || event.metaKey;
    if (cid === null) {
        cid = 'null';
    }

    query.delete(ROUTE_PARAM_KEY.SEARCH);
    query.delete(ROUTE_PARAM_KEY.SELKEY);
    query.delete(ROUTE_PARAM_KEY.SELLEVEL);
    query.delete(ROUTE_PARAM_KEY.ACCOUNT);
    query.delete(ROUTE_PARAM_KEY.SIDEBAR);
    query.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.CATEGORY);
    query.delete(ROUTE_PARAM_KEY.CONTENT);
    query.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.HOME);    
    query.delete(ROUTE_PARAM_KEY.SELECT);
    query.append(ROUTE_PARAM_KEY.SELECT, ROUTE_PARAM_SELECT.CATEGORY);    
    query.delete(ROUTE_PARAM_KEY.SELCONTENT);
    query.append(ROUTE_PARAM_KEY.SELCONTENT, cid);   
    query.delete(ROUTE_PARAM_KEY.SELNOTIFY);
    query.append(ROUTE_PARAM_KEY.SELNOTIFY, cname);  
    history.push({pathname: '/', search: query.toString()});
}

export const getSubFolderCount = async (fKey, fLevel) => {
    const AccountName = getAccountName()
    const Content = fKey === 'root' ? '' : fKey
    const Condition = "startswith";
    const Count = "true";
    const Level = fLevel + 1;

    return apicall({
      url: API_GET_PARENT_FOLDER_URL,
      method: "GET",
      type: "form",
      params: { AccountName, Condition, Content, Count, Level }
    })
}

export const handleFolderSelect = (event, history, query, dictionary, fid, fkey, flevel, sfcount, props) => {
    if (props.mobileOpen) {
        props.handleDrawerToggle();
    }

    const isNavigateNewTab = event.ctrlKey || event.metaKey;

    query.delete(ROUTE_PARAM_KEY.SEARCH);
    query.delete(ROUTE_PARAM_KEY.SIDEBAR);
    query.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.FOLDER);
    query.delete(ROUTE_PARAM_KEY.CONTENT);
    query.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.BROWSER);    
    query.delete(ROUTE_PARAM_KEY.SELECT);
    query.append(ROUTE_PARAM_KEY.SELECT, ROUTE_PARAM_SELECT.FOLDER);    
    query.delete(ROUTE_PARAM_KEY.SELCONTENT);
    query.append(ROUTE_PARAM_KEY.SELCONTENT, fid);    
    query.delete(ROUTE_PARAM_KEY.SELKEY);
    query.append(ROUTE_PARAM_KEY.SELKEY, fkey);    
    query.delete(ROUTE_PARAM_KEY.SELLEVEL);
    query.append(ROUTE_PARAM_KEY.SELLEVEL, flevel);    
    query.delete(ROUTE_PARAM_KEY.SELAPPEND);
    query.append(ROUTE_PARAM_KEY.SELAPPEND, sfcount);     
    history.push({pathname: '/', search: query.toString()});
}

export const handleFolderSelectByUser = (event, history, query, dictionary, fid, fkey, flevel, sfcount, props) => {
    if (props.mobileOpen) {
        props.handleDrawerToggle();
    }

    const isNavigateNewTab = event.ctrlKey || event.metaKey;

    query.delete(ROUTE_PARAM_KEY.SEARCH);
    query.delete(ROUTE_PARAM_KEY.SIDEBAR);
    query.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.FOLDER_USER);
    query.delete(ROUTE_PARAM_KEY.CONTENT);
    query.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.BROWSER);    
    query.delete(ROUTE_PARAM_KEY.SELECT);
    query.append(ROUTE_PARAM_KEY.SELECT, ROUTE_PARAM_SELECT.FOLDER);    
    query.delete(ROUTE_PARAM_KEY.SELCONTENT);
    query.append(ROUTE_PARAM_KEY.SELCONTENT, fid);    
    query.delete(ROUTE_PARAM_KEY.SELKEY);
    query.append(ROUTE_PARAM_KEY.SELKEY, fkey);    
    query.delete(ROUTE_PARAM_KEY.SELLEVEL);
    query.append(ROUTE_PARAM_KEY.SELLEVEL, flevel);    
    query.delete(ROUTE_PARAM_KEY.SELAPPEND);
    query.append(ROUTE_PARAM_KEY.SELAPPEND, sfcount);     
    history.push({pathname: '/', search: query.toString()});
}