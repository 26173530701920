import React, { useContext, useState, useRef } from 'react';
import { LanguageContext } from '../../../localization/LanguageContainers';
import {Button, Icon, TextField, CircularProgress, InputAdornment, IconButton, Dialog } from '@material-ui/core/';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../../helper/window-size';
import { TOOL_FIXED_HEIGHT, THEMETYPE } from '../../../utils/const';
//import { ChromePicker } from "react-color";
import { getAccountName } from '../../../helper/get-account-name';
import Expand from 'react-expand-animated';
import { uploadSettings, Actions } from '../../../store/setting-reducer';
import { lightTheme, darkTheme } from '../../../utils/theme';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      textAlign: 'center',
      overflowY: 'auto'
    },
    card: {
      minWidth: '330px',
      width: '60%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '5px 20px',
    },
    item: {
      margin: '10px 0'
    },
    itemtitle: {
      textAlign: 'left',
      fontFamily: 'Muli'
    },
    ctrlitem: {
      margin: '40px 0 10px 0'
    },
    spinner: {
      color: 'white',
      marginRight: '10px'
    },
    itemcolor: {
      width: '100%',
      height: '52px',
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.7'
      },
      '&:active': {
        transform: 'scale(0.98)'
      }
    },
    itemcolorselector: {
      width: '100%',
      height: '52px',
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
      fontFamily: 'Muli',
      cursor: 'pointer',
      paddingTop: '14px',
      '&:hover': {
        opacity: '0.7'
      },
      '&:active': {
        transform: 'scale(0.98)'
      }
    }
}));

const getCurrentTheme = (id) => {
  let theme = {};
  switch (id) {
    case 'LightTheme':
      theme = THEMETYPE.LIGHTTHEME;
      break;
    case 'DarkTheme':
      theme = THEMETYPE.DARKTHEME;
      break;
    case 'CustomTheme':
        theme = THEMETYPE.CUSTOMTHEME;
        break;
  }
  return theme;
}

function SettingAppearance(props) {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const settingInfo = useSelector((state) => state.setting);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [introduction, setIntroduction] = useState(settingInfo.introduction || '');
  const [logourl, setLogoUrl] = useState(settingInfo.logoUrl || '');
  const [bannerurl, setBannerUrl] = useState(settingInfo.bannerUrl || '');
  const refLogoUrl = useRef();
  const refBannerUrl = useRef();
  const [primarycolor, setPrimaryColor] = useState(settingInfo.primaryColor || '#FFFFFF');
  const [secondarycolor, setSecondaryColor] = useState(settingInfo.secondaryColor || '#FFFFFF');
  const [textcolor, setTextColor] = useState(settingInfo.textColor || '#FFFFFF');
  const [backgroundcolor, setBackgroundColor] = useState(settingInfo.backgroundColor || '#FFFFFF');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [pickColor, setPickColor] = useState('#FFFFFFFF');
  const [pickColorSelector, setPickColorSelector] = useState(0);
  const [width, height] = useWindowSize();
  const contentHeight = height - TOOL_FIXED_HEIGHT;
  const [themeID, setThemeID] = useState(settingInfo.themeName || '');
  const [themeExpand, setThemeExpand] = useState(false);
  const currentTheme = getCurrentTheme(themeID);
  const appTheme = useSelector(store => store.setting.theme);

  const SaveButton = withStyles((theme) => ({
    root: {
      width: '100%',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);

  const ItemTextField = withStyles({
    root: {
      '& label': {
        color: appTheme.textColor,
      },
      '& label.Mui-focused': {
        color: appTheme.textColorThird,
      },
      '& label.Mui-disabled': {
        color: appTheme.textColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: appTheme.textColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: appTheme.textColor,
        },
        '&:hover fieldset': {
          opacity: '0.7',
          borderColor: appTheme.textColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: appTheme.textColorThird,
        },
        '&.Mui-disabled fieldset': {
          borderColor: appTheme.textColor,
        },
      },
    },
  })(TextField);

  const handleIntroduction = (e) => {
    setIntroduction(e.target.value)
  }

  const handleLogoUrl = (e) => {
    setLogoUrl(e.target.value)
  }

  const handleBannerUrl = (e) => {
    setBannerUrl(e.target.value)
  }

  const handleSave = () => {
    if (loading) return;
    setLoading(true); 
    let values = {};
    values.AccountName = getAccountName();
    values.AllowGuests = settingInfo.allowGuests;
    values.GuestsCanAddContent = settingInfo.guestsCanAddContent;
    values.TextColor = textcolor;
    values.PrimaryColor = primarycolor;
    values.SecondaryColor = secondarycolor;
    values.BackgroundColor = backgroundcolor;
    values.BannerUrl = bannerurl ? bannerurl : null;
    values.Introduction = introduction ? introduction : null;
    values.LogoUrl = logourl ? logourl : null;
    values.ThemeName = themeID ? themeID : null;
    dispatch(uploadSettings(values, setLoading, true));

    switch (themeID) {
      case 'LightTheme':
        dispatch(Actions.setThemeInfo(lightTheme));
        break;
      case 'DarkTheme':
        dispatch(Actions.setThemeInfo(darkTheme));
        break;
      case 'CustomTheme':
          break;
    }
  }

  const handlePickColor = (c) => {
    switch (pickColorSelector) {
      case 0:
        setPrimaryColor(c.hex);
        break;
      case 1:
        setSecondaryColor(c.hex);
        break;
      case 2:
        setTextColor(c.hex);
        break;
      case 3:
        setBackgroundColor(c.hex);
        break;
    }
    setPickColor(c.hex);
  }

  const handleSelectTheme = () => {
    setThemeExpand(!themeExpand);
  }

  const handleSetLightTheme = () => {    
    setThemeExpand(false);
    setThemeID('LightTheme');
  }

  const handleSetDarkTheme = () => {    
    setThemeExpand(false);
    setThemeID('DarkTheme');
  }

  const handleSetCustomTheme = () => {    
    setThemeExpand(false);
    setThemeID('CustomTheme');
  }

  return (
      <div className={classes.root} style={{height: `${contentHeight}px`, backgroundColor: appTheme.backgroundColor}}>
          <div className={classes.card} style={{backgroundColor: appTheme.backgroundColor}}>
            <div className={classes.item}>
              <h4 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelIntroduction}</h4>
              <ItemTextField
                id="outlined-secondary"
                label={dictionary.formLabelIntroductionPlaceholder}
                variant="outlined"
                value={introduction}
                onChange={handleIntroduction}
                fullWidth
                multiline
                rows={3}
                InputProps={{
                    style: {
                        color: appTheme.textColor
                    }
                }}
              />
            </div>
            <div className={classes.item}>
              <h4 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelLogoUrl}</h4>
              <ItemTextField
                id="outlined-secondary"
                label={dictionary.formLabelLogoUrlPlaceholder}
                variant="outlined"
                value={logourl}
                onChange={handleLogoUrl}
                fullWidth
                InputProps={{
                    style: {
                        color: appTheme.textColor
                    }
                }}
              />
            </div>
            <div className={classes.item}>
              <h4 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelBannerUrl}</h4>
              <ItemTextField
                id="outlined-secondary"
                label={dictionary.formLabelBannerUrlPlaceholder}
                variant="outlined"
                value={bannerurl}
                onChange={handleBannerUrl}
                fullWidth
                InputProps={{
                    style: {
                        color: appTheme.textColor
                    }
                }}
              />
            </div>
            <div className={classes.item}>
              <h4 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelTheme}</h4>
              <div className={classes.itemcolorselector} style={{backgroundColor: currentTheme.BACKCOLOR, color: currentTheme.TEXTCOLOR}} onClick={handleSelectTheme}>
                {currentTheme.TITLE}
              </div>
              <Expand open={themeExpand}>
                <div className={classes.itemcolorselector} style={{backgroundColor: THEMETYPE.LIGHTTHEME.BACKCOLOR, color: THEMETYPE.LIGHTTHEME.TEXTCOLOR}} onClick={handleSetLightTheme}>
                  {THEMETYPE.LIGHTTHEME.TITLE}
                </div>
                <div className={classes.itemcolorselector} style={{backgroundColor: THEMETYPE.DARKTHEME.BACKCOLOR, color: THEMETYPE.DARKTHEME.TEXTCOLOR}} onClick={handleSetDarkTheme}>
                  {THEMETYPE.DARKTHEME.TITLE}
                </div>
              </Expand>
            </div>
            <div className={classes.ctrlitem}>
              <SaveButton onClick={()=>handleSave()}>
                {loading && <CircularProgress
                  className={classes.spinner}
                  size={20}
                />}
                {dictionary.btnLabelSave}
              </SaveButton>
            </div>
          </div>
          <div className={classes.dialog}>
            <Dialog
              open={showColorPicker}
              onClose={()=>setShowColorPicker(false)}
            >
              {/* <ChromePicker
                styles={{width: `${width/2}px`}}
                color={pickColor}
                onChange={handlePickColor}
              /> */}
            </Dialog>
          </div>
      </div>
  )
}

export default SettingAppearance
