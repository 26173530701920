import { makeStyles } from '@material-ui/core/styles';
import { getAccountName } from '../helper/get-account-name';

export const THEMETYPE = {
    LIGHTTHEME: {
        TITLE: "Light Theme",
        TEXTCOLOR: "#000000",
        BACKCOLOR: "#FFFFFF"
    },
    DARKTHEME: {
        TITLE: "Dark Theme",
        TEXTCOLOR: "#FFFFFF",
        BACKCOLOR: "#343434"
    },
    CUSTOMTHEME: {
        TITLE: "Custom Theme",
        TEXTCOLOR: "#FFFFFF",
        BACKCOLOR: "#32385C"
    }
}

export const ROUTE_PARAM_KEY = {
    SIDEBAR: 'side',
    SLIDEBAR: 'slide',
    CONTENT: 'cont',
    ORGANIZE: 'ogan',
    SELECT: 'sel',
    SELCONTENT: 'selcon',
    SELAPPEND: 'selapx',
    SELLEVEL: 'sellvl',
    SELKEY: 'selkey',
    SELNOTIFY: 'selnot',
    SORT: 'sort',
    ACCOUNT: 'acct',
    SEARCH: 'srch'
}

export const ROUTE_PARAM_SIDEBAR = {
    CATEGORY: 'cate',
    FOLDER: 'fol',
    FOLDER_USER: 'folu',
    SETTING: 'set'
}

export const ROUTE_PARAM_SLIDEBAR = {
    CATE_CREATE: 'ca',
    CATE_EDIT: 'ec',
    FOL_CREATE: 'fo',
    FOL_EDIT: 'ef',
    UPLOADER: 'up',
}

export const ROUTE_PARAM_CONTENTS = {
    HOME: 'ho',
    BROWSER: 'br',
    SEARCH: 'sh',
    SETTING_APPEARANCE: 'sa',
    SETTING_CATEGORY: 'sc',
    SETTING_GENERAL: 'sg',
    SETTING_USER: 'su',
    SETTING_VIEW: 'sv',
}

export const ROUTE_PARAM_SELECT = {
    CATEGORY: 'ca',
    FOLDER: 'fo',
}

export const ROUTE_PARAM_ORGANIZE = {
    OPEN: 'true',
    CLOSE: 'false'
}

export const ROUTE_PARAM_SORT = {
    FILEINC: 'files',
    FILEDEC: 'filesdesc',
    DATEINC: 'created',
    DATEDEC: 'createddesc'
}

export const DRAWER_WIDTH = 320;
export const TOOL_FIXED_HEIGHT = 190;
export const EXPEND_TOOL_FIXED_HEIGHT = 200;
export const DEFAULT_FOLDER_PAGE_COUNT = 100;
export const THUMBNAIL_SIZE_LARGE = 180;
export const THUMBNAIL_SIZE_SMALL = 100;

export const APP_VIEW_MODE = {
    CATEGORY: 'cate',
    FOLDER: 'fol',
}

export function firstLetterUpper(theString) {
    if (!theString) return 'Error'
    var newString = theString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
    return newString;
}

/////////////// Route ///////////////
export const ROUTE_HOME = '/';
export const ROUTE_SIGNIN = '/signin';
export const ROUTE_SIGNUP = '/signup';

//////////////////// Backend APIs /////////////////////////
export const API_LOGIN =                    `https://myra-auth.azurewebsites.net/api/Login`;
export const API_GUEST_LOGIN =              `https://myra-auth.azurewebsites.net/api/GuestLogin`;
export const API_GUEST_CREATE =             `https://myra-auth.azurewebsites.net/api/CreateGuest`;
export const API_GET_SETTING_URL =          `https://myra-create-account.azurewebsites.net/api/GetAccountSettings?AccountName=${getAccountName()}`;
export const API_UPLOAD_SETTING_URL =       `https://myra-create-account.azurewebsites.net/api/SaveAccountSettings`;
export const API_GET_CATEGORIES_URL =       `https://myra-save-file-description.azurewebsites.net/api/GetCategories`;
export const API_SAVE_CATEGORIES_URL =      `https://myra-save-file-description.azurewebsites.net/api/SaveCategory`;
export const API_DEL_CATEGORIES_URL =       `https://myra-save-file-description.azurewebsites.net/api/DeleteCategory`;
export const API_GET_PARENT_FOLDER_URL =    `https://myra-get-folders.azurewebsites.net/api/SearchFolders`;
export const API_GET_FOLDER_BY_ID_URL =     `https://myra-get-folders.azurewebsites.net/api/GetFolderById`;
export const API_GET_FILES_BY_FOL_ID_URL =  `https://myra-get-file-references.azurewebsites.net/api/GetFileReferences`;
export const API_DOWNLOAD_FOLDER_URL =      `https://myra-create-folder.azurewebsites.net/api/DownloadFolderAsZip`;
export const API_SAVE_FOLDER_URL =          `https://myra-create-folder.azurewebsites.net/api/SaveFolderDetails`;
export const API_DEL_FOLDER_URL =           `https://myra-delete.azurewebsites.net/api/DeleteFolder`;