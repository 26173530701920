import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Icon, Drawer, TextField, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core/';
import { Form } from 'antd';
import FolderPlusSolid from '../../icons/FolderPlusSolid';
import { Multiselect } from 'multiselect-react-dropdown';
import Select from 'react-select';
import Joi from'@hapi/joi';
import { getAccountName } from '../../helper/get-account-name';
import { DRAWER_WIDTH, ROUTE_PARAM_KEY, ROUTE_PARAM_SLIDEBAR } from '../../utils/const';
import { useSlideBarState, useLocationQuery } from '../../helper/routing-analyser';

let GLOBAL_DRAW_OPEN = false;
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      margin: '0 5px'
    },
    interface: {
      font: `400 12px/24px Muli`,
      width: '100%'
    },
    iconRoot: {   
      height: '100%',
      marginRight: '10px',
      marginTop: '-3px'
    },
    drawerPaper: {
      width: DRAWER_WIDTH
    },
    drawerHeader: {
      height: '64px',
    },
    drawerBody: {
      padding: 20,
      marginTop: '20px',
      fontFamily: 'Muli'
    },
    spinner: {
      color: 'white',
      marginRight: '10px',
      marginTop: '7px'
    },
}));

function CreateFolderButton(props) {
  const { dictionary } = useContext(LanguageContext);
  const slidebar = useSlideBarState();
  const query = useLocationQuery(); 
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const appTheme = useSelector(store => store.setting.theme);
  const categories = props.categories;
  const classes = useStyles();
  const multiselectRef = React.createRef();
  const selectedValues = [];
  
  const AccountName = getAccountName();
  const [form] = Form.useForm();
  const [nameerr, setNameErr] = useState('');
  const [descerr, setDescErr] = useState('');
  const [loading, setLoading] = useState(false);

  const schema = {
    name: Joi.string().required().label('Name'),
    description: Joi.string().label('Description'),  
  };

  const options = [
    { value: '1', label: 'Mix' },
    { value: '2', label: 'Series' }
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
  };

  const FolderButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      transition: 'all 0.2s ease-out',
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);

  const CreateButton = withStyles((theme) => ({
    root: {
      width: '120px',
      height: '30px',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      transition: 'all 0.2s ease-out',
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);

  const usePaper = makeStyles((theme) => ({
    paper: {
      backgroundColor: appTheme.backgroundColorSecondary,
      width: DRAWER_WIDTH,
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      textAlign: 'center'
    },
    text: {
      color: appTheme.textColor
    }
  }))
  const paperclasses = usePaper();

  const ItemTextField = withStyles({
    root: {
      '& label': {
        color: appTheme.textColor,
      },
      '& label.Mui-focused': {
        color: appTheme.textColorThird,
      },
      '& label.Mui-disabled': {
        color: appTheme.textColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: appTheme.textColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: appTheme.textColor,
        },
        '&:hover fieldset': {
          opacity: '0.7',
          borderColor: appTheme.textColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: appTheme.textColorThird,
        },
        '&.Mui-disabled fieldset': {
          borderColor: appTheme.textColor,
        },
      },
    },
  })(TextField);

  function handleCreateFolder () {
    setOpen(true);
    setNameErr('');
    setDescErr('');
    form.setFieldsValue({ 
        Name: '', 
        Description: '',  
        FolderType: options[0],
        Favorite: false
    });

    // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
    // query.append(ROUTE_PARAM_KEY.SLIDEBAR, ROUTE_PARAM_SLIDEBAR.FOL_CREATE);
    // history.push({pathname: '/', search: query.toString()});

    if (props.mobileOpen) {
      props.handleDrawerToggle();
    }
  }

  function handleClose () {
    setOpen(false);     
    GLOBAL_DRAW_OPEN = false;
    // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
    // history.push({pathname: '/', search: query.toString()});
  }

  function onFinish (values) {
    const resultName = Joi.validate({name: values.Name}, {name: schema.name});
    if (resultName.error) {
        setNameErr(resultName.error.details[0].message);
    } else {
        setNameErr('');
    }

    if (resultName.error !== null) {
        return;
    } 
        
    const selectedCategories = [];
    if (multiselectRef.current) {
        multiselectRef.current.getSelectedItems().forEach(c => {
            selectedCategories.push(c.id);
        });
    }
    
    const callback = (folderId) => {
        const FolderId = folderId;
        // dispatch(Actions.saveFolderDetails({ 
        //     AccountName, 
        //     FolderId, 
        //     Description: values.Description, 
        //     IsFavorite: values.Favorite, 
        //     Categories: selectedCategories === [] ? null : selectedCategories, 
        //     ForCategory: false,
        //     FolderType: parseInt(selectedOption.value) }));
        // dispatch(Actions.getSubfolders({ folderId: null, folder_key: 'root', level: navFolderCurLevel }));
        // dispatch(Actions.getSubfoldersCount({ folder_key: 'root', level: navFolderCurLevel }));
        setLoading(false);
        handleClose();
        localStorage.setItem("selectedFolderName", values.Name);
        // if (categoryName) {
        //   history.push(`/categories/${categoryId}/${folderId}`);
        // } else {
        //   history.push(`/categories/all/${folderId}`);
        // }
    };

    setLoading(true);
    // dispatch(Actions.createFolder({ path: "/" + values.Name, callback }));    
  }

  // useEffect(()=>{
  //   if (slidebar === ROUTE_PARAM_SLIDEBAR.FOL_CREATE && GLOBAL_DRAW_OPEN === false) {
  //       setOpen(true);
  //       GLOBAL_DRAW_OPEN = true;
  //   }
  // }, [])

  return (
      <div className={classes.root}>
        <div className={classes.interface}>
            <FolderButton style={{width: '90%'}} onClick={handleCreateFolder}>
                <Icon classes={{root: classes.iconRoot}}>
                    <FolderPlusSolid/>
                </Icon>
                {dictionary.btnLabelCreateFolder}
            </FolderButton>
        </div>
        <Drawer 
          classes={{ paper: paperclasses.paper }} 
          anchor='right' 
          open={open} 
          onClose={() => { handleClose();}} 
          width='400px'>
          <div className={classes.drawerHeader} style={{backgroundColor: appTheme.backgroundColorFourth}}></div>
          <div className={classes.drawerBody}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              name="categoriesForm"
            >
              <Form.Item style={{width: '100%'}} name={dictionary.tblLabelName}>
                <ItemTextField 
                  style={{width: '100%'}} 
                  label={dictionary.tblLabelName} 
                  variant="outlined"                  
                  error={nameerr === '' ? false:true}
                  helperText={nameerr}
                  InputProps={{
                    className: paperclasses.text
                  }}
                />
              </Form.Item>
              <Form.Item style={{width: '100%'}} name={dictionary.tblLabelDescription}>
                <ItemTextField 
                  style={{width: '100%'}} 
                  label={dictionary.tblLabelDescription}
                  multiline rows={3} 
                  variant="outlined"              
                  error={descerr === '' ? false:true}
                  helperText={descerr}
                  InputProps={{
                    className: paperclasses.text
                  }}
                />
              </Form.Item>              
              <Form.Item label={<span style={{color: appTheme.textColor}}>{dictionary.tblLabelFolderType}</span>} style={{width: '100%' }} name={dictionary.tblLabelFolderType}>
                <Select
                    onChange={(o)=>handleChange(o)}
                    options={options}
                />
              </Form.Item>
              <Form.Item label={<span style={{color: appTheme.textColor}}>{dictionary.tblLabelCategories}</span>} name={dictionary.tblLabelCategories}>
                <Multiselect
                    ref={multiselectRef}
                    options={categories}
                    placeholder={dictionary.formLabelCategorySelectPlaceholder}
                    displayValue="key"
                    closeIcon='cancel'
                    selectionLimit='5'
                    selectedValues={selectedValues}
                    style={{ chips: { background: appTheme.buttonColorSecondary, borderRadius: '5px' }, inputField: {width: '100%'}}}
                />
              </Form.Item>
              <Form.Item name={dictionary.formLabelIsFavorite} style={{textAlign: 'left', color: appTheme.textColor}} valuePropName="checked" initialValue={false}>
                <FormControlLabel
                  control={<Checkbox value="introduce" color="default" style={{color: appTheme.textColor}}/>}
                  label={dictionary.formLabelIsFavorite}
                />
              </Form.Item>
              <Form.Item>
                <CreateButton type="primary" htmltype="submit" style={{width: '100%'}}>
                    <div classes={classes.iconLoading}>
                        {loading && <CircularProgress
                            className={classes.spinner}
                            size={20}
                        />}                            
                    </div>
                    {dictionary.btnLabelSave} 
                </CreateButton>
              </Form.Item>
            </Form>
          </div>          
        </Drawer>
      </div>
  )
}

export default CreateFolderButton;
