import { ROUTE_PARAM_CONTENTS, ROUTE_PARAM_KEY, ROUTE_PARAM_SELECT, ROUTE_PARAM_SIDEBAR } from './const';
import { getSubFolderCount } from '../utils/sidebar-controls';
import { toast } from 'react-toastify';

export const handleSort = (history, sortstate, query, props) => {    
    if (props.mobileOpen) {
        props.handleDrawerToggle();
    }

    query.delete(ROUTE_PARAM_KEY.SORT);
    query.append(ROUTE_PARAM_KEY.SORT, sortstate); 
    history.push({pathname: '/', search: query.toString()});
}

export const handleFolderSelect = async (event, history, query, dictionary, title, fid, fkey, flevel, loading) => {
    const isNavigateNewTab = event.ctrlKey || event.metaKey;

    loading(true);
    const reponse = await getSubFolderCount(fkey, flevel);
    const sfcount = JSON.parse(reponse.responseText).result;
    loading(false);

    query.delete(ROUTE_PARAM_KEY.SEARCH);
    query.delete(ROUTE_PARAM_KEY.CONTENT);
    query.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.BROWSER);    
    query.delete(ROUTE_PARAM_KEY.SELECT);
    query.append(ROUTE_PARAM_KEY.SELECT, ROUTE_PARAM_SELECT.FOLDER);    
    query.delete(ROUTE_PARAM_KEY.SELCONTENT);
    query.append(ROUTE_PARAM_KEY.SELCONTENT, fid);       
    query.delete(ROUTE_PARAM_KEY.SELKEY);
    query.append(ROUTE_PARAM_KEY.SELKEY, fkey);    
    query.delete(ROUTE_PARAM_KEY.SELLEVEL);
    query.append(ROUTE_PARAM_KEY.SELLEVEL, flevel);    
    query.delete(ROUTE_PARAM_KEY.SELAPPEND);
    query.append(ROUTE_PARAM_KEY.SELAPPEND, sfcount); 
    // if (isNavigateNewTab) {
    //     window.open({pathname: '/', search: query.toString()}.toString(), "_blank");
    // } else {
        history.push({pathname: '/', search: query.toString()});
    // }
}

export const handleUploaderContents = (history, account, props) => {
    const params = new URLSearchParams();
    params.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.FOLDER_USER);
    params.append(ROUTE_PARAM_KEY.ACCOUNT, account);
    history.push({pathname: '/', search: params.toString()});
  }