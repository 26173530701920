import React, { useContext } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icon } from '@material-ui/core/';
import { useSelector } from 'react-redux';
import ArchiveIcon from '@material-ui/icons/Archive';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAccountName } from '../../helper/get-account-name';
import Tooltip from 'react-tooltip-lite';
import { API_DOWNLOAD_FOLDER_URL } from '../../utils/const';

function DownloadButton(props) {
  const { dictionary } = useContext(LanguageContext);
  const appTheme = useSelector(store => store.setting.theme);
  const themeName = useSelector((store) => store.setting.themeName); 
  const token = useSelector(store => store.login.token);  
  const selectedFolder = props.folder; 
  const useStyles = makeStyles(() => ({
      root: {
        display: 'flex',
        margin: '0 5px'
      },
      iconRoot: {   
        height: '100%',
        marginRight: '10px'
      },
      iconDwnRoot: {   
        height: '100%'
      },
      custombutton: {
        width: '40px',
        height: '30px',
        minWidth: '40px',
        color: appTheme.textColorSecondary,
        backgroundColor: appTheme.buttonColorThird,
        transition: 'all 0.2s ease-out',
        '&:hover': {
          color: appTheme.textColorFourth,
          backgroundColor: appTheme.textColorSecondary,
        }
      },
  })); 
  const classes = useStyles();

  const handleDownload = () => {
    const url = API_DOWNLOAD_FOLDER_URL + `?Token=${token}&AccountName=${getAccountName()}&FolderId=${selectedFolder.id}`;
    window.open(url, "_blank");
  }

  return (
      <div className={classes.root}>
        <div className={classes.interface}>
          <Tooltip content={ dictionary.navTooltipDownload } direction="bottom" className={themeName === 'LightTheme' ? 'target-white' : 'target-black'}>
            <Button className={classes.custombutton} onClick={()=>handleDownload()}>
              <Icon classes={{root: classes.iconDwnRoot}}>
                <ArchiveIcon/>
              </Icon>
            </Button>
          </Tooltip>
        </div>
      </div>
  )
}

export default DownloadButton;
