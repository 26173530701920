import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect } from 'react-redux';
import Expand from 'react-expand-animated';
import { Button } from '@material-ui/core/';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { ROUTE_PARAM_KEY, ROUTE_PARAM_SIDEBAR, ROUTE_PARAM_CONTENTS, ROUTE_PARAM_ORGANIZE } from '../../utils/const';
import { useLocationQuery, useOrganizeState } from '../../helper/routing-analyser';
import { useHistory } from "react-router-dom";
import { handleCategorySelect } from '../../utils/sidebar-controls';
import { getAccountName } from '../../helper/get-account-name';
import PointLoading from '../point-loading';
import { Actions } from '../../store/admin-reducer';

function CategoryExpand(props) {  
  const appTheme = useSelector(store => store.setting.theme);
  const useStyles = makeStyles((theme) => ({    
    categorycard: {
      minWidth: '280px',
      width: '100%',
      borderTop: `1px dotted ${appTheme.buttonColorSecondary}`,
      borderBottom: `1px dotted ${appTheme.buttonColorSecondary}`,
      marginTop: '20px',
      marginBottom: '20px',
      boxSizing: 'border-box',
      padding: '10px 20px', 
    },
    organizecard: {
      minWidth: '280px',
      width: '100%',
      marginBottom: '20px',
      display: 'block',
      flexDirection: 'column',
      background: appTheme.backgroundColorThird,
      padding: '20px',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      boxSizing: 'border-box',        
      borderRadius: '10px'
    },
    organizewrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '12px',
      boxSizing: 'border-box'
    },
    organizeitem: {
      display: 'flex',
      width: 'fit-content',
      backgroundColor: appTheme.buttonColorSecondary,
      color: theme.palette.getContrastText(appTheme.buttonColorSecondary),
      padding: '5px 10px',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColorSecondary,
      }
    },
    organizenoitem: {
      display: 'flex',
      width: 'fit-content',
      backgroundColor: appTheme.buttonColorThird,
      color: theme.palette.getContrastText(appTheme.buttonColorThird),
      padding: '5px 10px',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {          
        opacity: '0.7',
        backgroundColor: appTheme.buttonColorThird,
      }
    },
  }));

  const history = useHistory(); 
  const query = useLocationQuery();
  const organizeExpand = useOrganizeState();
  const categories = props.categories;
  const folder = useSelector(store => store.folder.selectedFolder);
  const [foldercategory, setFolderCategory] = useState(folder ? folder.categories : []);
  const [loading, setLoading] = useState(false);
  const { dictionary } = useContext(LanguageContext);
  const AccountName = getAccountName();
  const dispatch = useDispatch();
  const classes = useStyles();
 
  const CustomButton = withStyles((theme) => ({
    root: {
      width: '40px',
      height: '30px',
      minWidth: '40px',
      color: appTheme.textColorSecondary,
      backgroundColor: appTheme.buttonColorThird,
      '&:hover': {
        color: appTheme.textColorFourth,
        backgroundColor: appTheme.textColorSecondary,
      }
    }
  }))(Button);

  const handleShortcutSettingsCategory = () => {
    if (props.mobileOpen) {
      props.handleDrawerToggle();
    }
    const params = new URLSearchParams();
    params.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.SETTING);
    params.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.SETTING_CATEGORY);
    history.push({pathname: '/', search: params.toString()});
  };

  const handleOrganizeSelect = (event, category) => {
    if (loading) return;
    let c = foldercategory;      
    const already_exist = c.filter(i => category.id === i);
    const unique = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
    const clipboard = [...c, category.id].filter(i => !already_exist.includes(i));
    setFolderCategory(unique(clipboard));
    const callback = () => {
      setLoading(false);
    }
    setLoading(true);
    dispatch(Actions.saveFolderDetails({ 
      AccountName, 
      FolderId: folder.id, 
      Description: folder.description, 
      IsFavorite: folder.isFavorite, 
      Categories: unique(clipboard), 
      ForCategory: true,
      FolderType: folder.folderType, callback }));
  }
  
  return (
      <div>
         <div className={classes.categorycard}>  
            <div className={classes.organizewrapper}>
              {foldercategory.map((category, index) => {
                const chk = categories && categories.filter(c => c.id === category);                
                if (chk.length === 0) return;
                return (
                  <div key={index} className={classes.organizeitem} onClick={ e => handleCategorySelect(e, history, query, dictionary, chk[0].id, chk[0].name, props)}>
                    <div>{chk[0].name}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <Expand open={organizeExpand === ROUTE_PARAM_ORGANIZE.OPEN}>
            <div className={classes.organizecard}>              
                <div style={{gridColumnStart: '1', gridRowStart: '1', width: '100%'}}>
                  {loading && <PointLoading/>}
                </div>
                <div style={{gridColumnStart: '1', gridRowStart: '1', width: '100%', textAlign: 'right'}}>
                  <CustomButton onClick={handleShortcutSettingsCategory}><BorderColorIcon /></CustomButton>
                </div>
                <div style={{gridColumnStart: '1', gridRowStart: '1'}}>
                  <div className={classes.organizewrapper}>
                    {categories.map((category, index) => {
                      const chk = foldercategory && foldercategory.filter(c => c === category.id);
                      return (
                        <div key={index} className={chk && chk.length ? classes.organizeitem : classes.organizenoitem} onClick={ e => handleOrganizeSelect(e, category)} disabled={loading}>
                          <div>{category.name}</div>                        
                        </div>
                      )
                    })}
                  </div>
                </div>
            </div>
          </Expand>
      </div>
  )
}

export default connect(s => ({}))(CategoryExpand);
