import { ROUTE_PARAM_CONTENTS, ROUTE_PARAM_KEY, ROUTE_PARAM_SIDEBAR, ROUTE_PARAM_ORGANIZE } from '../utils/const';
import { Actions } from '../store/login-reducer';
import { toast } from 'react-toastify';

export const handleSetting = (history, query, dictionary, props) => {
    const guestname = localStorage.getItem('guestname');
    const guesttoken = localStorage.getItem('guesttoken');   
    
    if (props.mobileOpen) {
        props.handleDrawerToggle();
    }
    
    if (query.toString().includes(ROUTE_PARAM_SIDEBAR.SETTING)) {
        return;   
    }

    if (String(guestname) === 'null' && String(guesttoken) === 'null') {
      query.delete(ROUTE_PARAM_KEY.SEARCH);
      query.delete(ROUTE_PARAM_KEY.SIDEBAR);
      query.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.SETTING);
      query.delete(ROUTE_PARAM_KEY.CONTENT);
      query.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.SETTING_GENERAL);
      history.push({pathname: '/', search: query.toString()});
    } else {
      toast.warning(dictionary.appToastNoSettingWithGuest);
    }
}

export const handleOrganizeMode = (history, query, dictionary, props) => {
  if (props.mobileOpen) {
    props.handleDrawerToggle();
  }

  query.delete(ROUTE_PARAM_KEY.SEARCH);
  const ogan = query.get(ROUTE_PARAM_KEY.ORGANIZE);
  if (ogan) {
    query.delete(ROUTE_PARAM_KEY.ORGANIZE);
  } else {
    query.delete(ROUTE_PARAM_KEY.ORGANIZE);
    query.append(ROUTE_PARAM_KEY.ORGANIZE, ROUTE_PARAM_ORGANIZE.OPEN);
  }
  history.push({pathname: '/', search: query.toString()});
}

export const handleMyContents = (history, query, dictionary, account, props) => {
  if (props.mobileOpen) {
    props.handleDrawerToggle();
  }
  
  const params = new URLSearchParams();
  params.append(ROUTE_PARAM_KEY.SIDEBAR, ROUTE_PARAM_SIDEBAR.FOLDER_USER);
  params.append(ROUTE_PARAM_KEY.ACCOUNT, account);
  history.push({pathname: '/', search: params.toString()});
}

export const handleSignout = (props) => {
  if (props.mobileOpen) {
    props.handleDrawerToggle();
  }
  
  document.querySelector('#useravataritem').style.display = 'none';
  props.dispatch(Actions.signOut());
}

export const handleAppSearch = (history, query, dictionary, search) => {  
  query.delete(ROUTE_PARAM_KEY.SEARCH);
  query.append(ROUTE_PARAM_KEY.SEARCH, search);
  query.delete(ROUTE_PARAM_KEY.CONTENT);
  query.append(ROUTE_PARAM_KEY.CONTENT, ROUTE_PARAM_CONTENTS.SEARCH);  
  history.push({pathname: '/', search: query.toString()});
}