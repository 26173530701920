import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles,/*  useTheme*/ } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect } from 'react-redux';
import { useLocationQuery } from '../../helper/routing-analyser';
import { useWindowSize } from '../../helper/window-size';
import { handleFolderSelect } from '../../utils/folder-controls';
import { useHistory } from 'react-router-dom';

function BrowserSubfolders(props) {  
  const appTheme = useSelector(store => store.setting.theme);
  const useStyles = makeStyles((theme) => ({    
    subfolders: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    subfolderitem: {
      display: 'grid',
      textAlign: 'center',
      padding: '10px',
      color: `${appTheme.buttonColor}`,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.7'
      }
    },
  }));

  const query = useLocationQuery(); 
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory(); 
  const [width, height] = useWindowSize();
  const getPath = (k) => k.split("/").filter(p => p);
  const classes = useStyles();
  const { subfolders, setSelectLoading } = props;
  const subcount = subfolders?subfolders.length:0;
  const thumbnailSize = useSelector(store => store.setting.thumbnailSize);
   
  return (
      <div>
         <div className={classes.subfolders}>
            {subcount > 0 && subfolders.map((dataItem, index) => {
              const [folder_name] = getPath(dataItem.key).slice(-1);
              const folderImageUrl = dataItem['folderImageUrl'];
              const size = width > 600 ? thumbnailSize : thumbnailSize / 2;
              return (
                <div className={classes.subfolderitem} key={index} onClick={(event) => handleFolderSelect(event, history, query, dictionary, folder_name, dataItem.id, dataItem.key, dataItem.level, setSelectLoading)}>                  
                  <img src={folderImageUrl} width={size * 2}/>
                  <span>{folder_name}</span>
                </div>
              )
            })}
          </div>
      </div>
  )
}

export default connect(s => ({}))(BrowserSubfolders);
