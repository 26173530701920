import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Button, Icon, Dialog, DialogContent, Badge } from '@material-ui/core/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloudUploadAltSolid from '../../icons/CloudUploadAltSolid';
import FolderSolid from '../../icons/FolderSolid';
import ImageSolid from '../../icons/ImageSolid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ROUTE_PARAM_KEY, ROUTE_PARAM_SLIDEBAR } from '../../utils/const';
import { useSlideBarState, useLocationQuery } from '../../helper/routing-analyser';
import { getTotalUploadSize } from '../../helper/get-upload-info';
import {
  getUploadLink, 
  processImage, 
  setCurrentUpload, 
  updateFilesList, 
  uploadBlob,
  setUploadingProcess,
  setFilesSize
} from '../../store/upload-reducer';
import { toast } from 'react-toastify';

export const MAX_UPLOAD_LIMIT = 5;
let GLOBAL_MODAL_OPEN = false;
const getContentsSize = (isfull) => {
  if (isfull) {
    return {width: window.screen.width - 80, height: (window.screen.width) * 189 / 500}
  } else {
    return {width: 500, height: 210}
  }
}

function UploadButton(props) {
  const { dictionary } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const appTheme = useSelector(store => store.setting.theme);
  const slidebar = useSlideBarState();
  const query = useLocationQuery(); 
  const history = useHistory();
  const {width, height} = getContentsSize(fullScreen)
  const [fileList, setFileList] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const current_uploads = useSelector((store) => store.upload.current_uploads);
  const uploaded_files = useSelector((store) => store.upload.uploaded_files);
  const uploaded_count = useSelector((store) => store.upload.uploaded_count);
  const dispatch = useDispatch();
  const selectedFolder = useSelector(store => store.folder.selectedFolder);
  const guestname = localStorage.getItem("guestname");

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      margin: '0 5px'
    },
    interface: {
      font: `400 12px/24px Muli`,
    },
    iconRoot: {   
      height: '100%',
      marginRight: '10px',
      marginTop: '-6px'
    },
    contents: {
      padding: '20px',
      textAlign: 'center',
      display: 'grid',
      height: '100%',
      verticalAlign: 'middle',
      alignContent: 'center'
    },
    dragpane: {
      fontFamily: 'Muli',
      height: '100%',
      verticalAlign: 'middle',
      alignContent: 'center',
      transition: 'all 0.2s ease-out',
      '&:hover': {
        backgroundColor: '#F8F8F820',
        border: '2px dashed #9AA3D0',
      }
    },
    dragicongroup: {
      display: 'inline-flex'
    },
    dragicon: {
      color: '#EAF4F5',
      margin: '0 10px'
    },
    controls: {
      marginTop: '20px'
    },
    custombutton: {
      width: '120px',
      height: '30px',
      color: theme.palette.getContrastText(appTheme.buttonColorSecondary),
      backgroundColor: appTheme.buttonColorSecondary,
      transition: 'all 0.2s ease-out',
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColorSecondary,
      }
    },
    cancelbutton: {
      width: '120px',
      height: '30px',
      color: appTheme.textColorSecondary,
      backgroundColor: appTheme.buttonColorThird,
      transition: 'all 0.2s ease-out',
      '&:hover': {
        backgroundColor: '#FC96B9',
      }
    }
  }));
  const classes = useStyles();

  const handleUploaderOpen = () => {
    setOpen(true);
    // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
    // query.append(ROUTE_PARAM_KEY.SLIDEBAR, ROUTE_PARAM_SLIDEBAR.UPLOADER);
    // history.push({pathname: '/', search: query.toString()});
  }

  const handleUploaderClose = () => {
    setOpen(false);
    GLOBAL_MODAL_OPEN = false;
    // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
    // history.push({pathname: '/', search: query.toString()});
  }

  const handleUpload = () => {
    if (fileCount === 0) {
      toast.warning(dictionary.appToastNoUploadFiles);
      return;
    } 
    
    handleUploaderClose();
    let size = getTotalUploadSize(fileList)
    dispatch(setFilesSize({size}));
    dispatch(setUploadingProcess());
    dispatch(updateFilesList({
      files: fileList.reduce((map, file) => { map[file.path] = file; return map }, {}),
      files_count: fileList.length,
      fast_upload_option: true
    }))
  }

  const resetFileList = () => {
    setFileList([]);
  }

  const onDropAccepted = useCallback((acceptedFiles, event) => {    
    fileList.push.apply(fileList, acceptedFiles);
    setFileList(fileList);
    setFileCount(fileList.length);
  });

  const { getRootProps, getInputProps } = useDropzone({ onDropAccepted, accept: 'image/jpeg, image/png' });

  useEffect(() => {
    if (fileCount === 0) return;

    // keep current_uploads queue full
    const current_uploads_count = Object.keys(current_uploads).length
    if (current_uploads_count < MAX_UPLOAD_LIMIT) {
      dispatch(setCurrentUpload())
    }

    //Process current_uploads queue
    Object.keys(current_uploads).forEach(path => {
      const current_upload = current_uploads[path]
      const file_is_not_uploaded_already = !uploaded_files[path]
      if (file_is_not_uploaded_already) {
        switch (current_upload.status) {
          case "get_link":
            const FileName = `${selectedFolder.key}/${path}`
            dispatch(getUploadLink({ FileName, path }))
            break;
          case "upload":
            dispatch(uploadBlob({ path }))
            break;
          case "process":
            dispatch(processImage({ path, total: fileCount }))
            break;
        }
      }
    })
  })

  // useEffect(()=>{
  //   if (slidebar === ROUTE_PARAM_SLIDEBAR.UPLOADER && GLOBAL_MODAL_OPEN === false) {
  //       setOpen(true);
  //       GLOBAL_MODAL_OPEN = true;
  //   }
  // }, [])

  return (
      <div className={classes.root}>
        <div className={classes.interface}>
          <Button className={classes.custombutton} onClick={()=>{             
            if (String(guestname) !== 'null' && guestname !== selectedFolder.guestName) {
              toast.error(dictionary.appToastGuestPermissionError);
              return;
            }
            resetFileList(); 
            setFileCount(0); 
            handleUploaderOpen(); 
          }}>
            <Icon classes={{root: classes.iconRoot}}>
              <CloudUploadAltSolid/>
            </Icon>
            {dictionary.btnLabelUpload}
          </Button>
        </div>
        <div className={classes.dialog}>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={()=>handleUploaderClose()}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent style={{backgroundColor: appTheme.backgroundColor}}>
              <div className={classes.contents}>
                <div className={classes.dragpane} style={{width: width, height: height, border: `2px dashed ${appTheme.textColorSecondary}`,}} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={classes.dragicongroup} style={{marginTop: `${height/5}px`}}>
                    <div className={classes.dragicon}>
                      <ImageSolid size={fullScreen ? height / 3 : 80}/>
                    </div>
                    <div className={classes.dragicon}>
                      <FolderSolid size={fullScreen ? height / 3 : 80}/>
                    </div>
                    <Badge badgeContent={fileCount} max={99999} color='error'/>
                  </div>
                  <h3 style={{color: appTheme.textColorThird}}>{dictionary.appLabelUploadDesc}</h3>
                </div>
                <div className={classes.controls}>
                  <Button style={{marginRight: '5px'}} className={classes.custombutton} onClick={()=>{handleUpload();}}>
                    <Icon classes={{root: classes.iconRoot}}>
                      <CloudUploadAltSolid/>
                    </Icon>
                    {dictionary.btnLabelUpload} 
                  </Button>
                  <Button style={{marginLeft: '5px'}} className={classes.cancelbutton} onClick={()=>handleUploaderClose()}>
                    {dictionary.btnLabelCancel}
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
  )
}

export default UploadButton;
