import update from 'immutability-helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_GET_SETTING_URL, API_UPLOAD_SETTING_URL, APP_VIEW_MODE, THUMBNAIL_SIZE_LARGE, THUMBNAIL_SIZE_SMALL } from '../utils/const';
import { lightTheme, darkTheme } from '../utils/theme';
import apicall from '../utils/api-call';

toast.configure();
export const ActionsTypes = {
    SAVE_SETTING_INFO: "SAVE_SETTING_INFO",
    UPLOAD_SETTING_INFO: "UPLOAD_SETTING_INFO",
    SET_THEME_INFO: "SET_THEME_INFO",
    SET_VIEWMODE_INFO: "SET_VIEWMODE_INFO",
    SET_THUMBNAIL_SIZE_TO_LARGE: "SET_THUMBNAIL_SIZE_TO_LARGE",
    SET_THUMBNAIL_SIZE_TO_SMALL: "SET_THUMBNAIL_SIZE_TO_SMALL",
}

export const getSettings = () => {
    return (dispatch) => {
        return apicall({
            url: API_GET_SETTING_URL,
            method: "GET",
            type: "json",
            params: {}
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                return dispatch(Actions.saveSettings(res.result));
            }
            else if (res && res.Message) {
                toast.warn(res.Message)
            } else if (res && res.Error) {
                toast.error(res.Error)
            } else {
                toast.error("Error: Not able to fetch account settings.")
            }
        })
    }
};

export const uploadSettings = (info, setLoading, flag) => {
    return (dispatch) => {
        return apicall({
            url: API_UPLOAD_SETTING_URL,
            method: "POST",
            type: "json",
            params: info,
        }).then(response => {
            let res = null
            if ([200, 403, 500].includes(response.status)) {
                res = JSON.parse(response.responseText)
            }
            if ([200].includes(response.status)) {
                setLoading(false);
                if (flag) toast.success("Appearance Settings has been updated");
                else toast.success("General Settings has been updated");
                return dispatch(Actions.saveSettings(res.result));
            } else if (res && res.Message) {
                setLoading(false);
                toast.warn(res.Message)
            } else if (res && res.Error) {
                setLoading(false);
                toast.error(res.Error)
            } else {
                setLoading(false);
                toast.error("Error: Not able to update account settings.")
            }
        })
    }
};

export const Actions = {
    getSettings,
    uploadSettings,
    saveSettings: (payload) => ({ type: ActionsTypes.SAVE_SETTING_INFO, payload: payload }),
    setThemeInfo: (payload) => ({ type: ActionsTypes.SET_THEME_INFO, payload: payload }),
    setViewModeInfo: (payload) => ({ type: ActionsTypes.SET_VIEWMODE_INFO, payload: payload }),
    setThumbnailSizeToLarge: () => ({ type: ActionsTypes.SET_THUMBNAIL_SIZE_TO_LARGE }),
    setThumbnailSizeToSmall: () => ({ type: ActionsTypes.SET_THUMBNAIL_SIZE_TO_SMALL }),
}

let initial = {
    allowGuests: true,
    backgroundColor: "#FFFFFF",
    bannerUrl: null,
    guestsCanAddContent: false,
    introduction: "<h1>Welcome</h1>",
    logoUrl: null,
    themeName: 'LightTheme',
    primaryColor: "#d4d4aa",
    secondaryColor: "#ebf5df",
    textColor: "#000000",
    theme: lightTheme,
    viewmode: APP_VIEW_MODE.CATEGORY,
    thumbnailSize: THUMBNAIL_SIZE_LARGE,
}

export default (state, action) => {
  state = state == null ? initial : state
  switch (action.type) {
    case ActionsTypes.SAVE_SETTING_INFO: {
        return {...state, ...action.payload};
    }
    case ActionsTypes.SET_THEME_INFO: {
        return update(state, {
            theme: { $set: action.payload },
        })
    }
    case ActionsTypes.SET_VIEWMODE_INFO: {
        return update(state, {
            viewmode: { $set: action.payload },
        })
    }
    case ActionsTypes.SET_THUMBNAIL_SIZE_TO_LARGE: {
        return update(state, {
            thumbnailSize: { $set: THUMBNAIL_SIZE_LARGE },
        })
    }
    case ActionsTypes.SET_THUMBNAIL_SIZE_TO_SMALL: {
        return update(state, {
            thumbnailSize: { $set: THUMBNAIL_SIZE_SMALL },
        })
    }
    default:
        return state;
  }
}

