import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Icon, Drawer, TextField, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core/';
import {Form} from 'antd';
import EditIcon from '@material-ui/icons/Edit';
import {Multiselect} from 'multiselect-react-dropdown';
import Select from 'react-select';
import Joi from'@hapi/joi';
import { getAccountName } from '../../helper/get-account-name';
import { DRAWER_WIDTH, ROUTE_PARAM_KEY, ROUTE_PARAM_SLIDEBAR } from '../../utils/const';
import { useLocationQuery, useSlideBarState } from '../../helper/routing-analyser';
import { getFolderInfo } from '../../helper/get-folder';
import { toast } from 'react-toastify';
import { Actions } from '../../store/admin-reducer';

let GLOBAL_DRAW_OPEN = false;
function EditFolderButton(props) {
  const { dictionary } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useLocationQuery(); 
  const appTheme = useSelector(store => store.setting.theme);
  const { categories, selectFolder } = props;
  const sortFolderVariable = useSelector((state) => state.sortFolderVariable);
  const filelist = useSelector((state) => state.folder.dataFileList);
  const multiselectRef = React.createRef();
  const selectedValues = [];
  getSelectedCategories();
  const useStyles = makeStyles((theme) => ({
      root: {
        display: 'flex',
        margin: '0 5px'
      },
      interface: {
        font: `400 12px/24px Muli`,
        width: '100%'
      },
      iconRoot: {   
        height: '100%',
        marginTop: '-3px'
      },
      drawerPaper: {
        width: DRAWER_WIDTH
      },
      drawerHeader: {
        height: '64px',
      },
      drawerBody: {
        padding: 20,
        marginTop: '20px',
        fontFamily: 'Muli'
      },
      spinner: {
        color: 'white',
        marginRight: '10px',
        marginTop: '7px'
      },
      folderbutton: {
        width: '40px',
        height: '30px',
        minWidth: '40px',
        color: appTheme.textColorSecondary,
        backgroundColor: appTheme.buttonColorThird,
        '&:hover': {
          color: appTheme.textColorFourth,
          backgroundColor: appTheme.textColorSecondary,
        }
      },
      savebutton: {
        width: '40px',
        height: '30px',
        color: theme.palette.getContrastText(appTheme.buttonColor),
        backgroundColor: appTheme.buttonColor,
        '&:hover': {
          opacity: '0.7',
          backgroundColor: appTheme.buttonColor,
        }
      },
      deletebutton: {
        width: '40px',
        height: '30px',
        color: theme.palette.getContrastText('#FF4D4F'),
        backgroundColor: '#FF4D4F',
        '&:hover': {
          opacity: '0.7',
          backgroundColor: '#FF4D4F',
        }
      }
  }));
  const classes = useStyles();
  
  const AccountName = getAccountName();
  const [form] = Form.useForm();
  const [nameerr, setNameErr] = useState('');
  const [descerr, setDescErr] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);

  const guestname = localStorage.getItem("guestname");

  const schema = {
    name: Joi.string().required().label('Name'),
    description: Joi.string().label('Description'),  
  };

  const options = [
    { value: '1', label: 'Mix' },
    { value: '2', label: 'Series' }
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
  };

  const usePaper = makeStyles((theme) => ({
    paper: {
      backgroundColor: appTheme.backgroundColorSecondary,
      width: DRAWER_WIDTH,
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      textAlign: 'center'
    },
    text: {
      color: appTheme.textColor
    }
  }))
  const paperclasses = usePaper();

  const ItemTextField = withStyles({
    root: {
      '& label': {
        color: appTheme.textColor,
      },
      '& label.Mui-focused': {
        color: appTheme.textColorThird,
      },
      '& label.Mui-disabled': {
        color: appTheme.textColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: appTheme.textColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: appTheme.textColor,
        },
        '&:hover fieldset': {
          opacity: '0.7',
          borderColor: appTheme.textColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: appTheme.textColorThird,
        },
        '&.Mui-disabled fieldset': {
          borderColor: appTheme.textColor,
        },
      },
    },
  })(TextField);

  function getSelectedCategories () {
    if (!selectFolder || !selectFolder.categories) return;

    selectFolder.categories.forEach(c => {
        let category = categories.filter(cat => cat.id === c);
        selectedValues.push(category[0]);
    })
  }  

  function handleEditFolder () {
    if (String(guestname) !== 'null' && guestname !== selectFolder.guestName) {
      toast.error(dictionary.appToastGuestPermissionError);
      return;
    }
    setOpen(true);    
    setNameErr('');
    setDescErr('');
    const { title } = getFolderInfo(selectFolder); 
    form.setFieldsValue({ 
        Name: selectFolder ? title : '', 
        Description: selectFolder ? selectFolder.description : '',  
        FolderType: selectFolder ? options[selectFolder.folderType - 1] : options[0],
        Favorite: selectFolder ? selectFolder.isFavorite : false
    });
    
    // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
    // query.append(ROUTE_PARAM_KEY.SLIDEBAR, ROUTE_PARAM_SLIDEBAR.FOL_EDIT);
    // history.push({pathname: '/', search: query.toString()});

    if (props.mobileOpen) {
      props.handleSidebarToggle();
    }
  }

  function onFinish (values) {
    const resultName = Joi.validate({name: values.Name}, {name: schema.name});
    if (resultName.error) {
        setNameErr(resultName.error.details[0].message);
    } else {
        setNameErr('');
    }
    if (resultName.error !== null) {
        return;
    } 
        
    const selectedCategories = [];
    if (multiselectRef.current) {
        multiselectRef.current.getSelectedItems().forEach(c => {
            selectedCategories.push(c.id);
        });
    }

    const callback = () => {
        setLoading(false);
        setOpen(false); 
    }
    const { title } = getFolderInfo(selectFolder); 

    // if (selectFolder) {
    //     setLoading(true);
    //     if (values.Name !== title) {
    //       localStorage.setItem("selectedFolderName", values.Name);
    //       dispatch(Actions.renameFolder({folderId : selectFolder.id, new_name : values.Name, callback : null}));
    //     } 
    //     dispatch(Actions.saveFolderDetails({ 
    //       AccountName, 
    //       FolderId: selectFolder.id, 
    //       Description: values.Description, 
    //       IsFavorite: values.Favorite, 
    //       Categories: selectedCategories === [] ? null : selectedCategories, 
    //       ForCategory: false,
    //       FolderType: parseInt(selectedOption.value), callback, message: true }));  
    // }
  }

  function handleDelete() {
    if (filelist && filelist.length > 0) {
      toast.error(dictionary.appToastFilesDeleteBeforeFolderDelete);
      setOpen(false);
      return;
    }
    setLoadingDelete(true);
    dispatch(Actions.deleteFolder({
      folderId: selectFolder.id,
      sortFolderVariable: sortFolderVariable,
      callback: () => {
        setLoadingDelete(false);
        setOpen(false); 
        history.goBack();
      }
    }))
  }

  const handleEditFolderClose = () => {    
    setOpen(false);     
    GLOBAL_DRAW_OPEN = false;
    // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
    // history.push({pathname: '/', search: query.toString()});
  }

  // useEffect(()=>{
  //   if (slidebar === ROUTE_PARAM_SLIDEBAR.FOL_EDIT && GLOBAL_DRAW_OPEN === false) {
  //     setOpen(true);
  //     GLOBAL_DRAW_OPEN = true;
  //   }
  // }, []);

  return (
      <div className={classes.root}>
        <div className={classes.interface}>
            <Button className={classes.folderbutton} style={{width: '90%'}} onClick={handleEditFolder}>
                <Icon classes={{root: classes.iconRoot}}>
                    <EditIcon/>
                </Icon>
            </Button>
        </div>
        <Drawer 
          classes={{ paper: paperclasses.paper }} 
          anchor='right' 
          open={open} 
          onClose={() => { handleEditFolderClose(); }} 
          width='400px'>
          <div className={classes.drawerHeader} style={{backgroundColor: appTheme.backgroundColorFourth}}></div>
          <div className={classes.drawerBody}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              name="categoriesForm"
            >
              <Form.Item style={{width: '100%'}} name={dictionary.tblLabelName}>
                <ItemTextField 
                  style={{width: '100%'}} 
                  label={dictionary.tblLabelName} 
                  variant="outlined"                  
                  error={nameerr === '' ? false:true}
                  helperText={nameerr}
                  InputProps={{
                    style: {
                        color: appTheme.textColor
                    }
                  }}
                />
              </Form.Item>
              <Form.Item style={{width: '100%'}} name={dictionary.tblLabelDescription}>
                <ItemTextField 
                  style={{width: '100%'}} 
                  label={dictionary.tblLabelDescription}
                  multiline rows={3} 
                  variant="outlined"              
                  error={descerr === '' ? false:true}
                  helperText={descerr}
                  InputProps={{
                    className: paperclasses.text
                  }}
                />
              </Form.Item>              
              <Form.Item label={<span style={{color: appTheme.textColor}}>{dictionary.tblLabelFolderType}</span>} style={{width: '100%' }} name={dictionary.tblLabelFolderType}>
                <Select
                    onChange={(o)=>handleChange(o)}
                    options={options}
                />
              </Form.Item>
              <Form.Item label={<span style={{color: appTheme.textColor}}>{dictionary.tblLabelCategories}</span>} name={dictionary.tblLabelCategories}>
                {categories.length > 0 && <Multiselect
                    ref={multiselectRef}
                    options={categories}
                    placeholder={dictionary.formLabelCategorySelectPlaceholder}
                    displayValue="key"
                    closeIcon='cancel'
                    selectionLimit='5'
                    selectedValues={selectedValues}
                    style={{ chips: { background: appTheme.buttonColorSecondary, borderRadius: '5px' }, inputField: {width: '100%'}}}
                />}
              </Form.Item>
              <Form.Item name={dictionary.formLabelIsFavorite} style={{textAlign: 'left', color: appTheme.textColor}} valuePropName="checked" initialValue={false}>
                <FormControlLabel
                  control={<Checkbox value="introduce" color="default" style={{color: appTheme.textColor}}/>}
                  label={dictionary.formLabelIsFavorite}
                />
              </Form.Item>
              <Form.Item>
                <Button className={classes.savebutton} type="primary" htmltype="submit" style={{width: '100%'}}>
                    <div classes={classes.iconLoading}>
                        {loading && <CircularProgress
                            className={classes.spinner}
                            size={20}
                        />}                            
                    </div>
                    {dictionary.btnLabelSave} 
                </Button>
              </Form.Item>
            </Form>
            <Button className={classes.deletebutton} type="primary" htmltype="submit" style={{width: '100%'}} onClick={()=>handleDelete()}>
                <div classes={classes.iconLoading}>
                    {loadingdelete && <CircularProgress
                        className={classes.spinner}
                        size={20}
                    />}                            
                </div>
                {dictionary.btnLabelDelete} 
            </Button>   
          </div>       
        </Drawer>
      </div>
  )
}

export default EditFolderButton;
