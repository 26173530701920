import React, { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import {Button, CircularProgress, Link} from '@material-ui/core';
import CustomInput from '../../components/custom-input';
import { getAccountName } from '../../helper/get-account-name';
import { useHistory } from 'react-router-dom';
import { Actions } from '../../store/login-reducer';
import Joi from'@hapi/joi';
import { ROUTE_SIGNIN } from '../../utils/const';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      marginTop: '-50px'
    },
    title: {
      fontFamily: `Muli`,
    },
    card: {
      minWidth: '330px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '20px',
      marginTop: '30px',
      boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
    },
    account: {
      marginTop: '10px',
      fontFamily: `Muli`,
    },
    spinner: {
      color: 'white',
      marginRight: '10px'
    },
    error: {
      color: 'red',
      font: '12px/12px Muli',
      marginTop: '-10px'
    }
}));

const Signup = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  const [name, setName] = useState('');
  const [nameerr, setNameErr] = useState('');
  const [email, setEmail] = useState('');
  const [emailerr, setEmailErr] = useState('');
  const [password, setPassword] = useState('');
  const [passworderr, setPasswordErr] = useState('');
  const AccountName = getAccountName();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const settinginfo = useSelector((state) => state.setting);
  const login = useSelector((state) => state.login);

  const schema = {
    name: Joi.string().required().label('Name'),
    email: Joi.string().required().email({tlds: { allow: true } }).label('Email'),
    password: Joi.string().required().label('Password'),  
  };

  const SignupButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#31919D'),
      backgroundColor: '#31919D',
      '&:hover': {
        backgroundColor: '#61C3CF',
      },
      marginTop: '15px'
    },
  }))(Button);

  const setNameSuggest = (value) => {
    const suggest_name = value.split('@')[0];
    setName(suggest_name);
  };

  const handleSignup = () => {
    const resultName = Joi.validate({name: name}, {name: schema.name});
    const resultEmail = Joi.validate({email: email}, {email: schema.email});
    const resultPass = Joi.validate({password: password}, {password: schema.password});
    if (resultName.error) {
        setNameErr(resultName.error.details[0].message);
    } else {
        setNameErr('');
    }
    if (resultEmail.error) {
        setEmailErr(resultEmail.error.details[0].message);
    } else {
        setEmailErr('');
    }
    if (resultPass.error) {
        setPasswordErr(resultPass.error.details[0].message);
    }else {
        setPasswordErr('');
    }
    if (resultName.error === null && resultEmail.error === null && resultPass.error === null) {
        props.dispatch(Actions.guestSignup({ name: name, password: password, email: email, AccountName: AccountName, history: history }));
        setLoading(true);
    }
  };

  useEffect(() => {
    if (login.trying_signup === 2) {
      props.dispatch(Actions.signUpReset());
      history.push(ROUTE_SIGNIN);
    }
    if (login.trying_signup === -1) {
      props.dispatch(Actions.signUpReset());
      setLoading(false);
    }
  }, [login]);

  return (
    <div className={classes.root}>
      <form className={classes.card}>
        <h1 className={classes.title}>{dictionary.appName}</h1>        
        <CustomInput
          labelText="Email"
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          value={email}
          handleChange={(e) => {setEmail(e.target.value); setNameSuggest(e.target.value);}}
          type="text"
          error={emailerr === '' ? false:true}
        />
        {emailerr && <span className={classes.error}>{emailerr}</span>}
        <CustomInput
          labelText="Username"
          id="username"
          formControlProps={{
            fullWidth: true
          }}
          value={name}
          handleChange={(e) => setName(e.target.value)}
          type="text"
          error={nameerr === '' ? false:true}
        />
        {nameerr && <span className={classes.error}>{nameerr}</span>}
        <CustomInput
          labelText="Password"
          id="password"
          formControlProps={{
            fullWidth: true
          }}
          value={password}
          handleChange={(e) => setPassword(e.target.value)}
          type="password"
          error={passworderr === '' ? false:true}
        />
        {passworderr && <span className={classes.error}>{passworderr}</span>}

        <SignupButton onClick={handleSignup}>
          {loading && <CircularProgress
            className={classes.spinner}
            size={20}
          />}
          {dictionary.btnLabelSignup}
        </SignupButton>

        {settinginfo.allowGuests && <span className={classes.account}>
            {dictionary.formLabelRegister} <Link href="/signin" color="initial">{dictionary.formLabelSignin}</Link>
        </span>}
      </form>
    </div>    
  )
}

export default connect(s => ({}))(Signup);