import update from 'immutability-helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAccountName } from '../helper/get-account-name';
import { API_LOGIN, API_GUEST_LOGIN, API_GUEST_CREATE } from '../utils/const';
import apicall from '../utils/api-call';

const SUCCESS = 0
const ATTEMPT = 1
const FAILURE = -1
toast.configure(); 

export const isObject = (element) => Object.prototype.toString.call(element) === '[object Object]';


export const ActionsTypes = {
  SIGN_IN_ATTEMPT: "SIGN_IN_ATTEMPT",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  GUEST_SIGN_UP: 'GUEST_SIGN_UP',
  SIGN_UP_ATTEMPT: "SIGN_UP_ATTEMPT",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  SIGN_UP_RESET: "SIGN_UP_RESET",
}

//Redux-Thunk
//{{{ signIn
export const signIn = ({name, password }) => {
  return (dispatch, getState) => {
    const state = getState();
    const AccountName = getAccountName();
    dispatch(Actions.signInAttempt())
    if (AccountName === name) {
      return apicall({
        url: API_LOGIN,
        method: "POST",
        type: "json",
        params: { Name: name, Password: password }
      })
        .then(response => {
          let res = null
          if ([200, 403, 500].includes(response.status)) {
            res = JSON.parse(response.responseText)
          }
          if (res && res.tokenValue) {
            return dispatch(Actions.signInSuccess({ name: name, token: res.tokenValue, guestname: null, guesttoken: null }));
          } else if (res && res.Message) {
            toast.warn(res.Message)
          } else if (res && res.Error) {
            toast.error(res.Error)
          } else {
            toast.error("Error: unable to signin")
          }
          return dispatch(Actions.signInFailure());
        })
    } else {
      return apicall({
        url: API_GUEST_LOGIN,
        method: "POST",
        type: "json",
        params: { AccountName: AccountName, GuestName: name, Password: password }
      })
        .then(response => {
          let res = null
          if ([200, 403, 500].includes(response.status)) {
            res = JSON.parse(response.responseText)
          }
          if (res && res.tokenValue) {
            return dispatch(Actions.signInSuccess({ name: AccountName, token: null, guestname: name, guesttoken :  res.tokenValue }));
          } else if (res && res.message) {
            toast.warn(res.message)
          } else if (res && res.Error) {
            toast.error(res.Error)
          } else {
            toast.error("Error: unable to signin")
          }
          return dispatch(Actions.signInFailure());
        })
    }
  };
};
//}}}

//{{{ signIn
export const guestSignup = ({name, password, email, AccountName, history }) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(Actions.signUpAttempt())    
    return apicall({
      url: API_GUEST_CREATE,
      method: "POST",
      type: "json",
      params: { Email: email, AccountName: AccountName, Name: name, Password: password }
    })
      .then(response => {
        let res = null
        if ([200, 403, 500].includes(response.status)) {
          res = JSON.parse(response.responseText)
        }
        if (res && res.message) {
          toast.success(res.message)
          return dispatch(Actions.signUpSuccess());
        } else if (res && res.Error) {
          toast.error(res.Error)
        } else {
          toast.error("Error: unable to signup")
        }
        return dispatch(Actions.signUpFailure());
      })
    }
};
//}}}

export const Actions = {
  signIn,
  signInAttempt: (payload) => ({ type: ActionsTypes.SIGN_IN_ATTEMPT, payload: payload }),
  signInSuccess: (payload) => ({ type: ActionsTypes.SIGN_IN_SUCCESS, payload: payload }),
  signInFailure: (payload) => ({ type: ActionsTypes.SIGN_IN_FAILURE, payload: payload }),
  signOut: (payload) => ({ type: ActionsTypes.SIGN_OUT, payload: payload }),
  guestSignup,//: (payload) => ({ type: ActionsTypes.GUEST_SIGN_UP, payload: payload }),
  signUpAttempt: (payload) => ({ type: ActionsTypes.SIGN_UP_ATTEMPT, payload: payload }),
  signUpSuccess: (payload) => ({ type: ActionsTypes.SIGN_UP_SUCCESS, payload: payload }),
  signUpFailure: (payload) => ({ type: ActionsTypes.SIGN_UP_FAILURE, payload: payload }),
  signUpReset: () => ({ type: ActionsTypes.SIGN_UP_RESET }),
}

let initial = {
  name: null,
  token: null,
  guestname: null,
  guesttoken: null,
  trying_signin: 0,
  trying_signup: 0
}

export default (state, action) => {
  state = state == null ? initial : state
  switch (action.type) {
    //{{{ SIGN_IN_ATTEMPT
    case ActionsTypes.SIGN_IN_ATTEMPT: {
      return update(state, {
        trying_signin: { $set: 1 },
      })
    }
    //}}}
    //{{{ SIGN_IN_SUCCESS
    case ActionsTypes.SIGN_IN_SUCCESS: {
      const { token, name, guestname, guesttoken } = action.payload
      localStorage.setItem('name', name)
      localStorage.setItem('token', token)
      localStorage.setItem('guestname', guestname)
      localStorage.setItem('guesttoken', guesttoken)
      return update(state, {
        name: { $set: name },
        token: { $set: token },
        guestname: { $set: guestname },
        guesttoken: { $set: guesttoken },
        trying_signin: { $set: 0 },
      })
    }
    //}}}
    //{{{ SIGN_IN_FAILURE
    case ActionsTypes.SIGN_IN_FAILURE: {
      return update(state, {
        trying_signin: { $set: 0 },
      })
    }
    //}}}
    //{{{ SIGN_OUT
    case ActionsTypes.SIGN_OUT: {
      localStorage.setItem('name', "")
      localStorage.setItem('token', "")
      localStorage.setItem('guestname', "")
      localStorage.setItem('guesttoken', "")
      return update(state, {
        name: { $set: null },
        token: { $set: null }
      })
    }
    //}}}
    // case ActionsTypes.GUEST_SIGN_UP: {
    //   const { is_guest_signup } = action.payload;
    //   console.log(is_guest_signup);
    //   return update(state, {
    //     is_guest_signup: { $set: is_guest_signup },
    //   })
    // }
    //{{{ SIGN_IN_ATTEMPT
    case ActionsTypes.SIGN_UP_ATTEMPT: {
      return update(state, {
        trying_signup: { $set: 1 },
      })
    }
    //}}}
    //{{{ SIGN_IN_SUCCESS
    case ActionsTypes.SIGN_UP_SUCCESS: {
      return update(state, {
        trying_signup: { $set: 2 },
      })
    }
    //}}}
    //{{{ SIGN_IN_FAILURE
    case ActionsTypes.SIGN_UP_FAILURE: {
      return update(state, {
        trying_signup: { $set: -1 },
      })
    }
    case ActionsTypes.SIGN_UP_RESET: {
      return update(state, {
        trying_signup: { $set: 0 },
      })
    }
    default:
      return state;
  }
}

