import React, { useContext, /*useState,*/ useEffect } from 'react';
import { makeStyles, withStyles,/*  useTheme*/ } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect } from 'react-redux';
import { ROUTE_PARAM_CONTENTS } from '../../utils/const';
import { useContentState } from '../../helper/routing-analyser';
import SettingAppearance from './settings/setting-appearance';
import SettingCategory from './settings/setting-category';
import SettingGeneral from './settings/setting-general';
import SettingUser from './settings/setting-user';
import SettingView from './settings/setting-view';
import FolderList from './folder-list';
import BrowserList from './browser-list';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'block'
    },
    contents: {
      marginTop: '20px',
      padding: '0 10px'
    },
    paginationbar: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    },
}));

const getContentPages = (route_state, props) => {
  let contents = null;
  const state = String(route_state) === 'null' ? ROUTE_PARAM_CONTENTS.HOME : route_state;
  switch (state) {
    case ROUTE_PARAM_CONTENTS.SETTING_APPEARANCE:
      contents = <SettingAppearance/>;
      break;
    case ROUTE_PARAM_CONTENTS.SETTING_CATEGORY:
      contents = <SettingCategory/>;
      break;
    case ROUTE_PARAM_CONTENTS.SETTING_GENERAL:
      contents = <SettingGeneral/>;
      break;
    case ROUTE_PARAM_CONTENTS.SETTING_USER:
      contents = <SettingUser/>;
      break;
    case ROUTE_PARAM_CONTENTS.SETTING_VIEW:
      contents = <SettingView/>;
      break;
    case ROUTE_PARAM_CONTENTS.HOME:
      contents = <FolderList/>;
      break;
    case ROUTE_PARAM_CONTENTS.SEARCH:
      contents = <FolderList/>;
      break;
    case ROUTE_PARAM_CONTENTS.BROWSER:
      contents = <BrowserList/>;
      break;
    default:
      break;
  }

  return contents;
}

function Contents(props) {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const content = useContentState();  
  const ContentPages = getContentPages(content, props);

  return (
      <div className={classes.root}>
          <div className={classes.contents}>      
          { ContentPages }
          </div>
      </div>
  )
}

export default connect(s => ({}))(Contents);
