import localForage from 'localforage'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import reduxThunk from 'redux-thunk';
import LoginReducer, { Actions as LoginActions } from './login-reducer';
import SettingReducer from './setting-reducer';
import FolderReducer from './folder-reducer';
import UploadReducer from './upload-reducer';
import AdminReducer from './admin-reducer';
import rootEpic from './epics';

const AccountName = localStorage.getItem("name")
const token = window.localStorage.getItem("token")
const GuestName = window.localStorage.getItem("guestname")
const GuestToken = window.localStorage.getItem("guesttoken")
const epicMiddleware = createEpicMiddleware({
    dependencies: { token, AccountName , GuestName, GuestToken }
});

const middleware = [ reduxThunk, epicMiddleware ];
const persistConfig = {
    key: 'root',
    storage: localForage,
    blacklist: ['home','login','upload']
}
// const homeConfig = {
//   key: 'home',
//   storage: localForage,
//   whitelist: [ 'folders', 'subfolders', 'expanded_folders','folders_reverse_index','folders_keys','listing_layout']
// }

const configureStore = () => {
    const reducers = combineReducers({
       login: LoginReducer,
       setting: SettingReducer,
       folder: FolderReducer,
       upload: UploadReducer,
       admin: AdminReducer,
    })
    
    const persistedReducer = persistReducer(persistConfig, reducers)
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
    const store = createStore(
        persistedReducer,
        {},
        composeEnhancers(
          applyMiddleware(...middleware),
        )
    )
    
    epicMiddleware.run(rootEpic);
  
    const persistor = persistStore(store)  
  
    const name = localStorage.getItem("name");
    const token = localStorage.getItem("token");
    const guestname = localStorage.getItem("guestname");
    const guesttoken = localStorage.getItem("guesttoken");
    
    if(name && (token || guesttoken)) {
      if(token){
        store.dispatch(LoginActions.signInSuccess({name, token, guestname, guesttoken}));
      } else {
        store.dispatch(LoginActions.signInSuccess({name, guestname, guesttoken}));
      }      
    }
  
    return { store, persistor }
  }
  
export default configureStore