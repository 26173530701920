import { combineEpics } from 'redux-observable';
import deleteFilesEpic from './delete-files-epic';
// import getFilesReferencesEpic from './get-files-references-epic';
// import moveFilesEpic from './move-files-epic';
// import typingFoldersFilterEpic from './typing-folders-filter-epic';

const rootEpic = combineEpics(
  deleteFilesEpic,
  // moveFilesEpic,
  // typingFoldersFilterEpic,
  // getFilesReferencesEpic
);

export default rootEpic
