import React, { useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { LanguageContext } from '../../localization/LanguageContainers';
import { DRAWER_WIDTH, ROUTE_PARAM_ORGANIZE } from '../../utils/const';
import {Avatar} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList';
import VerifiedUserIcon  from '@material-ui/icons/VerifiedUser';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useLocationQuery, useOrganizeState } from '../../helper/routing-analyser';
import { handleSetting, handleOrganizeMode, handleMyContents, handleSignout } from '../../utils/nav-controls';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: '7! important',
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  toolbar: {
    marginTop: '24px',
    marginBottom: '10px',
    font: `400 12px/20px Muli`,
    color: '#32385C'
  },
  appTitle: { 
    userSelect: 'none',
    marginTop: '10px',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  avatar: {
    width: '70px',
    height: '70px',
    marginLeft: '125px'
  }
}))

const NavSideBar = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const history = useHistory();
  const query = useLocationQuery(); 
  const organizeExpand = useOrganizeState(); 
  const url = history.location.pathname.split("/");
  const appTheme = useSelector(store => store.setting.theme);
  const username = useSelector(store => store.login.name);

  const usePaper = makeStyles((theme) => ({
      paper: {
        backgroundColor: appTheme.backgroundColorSecondary,
        width: DRAWER_WIDTH,
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        textAlign: 'center'
      }
    }))
  const paperclasses = usePaper(); 

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        anchor={'right'}
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        classes={{ paper: paperclasses.paper, }}
        ModalProps={{
          keepMounted: true
        }}
      >
        <div>
          <div className={classes.toolbar} >
            <Avatar src={null} className={classes.avatar}/>           
            <h1 className={classes.appTitle} style={{color: appTheme.textColorSecondary}}>{username}</h1> 
          </div>
          <div id='sidebardrawer'>
            <Divider />
            <List>
              <ListItem button key={dictionary.btnLabelSettings} onClick={(e)=>{}} >
                <ListItemIcon style={{color: appTheme.textColor}}><SettingsIcon /></ListItemIcon>
                <ListItemText style={{color: appTheme.textColor}} primary={dictionary.btnLabelSettings} onClick={()=>handleSetting(history, query, dictionary, props)}/>
              </ListItem>
              <ListItem button key={dictionary.btnLabelOrganizeModeOn} onClick={(e)=>{}} >
                <ListItemIcon style={{color: appTheme.textColor}}><ViewListIcon /></ListItemIcon>
                <ListItemText style={{color: appTheme.textColor}} primary={organizeExpand === ROUTE_PARAM_ORGANIZE.OPEN ? dictionary.btnLabelOrganizeModeOff : dictionary.btnLabelOrganizeModeOn} onClick={()=>handleOrganizeMode(history, query, dictionary, props)}/>
              </ListItem>
              <ListItem button key={dictionary.btnLabelMyContents} onClick={(e)=>{}} >
                <ListItemIcon style={{color: appTheme.textColor}}><VerifiedUserIcon /></ListItemIcon>
                <ListItemText style={{color: appTheme.textColor}} primary={dictionary.btnLabelMyContents} onClick={()=>handleMyContents(history, query, dictionary, username, props)}/>
              </ListItem>
              <ListItem button key={dictionary.btnLabelSignout} onClick={(e)=>{}} >
                <ListItemIcon style={{color: appTheme.textColor}}><ExitToAppIcon /></ListItemIcon>
                <ListItemText style={{color: appTheme.textColor}} primary={dictionary.btnLabelSignout} onClick={()=>handleSignout(props)}/>
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
    </nav>
  );
}

export default connect(s => ({}))(NavSideBar);