import { ofType } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, endWith, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, ActionsTypes } from '../admin-reducer';

export default (action$, state$, {token, AccountName}) => action$.pipe(
  ofType(ActionsTypes.DELETE_FILES),
  switchMap( ({ payload }) => {
    const { filesIDs, folderId } = payload
    const photosIds$ = from(filesIDs);
    return photosIds$.pipe(
      mergeMap(fileId => {
          return concat(
            of(Actions.deleteFileAttempt({fileId, filesIDs})),
            ajax({
              url: `https://myra-delete.azurewebsites.net/api/DeleteFile`,
              method: "POST",
              type: "json",
              body: JSON.stringify({AccountName, FileId: fileId}),
              headers: {
                'Content-Type': 'application/json',
                'token' : token
              }
            }).pipe( map((res) => { return Actions.deleteFileSuccess({fileId, folderId}) })
                  , catchError( err => of(Actions.deleteFileFailure({fileId, folderId})))
            )
          )}
      , null, 5)
    , endWith( Actions.deleteFilesFullfiled() )
    )
  })
);

