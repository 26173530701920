import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Button, Icon, Dialog, DialogContent } from '@material-ui/core/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../store/admin-reducer';
import { Actions as FolderActions } from '../../store/folder-reducer';
import TrashAltRegular from '../../icons/TrashAltRegular';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from 'react-tooltip-lite';

const getContentsSize = (isfull) => {
  if (isfull) {
    return {width: window.screen.width - 80, height: (window.screen.width) * 189 / 500}
  } else {
    return {width: 500, height: 210}
  }
}

function DeleteButton(props) {
  const { dictionary } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const appTheme = useSelector(store => store.setting.theme);
  const themeName = useSelector(store => store.setting.themeName);
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const selectedFolder = props.folder;
  const clipBoard = useSelector((store) => store.folder.clipboardPhotos);
  const isDeleting = useSelector((store) => store.admin.is_deleting_file);
  const guestname = localStorage.getItem("guestname");
  const useStyles = makeStyles((theme) => ({
      root: {
        display: 'flex',
        margin: '0 5px'
      },
      iconRoot: {   
        height: '100%',
        marginRight: '10px',
        marginTop: '-5px'
      },
      iconDelRoot: {   
        height: '100%',
        marginTop: '-6px'
      },
      contents: {
        padding: '20px',
        textAlign: 'center',
        display: 'grid',
        height: '100%',
        verticalAlign: 'middle',
        alignContent: 'center'
      },
      text: {
        fontFamily: 'Muli',
        color: '#242C52'  
      },
      controls: {
        marginTop: '20px'
      },
      custombutton: {
        width: '40px',
        height: '30px',
        minWidth: '40px',
        color: appTheme.textColorSecondary,
        backgroundColor: appTheme.buttonColorThird,
        transition: 'all 0.2s ease-out',
        '&:hover': {
          backgroundColor: '#FC96B9',
        }
      },
      delbutton: {
        width: '120px',
        height: '30px',
        color: theme.palette.getContrastText(appTheme.buttonColor),
        backgroundColor: appTheme.buttonColor,
        transition: 'all 0.2s ease-out',
        '&:hover': {
          opacity: '0.7',
          backgroundColor: appTheme.buttonColor,
        }
      },
      cancelbutton: {
        width: '120px',
        height: '30px',
        color: appTheme.textColorSecondary,
        backgroundColor: appTheme.buttonColorThird,
        transition: 'all 0.2s ease-out',
        '&:hover': {
          backgroundColor: '#FC96B9',
        }
      }
  }));
  const classes = useStyles();

  const handleDelete = () => {
    if (String(guestname) !== 'null' && guestname !== selectedFolder.guestName) {
      toast.error(dictionary.appToastGuestPermissionError);
      return;
    }

    if (clipBoard.length === 0) {
      toast.error(dictionary.appToastNoFileDelItems);
      return;
    }
    setOpen(true);
  }

  const onDelete = () => {
    setOpen(false);
    dispatch(Actions.deleteFiles({ filesIDs: clipBoard, folderId: selectedFolder.id }));
    dispatch(FolderActions.clearClipboardPhotos());
  }

  useEffect(()=>{
    if (!selectedFolder) return;
    if (isDeleting.length > 0 || !selectedFolder.id) return;
    dispatch(FolderActions.getFilesByFolderId(selectedFolder.id));
  }, [isDeleting]);

  return (
      <div className={classes.root}>
        <div className={classes.interface}>
          <Tooltip content={ dictionary.navTooltipDelete } direction="bottom" className={themeName === 'LightTheme' ? 'target-white' : 'target-black'}>
            <Button className={classes.custombutton} onClick={()=>handleDelete()}>
              <Icon classes={{root: classes.iconDelRoot}}>
                <TrashAltRegular size={16}/>
              </Icon>
            </Button>
          </Tooltip>
        </div>
        <div className={classes.dialog}>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            //onClose={()=>setOpen(false)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent style={{backgroundColor: appTheme.backgroundColor}}> 
              <div className={classes.contents}>
                <div className={classes.text}>
                  <h3 style={{color: appTheme.textColorThird}}>
                  {dictionary.formLabelFileDeleteQuery1} {clipBoard.length} {dictionary.formLabelFileDeleteQuery2}
                  </h3>
                </div>
                <div className={classes.controls}>
                  <Button className={classes.delbutton} style={{marginRight: '5px'}} onClick={()=>{onDelete();}}>
                    <Icon classes={{root: classes.iconRoot}}>
                      <TrashAltRegular size={16}/>
                    </Icon>
                    {dictionary.btnLabelDelete} 
                  </Button>
                  <Button className={classes.cancelbutton} style={{marginLeft: '5px'}} onClick={()=>setOpen(false)}>
                    {dictionary.btnLabelCancel}
                  </Button>
                </div>
              </div>             
            </DialogContent>
          </Dialog>
        </div>
      </div>
  )
}

export default DeleteButton;
