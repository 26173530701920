import React from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import './progress.css';
import CloudUploadAltSolid from '../../icons/CloudUploadAltSolid';

class UploadProgress extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
      const sqSize = this.props.sqSize;
      const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
      const viewBox = `0 0 ${sqSize} ${sqSize}`;
      const dashArray = radius * Math.PI * 2;
      const dashOffset = isNaN(this.props.percentage) ? 0 : dashArray - dashArray * this.props.percentage / 100;
      const {dictionary} = this.context;
  
      return (
        <div style={{display: 'flex'}}> 
            <div style={{position: 'fixed', marginLeft: '50px', marginTop: '28px', color: '#242C52'}}>       
                <CloudUploadAltSolid size={50}/>
            </div>
            <svg
                width={this.props.sqSize}
                height={this.props.sqSize}
                viewBox={viewBox}>
                <circle
                className="circle-background"
                cx={this.props.sqSize / 2}
                cy={this.props.sqSize / 2}
                r={radius}
                strokeWidth={`${this.props.strokeWidth}px`} />
                <circle
                className="circle-progress upload-stroke"
                cx={this.props.sqSize / 2}
                cy={this.props.sqSize / 2}
                r={radius}
                strokeWidth={`${this.props.strokeWidth}px`}
                transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
                style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset
                }} />
                <text
                className="text-percent unselectable upload-fill"
                x="50%"
                y="60%"
                dy=".3em"
                textAnchor="middle">
                {`${this.props.percentage}%`}
                </text>
                <text
                className="text-completed unselectable upload-fill"
                x="50%"
                y="78%"
                dy=".3em"
                textAnchor="middle">
                {dictionary.appLabelCompleted}
                </text>
            </svg>
        </div>
      );
    }
  }
  UploadProgress.contextType = LanguageContext;

  export default UploadProgress;
  
  UploadProgress.defaultProps = {
    sqSize: 150,
    percentage: 25,
    strokeWidth: 6
  };