import React, { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../../localization/LanguageContainers';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import {Button, CircularProgress, Link} from '@material-ui/core';
import CustomInput from '../../components/custom-input';
import { getAccountName } from '../../helper/get-account-name';
import { Actions } from '../../store/login-reducer';
import Joi from'@hapi/joi';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      marginTop: '-50px'
    },
    title: {
      fontFamily: `Muli`,
    },
    card: {
      minWidth: '330px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '20px',
      marginTop: '30px',
      boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
    },
    account: {
      marginTop: '10px',
      fontFamily: `Muli`,
    },
    spinner: {
      color: 'white',
      marginRight: '10px'
    },
    error: {
      color: 'red',
      font: '12px/12px Muli',
      marginTop: '-10px'
    }
}));

const Signin = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  const [name, setName] = useState('');
  const [nameerr, setNameErr] = useState('');
  const [password, setPassword] = useState('');
  const [passworderr, setPasswordErr] = useState('');
  const [loading, setLoading] = useState(false);
  const settinginfo = useSelector((state) => state.setting);

  const schema = {
    name: Joi.string().required().label('Name'),
    password: Joi.string().required().label('Password'),  
  };

  const SigninButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#31919D'),
      backgroundColor: '#31919D',
      '&:hover': {
        backgroundColor: '#61C3CF',
      },
      marginTop: '15px'
    },
  }))(Button);

  const handleSignin = () => {
    const resultName = Joi.validate({name: name}, {name: schema.name});
    const resultPass = Joi.validate({password: password}, {password: schema.password});
    if (resultName.error) {
        setNameErr(resultName.error.details[0].message);
    } else {
        setNameErr('');
    }
    if (resultPass.error) {
        setPasswordErr(resultPass.error.details[0].message);
    }else {
        setPasswordErr('');
    }
    if (resultName.error === null && resultPass.error === null) {
        props.dispatch(Actions.signIn({ name: name, password: password }));
        setLoading(true);
    }
  };

  const handleSigninKeyboard = () => {
    const name = document.getElementById('name').value;
    const password = document.getElementById('password').value;
    const resultName = Joi.validate({name: name}, {name: schema.name});
    const resultPass = Joi.validate({password: password}, {password: schema.password});
    if (resultName.error) {
        setNameErr(resultName.error.details[0].message);
    } else {
        setNameErr('');
    }
    if (resultPass.error) {
        setPasswordErr(resultPass.error.details[0].message);
    }else {
        setPasswordErr('');
    }   
    if (resultName.error === null && resultPass.error === null) {
        props.dispatch(Actions.signIn({ name: name, password: password }));
        setLoading(true);
    } 
  };

  useEffect(() => {
    setName(getAccountName());
    document.addEventListener("keypress", function onEvent(event) {
      if (event.key === "Enter") {
        handleSigninKeyboard()
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <form className={classes.card}>
        <h1 className={classes.title}>{dictionary.appName}</h1>
        <CustomInput
          labelText="Name"
          id="name"
          formControlProps={{
            fullWidth: true
          }}
          value={name}
          handleChange={(e) => setName(e.target.value)}
          type="text"
          error={nameerr === '' ? false:true}
        />
        {nameerr && <span className={classes.error}>{nameerr}</span>}
        <CustomInput
          labelText="Password"
          id="password"
          formControlProps={{
            fullWidth: true
          }}
          value={password}
          handleChange={(e) => setPassword(e.target.value)}
          type="password"
          error={passworderr === '' ? false:true}
        />
        {passworderr && <span className={classes.error}>{passworderr}</span>}

        <SigninButton onClick={handleSignin}>
          {loading && <CircularProgress
            className={classes.spinner}
            size={20}
          />}
          {dictionary.btnLabelSignin}
        </SigninButton>

        {settinginfo.allowGuests && <span className={classes.account}>
            {dictionary.formLabelAccount} <Link href="/signup" color="initial">{dictionary.formLabelSignup}</Link>
        </span>}
      </form>
    </div>    
  )
}

export default connect(s => ({}))(Signin);
