export const darkTheme = {
    backgroundColor: "#333333",
    backgroundColorSecondary: "#3E3E3E",
    backgroundColorThird: "#3E3E3E",
    backgroundColorFourth: "#333333",
    backgroundColorFifth: "#4E4E4E",
    textColor: "#FFFFFF",
    textColorSecondary: "#FFFFFF",
    textColorThird: "#FFFFFF",
    textColorFourth: "#4E774F",
    textColorFifth: "#FEFEFE",
    textColorSixth: "#494949",
    buttonColor: "#607D8B",
    buttonColorSecondary: "#4E774F",
    buttonColorThird: "#858585",
    buttonColorFourth: "#FFEB3B",
    buttonColorFifth: "#",
    paginationColor: "#4f5ff5",
}
  
export const lightTheme = {
    backgroundColor: "#FFFFFF",
    backgroundColorSecondary: "#F8F9FD",
    backgroundColorThird: "#DEDEDE",
    backgroundColorFourth: "#DEDEDE",
    backgroundColorFifth: "#A9ABAA",
    textColor: "#4C4C4C",
    textColorSecondary: "#32385C",
    textColorThird: "#3995A4",
    textColorFourth: "#FFFFFF",
    textColorFifth: "#242C52",
    textColorSixth: "#DCDCDC",
    buttonColor: "#31919D",
    buttonColorSecondary: "#242C52",
    buttonColorThird: "#E8E8ED",
    buttonColorFourth: "#91EE26",
    buttonColorFifth: "#",
    paginationColor: "#4f5ff5",
}