import { useHistory, useParams, useLocation } from "react-router-dom";
import { ROUTE_PARAM_KEY, ROUTE_PARAM_SIDEBAR, ROUTE_PARAM_SORT } from "../utils/const";

export const useSideBarState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const sidebar = query.get(ROUTE_PARAM_KEY.SIDEBAR);
  return sidebar;
}

export const useSlideBarState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const slidebar = query.get(ROUTE_PARAM_KEY.SLIDEBAR);
  return slidebar;
}

export const useContentState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.CONTENT);
  return content;
}

export const useOrganizeState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.ORGANIZE);
  return content;
}

export const useSelectState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SELECT);
  return content;
}

export const useSelContentState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SELCONTENT);
  return content;
}

export const useSelLevelState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SELLEVEL);
  return content;
}

export const useSelKeyState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SELKEY);
  return content;
}

export const useSelAppendState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SELAPPEND);
  return content;
}

export const useSelNotifyState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SELNOTIFY);
  return content;
}

export const useSortState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SORT);
  return String(content) === 'null' ? ROUTE_PARAM_SORT.FILEDEC : content;
}

export const useAccountState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.ACCOUNT);
  return content;
}

export const useSearchState = () => {    
  const query = new URLSearchParams(useLocation().search);
  const content = query.get(ROUTE_PARAM_KEY.SEARCH);
  return content;
}

export const useLocationQuery = () => {    
  const query = new URLSearchParams(useLocation().search);
  return query;
}