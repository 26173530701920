import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../../localization/LanguageContainers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useQuery } from 'react-query';
import {Button, Icon} from '@material-ui/core/';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Checkbox, FormControlLabel, CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import {Form} from 'antd';
import EmptyData from '../../../icons/EmptyData';
import { getAccountName } from '../../../helper/get-account-name';
import { create_UUID } from '../../../helper/uuid';
import { useWindowSize } from '../../../helper/window-size';
import { 
  EXPEND_TOOL_FIXED_HEIGHT, 
  DRAWER_WIDTH, 
  API_GET_CATEGORIES_URL,
  API_SAVE_CATEGORIES_URL,
  API_DEL_CATEGORIES_URL,
  ROUTE_PARAM_KEY,
  ROUTE_PARAM_SLIDEBAR
} from '../../../utils/const';
import apicall from '../../../utils/api-call';
import Joi from '@hapi/joi';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocationQuery } from '../../../helper/routing-analyser';
import { toast } from 'react-toastify';

toast.configure();
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'block',
      width: '100%'
    },
    toolbar: {
      display: 'flex',
      width: '100%',
      font: `400 12px/24px Muli`,
      marginTop: '10px',
      padding: '0 30px'
    },
    table: {
      width: '100%',
    },
    tablebox: {
      display: 'flex',
      width: '100%',
      font: `400 12px/24px Muli`,
      marginTop: '20px',
      padding: '5px 10px'
    },
    tableempty: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      padding: '10px 0',
      color: 'black'
    },
    drawerPaper: {
      width: DRAWER_WIDTH
    },
    drawerHeader: {
      height: '64px',
    },
    drawerBody: {
      padding: 20,
      marginTop: '20px'
    },
    spinner: {
      color: 'white',
      marginRight: '10px'
    },
    contents: {
      padding: '20px',
      textAlign: 'center',
      display: 'grid',
      height: '100%',
      verticalAlign: 'middle',
      alignContent: 'center'
    },
    text: {
      fontFamily: 'Muli',
      color: '#242C52'  
    },
    controls: {
      marginTop: '20px'
    }
}));

const saveCategoriesAsync = (catagory, method, setLoading, setIsCategoriesModalVisible, refetch) => {
  setLoading(true);
  return apicall({
    url: API_SAVE_CATEGORIES_URL,
    method: "POST",
    type: "json",
    params: catagory,
  }).then(response => {
      setLoading(false);
      setIsCategoriesModalVisible(false);
      refetch();
      let res = null;
      if ([200].includes(response.status)) {
        res = JSON.parse(response.responseText)
        if (String(method) === "ADD") {
          toast.success("The new category was added successfuly.");
        } else {
          toast.success("The new contents of category was updated successfuly.");
        }
      }
      else if (res && res.Message) {
          toast.warn(res.Message)
      } else if (res && res.Error) {
          toast.error(res.Error)
      } else {
          toast.error("Error: Not able update categories.")
      }
  })
};

const deleteCategoriesAsync = (catagoryid, setLoading, refetch) => {
  setLoading(true);
  return apicall({
      url: API_DEL_CATEGORIES_URL,
      method: "POST",
      type: "json",
      params: {
        AccountName: getAccountName(),
        Id: catagoryid
      },
  }).then(response => {
      let res = null
      if ([200].includes(response.status)) {
        setLoading(false);
        refetch();
        res = JSON.parse(response.responseText)
        toast.success("The selected category was deleted successfuly.");
      }
      else if (res && res.Message) {
          toast.warn(res.Message)
      } else if (res && res.Error) {
          toast.error(res.Error)
      } else {
          toast.error("Error: Not able update categories.")
      }
  })
};

function SettingCategory(props) {
  const { dictionary } = useContext(LanguageContext);
  const [width, height] = useWindowSize();
  const classes = useStyles();
  const categories = useSelector((store) => store.catagories);
  const [form] = Form.useForm();
  const [modalView, setModalView] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameerr, setNameErr] = useState('');
  const [descerr, setDescErr] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(null);
  const dispatch = useDispatch();
  const contentHeight = height - EXPEND_TOOL_FIXED_HEIGHT;
  const appTheme = useSelector((store) => store.setting.theme);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const token = useSelector((store) => store.login.token);
  const query = useLocationQuery();
  const history = useHistory();

  const { isLoading, data, isFetching, refetch } = useQuery(['repoData', token], async (token) => {
    const token_ = token.queryKey[1];
    return (await fetch(API_GET_CATEGORIES_URL+`?AccountName=${getAccountName()}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",  
        "Accept": "application/json",
        "token": token_,      
      }
    })).json(); 
  }, {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  });

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: appTheme.backgroundColorFifth,
      color: theme.palette.getContrastText(appTheme.backgroundColorFifth),
      fontFamily: 'Muli'
    },
    body: {
      fontSize: 14,
      fontFamily: 'Muli'
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#98989820'//theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const schema = {
    name: Joi.string().required().label('Name'),
    description: Joi.string().label('Description'),  
  };

  const CustomButton = withStyles((theme) => ({
    root: {
      width: '150px',
      height: '30px',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    }
  }))(Button);

  const EditButton = withStyles((theme) => ({
    root: {
      width: '35px',
      minWidth: '35px',
      height: '30px',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    }
  }))(Button);
  
  const DeleteButton = withStyles((theme) => ({
    root: {
      width: '35px',
      minWidth: '35px',
      height: '30px',
      color: theme.palette.getContrastText('#E46C6C'),
      backgroundColor: '#E46C6C',
      '&:hover': {
        opacity: '0.7',
        backgroundColor: '#E46C6C',
      }
    }
  }))(Button);

  const DelButton = withStyles((theme) => ({
    root: {
      width: '120px',
      height: '30px',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);

  const CancelButton = withStyles((theme) => ({
    root: {
      width: '120px',
      height: '30px',
      color: appTheme.textColorSecondary,
      backgroundColor: appTheme.buttonColorThird,
      '&:hover': {
        backgroundColor: '#FC96B9',
      }
    },
  }))(Button);

  const usePaper = makeStyles((theme) => ({
    paper: {
      backgroundColor: appTheme.backgroundColorSecondary,
      width: DRAWER_WIDTH,
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      textAlign: 'center'
    },
    text: {
      color: appTheme.textColor
    }
  }))
  const paperclasses = usePaper();

  const ItemTextField = withStyles({
    root: {
      '& label': {
        color: appTheme.textColor,
      },
      '& label.Mui-focused': {
        color: appTheme.textColorThird,
      },
      '& label.Mui-disabled': {
        color: appTheme.textColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: appTheme.textColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: appTheme.textColor,
        },
        '&:hover fieldset': {
          opacity: '0.7',
          borderColor: appTheme.textColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: appTheme.textColorThird,
        },
        '&.Mui-disabled fieldset': {
          borderColor: appTheme.textColor,
        },
      },
    },
  })(TextField);

  function onFinish (values) {
    const resultName = Joi.validate({name: values.Name}, {name: schema.name});
    if (resultName.error) {
        setNameErr(resultName.error.details[0].message);
    } else {
        setNameErr('');
    }
    if (resultName.error !== null) {
        return;
    }
    if (categoryData) {
      const newValues = {...values};
      newValues.Id = categoryData.id;
      newValues.AccountName = getAccountName();
      saveCategoriesAsync(newValues, "UPDATE", setLoading, setModalView, refetch);
    } else {
      const uuid = create_UUID();
      const AccountName = getAccountName();
      values.Id = uuid;
      values.AccountName = AccountName;
      saveCategoriesAsync(values, "ADD", setLoading, setModalView, refetch);
    } 
  }

  function onDelete () {
    setOpen(false);
    deleteCategoriesAsync(deleteCategory.id, setLoading, refetch);  
  }

  // useEffect(()=>{    
  //   if (query.get(ROUTE_PARAM_KEY.SLIDEBAR) === ROUTE_PARAM_SLIDEBAR.CATE_CREATE) {
  //     setModalView(true);
  //     setNameErr('');
  //     setDescErr('');
  //     setCategoryData(null);
  //     form.setFieldsValue({ Name: '', Description: '', IsExclusive: false });
  //   }
  // }, [])
  
  
  return (
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <CustomButton          
            onClick={() => {      
              setModalView(true);
              setNameErr('');
              setDescErr('');
              setCategoryData(null);
              form.setFieldsValue({ Name: '', Description: '', IsExclusive: false });        
              // query.delete(ROUTE_PARAM_KEY.SLIDEBAR);
              // query.append(ROUTE_PARAM_KEY.SLIDEBAR, ROUTE_PARAM_SLIDEBAR.CATE_CREATE);
              // history.push({pathname: '/', search: query.toString()});
            }} 
          >
            {dictionary.btnLabelAddCategory}
          </CustomButton>
        </div>
        <div className={classes.tablebox} style={{height: `${contentHeight}px`}}>
          {!isLoading && data?.result.length === 0 ? <div className={classes.tableempty}><EmptyData size={100}/><h3 style={{color: appTheme.textColor}}>{dictionary.tblLabelEmptyData}</h3></div> :
          <TableContainer component={Paper} style={{backgroundColor: appTheme.backgroundColor}}>
            <Table stickyHeader className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{dictionary.tblLabelName}</StyledTableCell>
                  <StyledTableCell>{dictionary.tblLabelDescription}</StyledTableCell>
                  <StyledTableCell align="right">{dictionary.tblLabelAction}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.result.map((category) => (
                  <StyledTableRow key={category.id}>
                    <StyledTableCell component="th" scope="row" style={{color: appTheme.textColor}}>
                      {category.name}
                    </StyledTableCell>
                    <StyledTableCell style={{color: appTheme.textColor}}>{category.description}</StyledTableCell>
                    <StyledTableCell align="right">
                      <EditButton 
                        type="link" 
                        style={{padding: '0'}}
                        onClick={() => {
                          setCategoryData(category); 
                          setModalView(true); 
                          setNameErr('');
                          setDescErr('');
                          form.setFieldsValue({ Name: category.name, Description: category.description, IsExclusive: category.isExclusive });
                        }}
                      >
                        <EditIcon/>
                      </EditButton>
                      <DeleteButton 
                        style={{padding: '0', marginLeft: '5px'}} 
                        onClick={() => {setDeleteCategory(category); setOpen(true);}}
                      >
                        <DeleteIcon/>
                      </DeleteButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
        </div>        
        <Drawer 
          classes={{ paper: paperclasses.paper }} 
          anchor='right' 
          open={modalView} 
          onClose={() => { 
            setModalView(false); 
            setCategoryData(null); 
            // query.delete(ROUTE_PARAM_KEY.SLIDEBAR); 
            // history.push({pathname: '/', search: query.toString()}); 
          }} 
          width='400px'>
          <div className={classes.drawerHeader} style={{backgroundColor: appTheme.backgroundColorFourth}}></div>
          <div className={classes.drawerBody}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              name="categoriesForm"
            >
              <Form.Item style={{width: '100%'}} name={dictionary.tblLabelName} initialValue={categoryData ? categoryData.name : ''}>
                <ItemTextField 
                  style={{width: '100%'}} 
                  label={dictionary.tblLabelName} 
                  variant="outlined"                  
                  error={nameerr === '' ? false:true}
                  helperText={nameerr}
                  InputProps={{
                    className: paperclasses.text
                  }}
                />
              </Form.Item>
              <Form.Item style={{width: '100%'}} name={dictionary.tblLabelDescription} initialValue={categoryData ? categoryData.description : ''}>
                <ItemTextField 
                  style={{width: '100%'}} 
                  label={dictionary.tblLabelDescription}
                  multiline rows={3} 
                  variant="outlined"              
                  error={descerr === '' ? false:true}
                  helperText={descerr}
                  InputProps={{
                    className: paperclasses.text
                  }}
                />
              </Form.Item>
              <Form.Item name={dictionary.formLabelIsExclusive} style={{textAlign: 'left', color: appTheme.textColor}} valuePropName="checked" initialValue={false}>
                <FormControlLabel
                  control={<Checkbox value="introduce" color="default" style={{color: appTheme.textColor}}/>}
                  label={dictionary.formLabelIsExclusive}
                />
              </Form.Item>
              <Form.Item>
                <CustomButton type="primary" htmltype="submit" style={{width: '100%'}}>
                  {loading && <CircularProgress
                    className={classes.spinner}
                    size={20}
                  />}
                  {dictionary.btnLabelSave}
                </CustomButton>
              </Form.Item>
            </Form>
          </div>          
        </Drawer>
        <div className={classes.dialog}>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent style={{backgroundColor: appTheme.backgroundColor}}> 
              <div className={classes.contents}>
                <div className={classes.text}>
                  <h3 style={{color: appTheme.textColorThird}}>
                  {dictionary.formLabelFileDeleteQuery1} {deleteCategory && deleteCategory.name} {dictionary.formLabelFileDeleteQuery3}
                  </h3>
                </div>
                <div className={classes.controls}>
                  <DelButton style={{marginRight: '5px'}} onClick={()=>{onDelete();}}>
                    {dictionary.btnLabelDelete} 
                  </DelButton>
                  <CancelButton style={{marginLeft: '5px'}} onClick={()=>setOpen(false)}>
                    {dictionary.btnLabelCancel}
                  </CancelButton>
                </div>
              </div>             
            </DialogContent>
          </Dialog>
        </div>
      </div>
  )
}

export default SettingCategory
