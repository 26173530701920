import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useQuery } from 'react-query';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import CreateFolderButton from '../../components/create-folder-button';
import { getAccountName } from '../../helper/get-account-name';
import { useLocationQuery } from '../../helper/routing-analyser';
import { TOOL_FIXED_HEIGHT, API_GET_CATEGORIES_URL, firstLetterUpper } from '../../utils/const';
import { useWindowSize } from '../../helper/window-size';
import { handleHome, handleCategorySelect } from '../../utils/sidebar-controls';
import './index.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    marginTop: '30px',
    marginBottom: '10px',
    font: `400 15px/50px Muli`,
  },
  appTitle: {
    cursor: 'pointer',   
    userSelect: 'none',
    '&:hover': {
      opacity: '0.7'
    },
  },
  listitems: {
    overflowY: 'scroll',
    fontFamily: 'Muli'
  },
  marginspace: {
    marginTop: '10px'
  }
}))

const Category = (props: any) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles(); 
  const [width, height] = useWindowSize();
  const history = useHistory();
  const query = useLocationQuery(); 
  const appTheme = useSelector((store: any) => store.setting.theme); 
  const appViewmode = useSelector((store: any) => store.setting.viewmode); 
  const contentHeight = height - TOOL_FIXED_HEIGHT;
  const token = useSelector((store: any) => store.login.token);

  const { isLoading, data } = useQuery(['repoCateData', token], async (token) => {
    const token_ = token.queryKey[1];
    return (await fetch(API_GET_CATEGORIES_URL+`?AccountName=${getAccountName()}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",  
        "Accept": "application/json",
        "token": token_,      
      }
    })).json(); 
  });

  return (
    <div>
      <div className={classes.toolbar} >
        <h1 className={classes.appTitle} style={{color: appTheme.textColorSecondary}} onClick={()=>handleHome(history, query, appViewmode, props)}>{dictionary.appTitle}</h1>
        <CreateFolderButton
          categories={data?.result}
          mobileOpen={props.mobileOpen} 
          handleDrawerToggle={props.handleDrawerToggle}
        />
      </div>
      <div id='sidebardrawer'>
        <Divider />
        {isLoading ? <CircularProgress className={classes.marginspace}/> :
        <List className={classes.listitems} style={{height: `${contentHeight}px`}}>
          {!isLoading && data?.result.map((category: any) => (
            <ListItem button key={category.id} onClick={(event) => handleCategorySelect(event, history, query, dictionary, category.id, category.name, props)}>
              <ListItemIcon style={{color: appTheme.textColor}}><ClassIcon /></ListItemIcon>
              <ListItemText style={{color: appTheme.textColor}} primary={firstLetterUpper(category.name)}/>
            </ListItem>
          ))}
          <ListItem button key={dictionary.lstLabelNoCategory} onClick={(event) => handleCategorySelect(event, history, query, dictionary, null, null, props)}>
            <ListItemIcon style={{color: appTheme.textColor}}><CategoryIcon /></ListItemIcon>
            <ListItemText style={{color: appTheme.textColor}} primary={dictionary.lstLabelNoCategory} />
          </ListItem>
        </List>}
      </div>
    </div>
  );
}

export default connect(s => ({}))(Category);