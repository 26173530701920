import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../localization/LanguageContainers';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core/';
import { isMobile } from 'react-device-detect';
import { Actions } from '../../../store/setting-reducer';
import { APP_VIEW_MODE } from '../../../utils/const';
import { toast } from 'react-toastify';

toast.configure()
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'block',
      width: '100%',
      textAlign: 'center'
    },
    card: {
      minWidth: '330px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '20px',
      boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px',
      width: '50%'
    },
    item: {
      margin: '5px 0',
      textAlign: 'left'
    },
    itemtitle: {
      fontFamily: 'Muli',
      width: '100%',
      padding: isMobile ? '0 10px' : '0 50px',
      marginTop: '20px'
    },
    ctrlitem: {
      margin: '20px 0 10px 0'
    },
    spinner: {
      color: 'white',
      marginRight: '10px'
    },
}));

function SettingView(props) {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles(); 
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const appTheme = useSelector(store => store.setting.theme);
  const appViewMode = useSelector(store => store.setting.viewmode);
  const [selectedOption1, setSelectedOption1] = useState(appViewMode.includes(APP_VIEW_MODE.CATEGORY))
  const [selectedOption2, setSelectedOption2] = useState(appViewMode.includes(APP_VIEW_MODE.FOLDER))

  const SaveButton = withStyles((theme) => ({
    root: {
      width: '100%',
      color: theme.palette.getContrastText(appTheme.buttonColor),
      backgroundColor: appTheme.buttonColor,
      '&:hover': {
        opacity: '0.7',
        backgroundColor: appTheme.buttonColor,
      }
    },
  }))(Button);

  const handleSave = () => {
    if (loading) return;
    setLoading(true);
    let viewMode = APP_VIEW_MODE.CATEGORY;
    if (selectedOption2)
      viewMode = APP_VIEW_MODE.FOLDER;
    dispatch(Actions.setViewModeInfo(viewMode));
    setTimeout(()=>{setLoading(false); toast.success(dictionary.messageSettingViewSuccess);}, 2000);
  }
  
  return (
    <div className={classes.root}>
      <h3 className={classes.itemtitle} style={{color: appTheme.textColor}}>{dictionary.formLabelViewSettingNotice}</h3>
      <div className={classes.card} style={{backgroundColor:appTheme.backgroundColor}}>
        <div className={classes.item}>
          <FormControlLabel
            control={<Checkbox value="introduce" color="default" style={{color: appTheme.textColor}} checked={selectedOption1}/>}
            label={dictionary.formLabelCategoryView}
            onClick={()=>{setSelectedOption1(true); setSelectedOption2(false);}}
          />
        </div>
        <div className={classes.item}>
          <FormControlLabel
            control={<Checkbox value="introduce" color="default" style={{color: appTheme.textColor}} checked={selectedOption2}/>}
            label={dictionary.formLabelFolderView}
            onClick={()=>{setSelectedOption1(false); setSelectedOption2(true);}}
          />
        </div>
        <div className={classes.ctrlitem}>
          <SaveButton onClick={()=>handleSave()}>
            {loading && <CircularProgress
              className={classes.spinner}
              size={20}
            />}
            {dictionary.btnLabelSave}
          </SaveButton>
        </div>
      </div>
    </div>
  )
}

export default SettingView
