import React, { useContext, /*useState,*/ useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles,/*  useTheme*/ } from '@material-ui/core/styles';
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect } from 'react-redux';
import { API_GET_CATEGORIES_URL } from '../../utils/const';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { useSelContentState } from '../../helper/routing-analyser';
import { Button, Icon, Dialog, DialogContent } from '@material-ui/core/';
import { Actions } from '../../store/folder-reducer';
import { Actions as SettingActions } from '../../store/setting-reducer';
import { getAccountName } from '../../helper/get-account-name';
import Tooltip from 'react-tooltip-lite';
import ThSolid from '../../icons/ThSolid';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import CloseIcon from '@material-ui/icons/Close';
import ThLargeSolid from '../../icons/ThLargeSolid';
import UploadButton from '../upload-button';
import DownloadButton from '../download-button';
import DeleteButton from '../delete-button';
import EditFolderButton from '../edit-folder-button';
import { THUMBNAIL_SIZE_LARGE, THUMBNAIL_SIZE_SMALL } from '../../utils/const';

function ContentToolbar(props) {  
  const appTheme = useSelector(store => store.setting.theme);
  const useStyles = makeStyles((theme) => ({
    toolbar: {
      display: 'flex',
      width: '100%',
      height: '30px',
      font: `400 12px/24px Muli`,
      marginTop: '10px',
      padding: isMobile ? '0 15px':'0 30px',
    },
    thumbbutton: {
      width: '30px',
      height: '30px',
      minWidth: '30px',
      backgroundColor: 'transparent',
      border: '1px solid #DCDCDC',
      borderRadius: 'unset',
      transition: 'all 0.2s ease-out',
      '&:hover': {
        backgroundColor: appTheme.buttonColorThird,
      }
    },
    iconThumbRoot: {   
      display: 'contents'
    },
    controls: {
      display: 'flex',
      right: isMobile ? '18px':'36px',
      position: 'absolute',
    },
    selectbutton: {
      width: '30px',
      height: '30px',
      maxWidth: '30px',
      minWidth: '30px',
      color: appTheme.textColorSecondary,
      backgroundColor: appTheme.buttonColorThird,
      transition: 'all 0.2s ease-out',
      '&:hover': {
        color: appTheme.textColorFourth,
        backgroundColor: appTheme.textColorSecondary,
      }
    },
  }));

  const selectedContent = useSelContentState();
  const { dictionary } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const themeName = useSelector(store => store.setting.themeName); 
  const thumbnailsize = useSelector((store) => store.setting.thumbnailSize);
  const clipBoard = useSelector((store) => store.folder.clipboardPhotos);
  const guestname = localStorage.getItem("guestname");

  const folder = useSelector(store => store.folder.selectedFolder);
  const token = useSelector(store => store.login.token);   

  const { isLoading, data: categories } = useQuery(['repoCateData', token], async (token) => {
    const token_ = token.queryKey[1];
    return (await fetch(API_GET_CATEGORIES_URL+`?AccountName=${getAccountName()}`, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",  
        "Accept": "application/json",
        "token": token_,      
      }
    })).json(); 
  });

  useEffect(()=>{
    if (folder && folder.id === selectedContent) {
      return;
    } else {
      dispatch(Actions.getFolderById(selectedContent));
    }
  }, []);

  const handleThumbnailsLarge = () => {
    dispatch(SettingActions.setThumbnailSizeToLarge());
  }

  const handleThumbnailsSmall = () => {
    dispatch(SettingActions.setThumbnailSizeToSmall());
  }

  const handleAllSelect = () => {
    dispatch(Actions.addClipboardAllPhotos());
  }

  const handleAllUnselect = () => {
    dispatch(Actions.clearClipboardPhotos());
  }
  
  return (
      <div>
        <div className={classes.toolbar}>
            { (clipBoard.length === 0) && <>
              <div style={{marginLeft: '3px'}}>
                <Button onClick={()=>handleThumbnailsLarge()} className={classes.thumbbutton} style={{color: thumbnailsize === THUMBNAIL_SIZE_LARGE ? appTheme.textColorFifth:appTheme.textColorSixth}}>
                    <Icon classes={{root: classes.iconThumbRoot}}>
                        <ThLargeSolid size={16}/>
                    </Icon>
                </Button>
                <Button onClick={()=>handleThumbnailsSmall()} className={classes.thumbbutton} style={{marginLeft: '-1px', color: thumbnailsize === THUMBNAIL_SIZE_SMALL ? appTheme.textColorFifth:appTheme.textColorSixth}}>
                    <Icon classes={{root: classes.iconThumbRoot}}>
                        <ThSolid size={16}/>
                    </Icon>
                </Button>
              </div>
            </> }
            {!(String(guestname) !== 'null' && folder && guestname !== folder.guestName) && <div className={classes.controls}>
                <UploadButton folder={folder}/>
                <DownloadButton folder={folder}/>
                { (clipBoard.length > 0) && <div style={{margin: '0 5px', display: 'flex'}}>
                  <Tooltip content={ dictionary.navTooltipSelectAll } direction="bottom" className={themeName === 'LightTheme' ? 'target-white' : 'target-black'}>
                    <Button onClick={() => handleAllSelect()} className={classes.selectbutton}>
                        <Icon classes={{root: classes.iconThumbRoot}}>
                            <SelectAllIcon/>
                        </Icon>
                    </Button>
                  </Tooltip>
                  <Tooltip content={ dictionary.navTooltipDeselect } direction="bottom" className={themeName === 'LightTheme' ? 'target-white' : 'target-black'}>
                    <Button style={{marginLeft: '10px'}} onClick={() => handleAllUnselect()} className={classes.selectbutton}> 
                        <Icon classes={{root: classes.iconThumbRoot}}>
                            <CloseIcon/>
                        </Icon>
                    </Button>
                  </Tooltip>
                </div> }
                <DeleteButton folder={folder}/>
                <Tooltip content={ dictionary.navTooltipEditFolder } direction="bottom" className={themeName === 'LightTheme' ? 'target-white' : 'target-black'}>
                  <EditFolderButton categories={isLoading ? [] : categories.result} selectFolder={folder}/>
                </Tooltip>
            </div>}
          </div>      
      </div>
  )
}

export default connect(s => ({}))(ContentToolbar);
