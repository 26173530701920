const getFolderInfo = folder => {
    if (!folder) {
      console.log(folder)
      return { title:'', full_url:'', path:'', folderImageUrl:'' };
    }
    const path_array = folder.key.split("/").filter(p => p);
    const parent_path_array = path_array.filter(
      (_, idx) => idx != path_array.length - 1
    );
    const title = unescape(String(path_array.slice(-1)[0]));
    const path = `/album/${folder.id}`;
    const folderImageUrl = folder['folderImageUrl'];
    const full_url = path
    return({ title, full_url, path, folderImageUrl });
};
  
const getFolderParent = (folder, folders) => {
    if(!folder || !folders) return {};
    const path_array = folder.key.split("/").filter(p => p)
    const parent_path_array = path_array.filter((_, idx) => idx!=path_array.length - 1 ) 
    const parent_id = folders && Object.keys(folders).find(id => folders[id].key ==  `/${parent_path_array.join("/")}/` )
    const parent = folders && folders[parent_id]
    return({ parent })
}
  
export { getFolderInfo, getFolderParent };
  
  