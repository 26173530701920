
import React, { useEffect, useContext, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { LanguageContext } from '../../localization/LanguageContainers';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, Switch, useLocation } from "react-router-dom";
import { DRAWER_WIDTH, ROUTE_SIGNIN, ROUTE_SIGNUP, ROUTE_HOME } from '../../utils/const';
import Signin from '../auth-signin';
import Signup from '../auth-signup';
import SideBar from '../sidebar';
import NavBar from '../navbar';
import NavSideBar from '../navsidebar';
import BreadCrumb from '../breadcrumb';
import Contents from '../contents';
import { Dialog, DialogContent } from '@material-ui/core/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Router } from '@material-ui/icons';
import UploadProgress from '../../components/progress/upload-progress';
import DeletingProgress from '../../components/progress/delete-progress';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  titlebar: {
  },
  progresscontents: {
    padding: '30px',
    fontFamily: 'Muli',
    textAlign: 'center',
    minWidth: '210px'
  }
}));

const getUploadedSize = (sizeArr) => {  
  let size = 0;
  for (let obj in sizeArr) {
    size += sizeArr[obj].size;
  }
  return size;
}

const Main = (props) => {
    const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(false);
    const [mobileNavbarOpen, setMobileNavbarOpen] = React.useState(false);
    const { dictionary } = useContext(LanguageContext);
    const history = useHistory();
    const classes = useStyles();   
    const themefull = useTheme();
    const fullScreen = useMediaQuery(themefull.breakpoints.down('xs')); 
    const appTheme = useSelector(store => store.setting.theme);
    const colors = {
      backgroundColor: useSelector((store) => store.setting.backgroundColor),
      primaryColor: useSelector((store) => store.setting.primaryColor),
      secondaryColor: useSelector((store) => store.setting.secondaryColor),
      textColor: useSelector((store) => store.setting.textColor)
    }
    const name = useSelector((store) => store.login.name);
    const token = useSelector((store) => store.login.token);
    const guesttoken = useSelector((store) => store.login.guesttoken);
    const signedIn = (String(name) !== 'null') && ((String(token) !== 'null') || (String(guesttoken) !== 'null'));
    let location = useLocation();
    const theme = React.useMemo(() =>
      createTheme({
        palette: {
          primary: {
            main: colors.primaryColor ? colors.primaryColor : "#0000ff",
          },
          secondary: {
            main: appTheme.paginationColor ? appTheme.paginationColor : "#4f5ff5",
            body: "#550000" 
          },
          textPrimary: {
            main: colors.textColor ? colors.textColor : "#FFFFFF",
          },
          backgroundColor: {
            main: appTheme.backgroundColor,
            body: appTheme.backgroundColor,
          }
        },
      })
    );    

    const uploading = useSelector((store) => store.upload.is_uploading);
    const files_size = useSelector((store) => store.upload.files_size);
    const upload_size_store = useSelector((store) => store.upload.upload_size);  
    const upload_size = getUploadedSize(upload_size_store);

    const deleting_files = useSelector((store) => store.admin.deleting_files);
    const deleted_files = useSelector((store) => store.admin.deleted_files);
    const deleting = deleting_files.length > 0 ? true : false;

    const handleDrawerSidebarToggle = () => {
      setMobileSidebarOpen(!mobileSidebarOpen);
    }

    const handleDrawerNavbarToggle = () => {
      setMobileNavbarOpen(!mobileNavbarOpen);
    }

    const redirectionSignin = () => {
      if (signedIn) {    
        if (!window.location.href.includes(ROUTE_SIGNIN)) return;
        history.push(ROUTE_HOME);
      } else {
        if (window.location.href.includes(ROUTE_SIGNIN) || window.location.href.includes(ROUTE_SIGNUP)) return;
        window.top.document.title = "Photish";
        history.push(ROUTE_SIGNIN);
      }
    }

    useEffect(()=>{
      redirectionSignin();
      if (signedIn) window.top.document.title = name.charAt(0).toUpperCase() + name.slice(1) + " - Photish";
      document.body.style.backgroundColor = appTheme.backgroundColor;
    });

    return (
    <ThemeProvider theme={theme}>
      {signedIn ? 
      <div className={classes.root}>
        <NavBar
            {...props}
            handleDrawerNavbarToggle={handleDrawerNavbarToggle}
            handleDrawerSidebarToggle={handleDrawerSidebarToggle}
        />
        <div>
          <NavSideBar
            {...props}
            mobileOpen={mobileNavbarOpen} 
            handleDrawerToggle={handleDrawerNavbarToggle}
          />
          <SideBar          
            mobileOpen={mobileSidebarOpen} 
            handleDrawerToggle={handleDrawerSidebarToggle}
          />
        </div>
        <main className={classes.content} style={{backgroundColor: appTheme.backgroundColor}}>
          <div className={classes.toolbar} />
          <div className={classes.titlebar}>
            <BreadCrumb/>
          </div>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={300}
            >              
              <Switch location={location}>
                <Route path="/" render={(props) => <Contents {...props} />}/>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </main>    
        <Dialog
          fullScreen={fullScreen}
          open={uploading}
          aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <div 
              className={classes.progresscontents} 
              style={{marginTop: fullScreen ? (window.innerHeight - (150 + 60 + 60)) / 2 : 0}}
            >
              <h3 style={{color: '#242C52'}}>{dictionary.appLabelUploading}</h3>
              <div style={{marginLeft: fullScreen ? (window.innerWidth - (150 + 60 + 60)) / 2 : 0}}>
                <UploadProgress percentage={(files_size === 0) ? 0 : Math.round(upload_size * 100 / files_size)}/>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={deleting}
          aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <div 
              className={classes.progresscontents} 
              style={{marginTop: fullScreen ? (window.innerHeight - (150 + 60 + 60)) / 2 : 0}}
            >
              <h3 style={{color: '#31919D'}}>{dictionary.appLabelDeleting}</h3>
              <div style={{marginLeft: fullScreen ? (window.innerWidth - (150 + 60 + 60)) / 2 : 0}}>
                <DeletingProgress percentage={deleting ? Math.round(deleted_files.length * 100 / deleting_files.length) : 0}/>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* <Dialog
          fullScreen={fullScreen}
          open={moving}
          aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <div 
              className={classes.progresscontents} 
              style={{marginTop: fullScreen ? (window.innerHeight - (150 + 60 + 60)) / 2 : 0}}
            >
              <h3 style={{color: '#31919D'}}>{dictionary.appLabelMoving}</h3>
              <div style={{marginLeft: fullScreen ? (window.innerWidth - (150 + 60 + 60)) / 2 : 0}}>
                <MovingProgress percentage={78}/>
              </div>
            </div>
          </DialogContent>
        </Dialog>     */}
      </div> :
      <div className={classes.root}>
        <main className={classes.content}>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={300}
            >              
              <Switch location={location}>
                <Route path="/signin" render={(props) => <Signin {...props}/>} />
                <Route path="/signup" exact render={(props) => <Signup {...props}/>} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </main>
      </div>}      
    </ThemeProvider>
  );
}

export default connect(s => ({}))(Main);
