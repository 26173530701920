import { default as React, useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { LanguageContext } from '../../localization/LanguageContainers';
import { connect, useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import {IconButton, Button, InputBase, Avatar} from '@material-ui/core';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocationQuery, useOrganizeState, useSearchState } from '../../helper/routing-analyser';
import { DRAWER_WIDTH, ROUTE_PARAM_KEY, ROUTE_PARAM_SIDEBAR, ROUTE_PARAM_ORGANIZE } from '../../utils/const';
import { toast } from 'react-toastify';
import { handleSetting, handleOrganizeMode, handleMyContents, handleSignout, handleAppSearch } from '../../utils/nav-controls';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: '7 !important',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            marginLeft: DRAWER_WIDTH,
        },
        overflow: 'hidden',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: DRAWER_WIDTH,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha('#C4C4C4', 0.15),
      '&:hover': {
        backgroundColor: alpha('#C4C4C4', 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(0),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    toolButton: {
        width: 'fit-content',
        marginRight: '5px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    avataBox: {
        display: 'flex',
        cursor: 'pointer',
        '& > *': {
            margin: theme.spacing(1),
        },
        '&:hover': {
          backgroundColor: '#B0B0B020',
          borderRadius: '5px'
        },
    },
    username: {    
      userSelect: 'none',
      marginTop: '13px',
      '&::first-letter': {
        textTransform: 'uppercase'
      }
    },
    expand: {
      marginTop: '15px'
    },
    dropdown: {
      cursor: 'pointer',
      display: 'none',
      position: 'absolute',
      right: '24px',
      top: '60px',
      zIndex: 10,           
      minWidth: '170px',
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
      '&:hover': {
        display: 'block',
      },
    },
    dropdownitem: {
      padding: '8px 16px',
      textDecoration: 'none',
      display: 'block',
      '&:hover': {
        backgroundColor: '#B0B0B020',
      }
    }
}));

const NavBar = (props) => {  
    const { dictionary } = useContext(LanguageContext); 
    const history = useHistory(); 
    const classes = useStyles();
    const query = useLocationQuery();
    const organizeExpand = useOrganizeState(); 
    const search_state = useSearchState();
    const url = history.location.pathname.split("/");
    const appTheme = useSelector(store => store.setting.theme);
    const username = useSelector(store => store.login.name);
    const isHover = e => e.parentElement.querySelector(':hover') === e;

    const handleSearchKeyPress = (event) => {
      if (event.charCode === 13) {   
        const search = document.getElementById("searchctrl").value;
        handleAppSearch(history, query, dictionary, search);
      }  
    }

    useEffect(()=>{
      document.getElementById("useravatar").addEventListener("mouseover", function(event) {
        document.querySelector('#useravataritem').style.display = 'block';
      })

      document.getElementById("useravatar").addEventListener("mouseleave", function(event) {
        if (!isHover(document.querySelector('#useravataritem')))
          document.querySelector('#useravataritem').style.display = 'none';
      })

      document.getElementById("useravataritem").addEventListener("mouseleave", function(event) {
        document.querySelector('#useravataritem').style.display = 'none';
      })

      document.getElementById("searchctrl").value = search_state ? search_state : '';
    })

    return (
      <div>
        <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: appTheme.backgroundColor}} color="primary">
            <Toolbar>
                <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.handleDrawerSidebarToggle}
                        className={classes.menuButton}
                        style={{color: appTheme.textColor}}
                    >
                        <MenuIcon />
                </IconButton>
                <div className={classes.search} style={{color: appTheme.textColor}}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        id="searchctrl"
                        placeholder={dictionary.iptLabelSearch}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        onKeyPress={e=>handleSearchKeyPress(e)}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    <Button 
                      className={classes.toolButton}
                      style={{color: appTheme.textColorThird}}
                      onClick={() => handleSetting(history, query, dictionary, props)}
                    >
                      <SettingsIcon style={{marginRight: '5px'}}/>
                      {dictionary.btnLabelSettings}
                    </Button>
                    <div 
                      id="useravatar"
                      className={classes.avataBox} 
                      >
                      <Avatar src={null} />           
                      <h3 className={classes.username} style={{color: appTheme.textColorThird}}>{username}</h3>        
                      <ExpandMoreIcon className={classes.expand} style={{color: appTheme.textColorThird}}/>
                    </div>
                </div>
                <div className={classes.sectionMobile} style={{color: appTheme.textColor}}>
                    <IconButton
                        aria-label="show more"
                        //aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={props.handleDrawerNavbarToggle}
                        color="inherit"
                    >
                      <MoreIcon />
                    </IconButton>
                </div>
            </Toolbar>      
        </AppBar>      
        <div id="useravataritem" className={classes.dropdown} style={{backgroundColor: appTheme.backgroundColor}}>
          <p className={classes.dropdownitem} style={{color: appTheme.textColorThird}} onClick={()=>handleOrganizeMode(history, query, dictionary, props)}>{organizeExpand === ROUTE_PARAM_ORGANIZE.OPEN ? dictionary.btnLabelOrganizeModeOff : dictionary.btnLabelOrganizeModeOn}</p>
          <p className={classes.dropdownitem} style={{color: appTheme.textColorThird}} onClick={()=>handleMyContents(history, query, dictionary, username, props)}>{dictionary.btnLabelMyContents}</p>
          <p className={classes.dropdownitem} style={{color: appTheme.textColorThird}} onClick={()=>handleSignout(props)}>{dictionary.btnLabelSignout}</p>
        </div>
      </div>
    );
}

export default connect(s => ({}))(NavBar);